import { Component, OnInit } from '@angular/core';
import { FiltroReserva, Paginacao } from '../../model';
import { ReservaService } from '../../services/';
import { Router } from '@angular/router';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'meus-orcamentos',
  templateUrl: './meus-orcamentos.component.html',
  styleUrls: ['./meus-orcamentos.component.scss']
})

export class MeusOrcamentosComponent implements OnInit {

  filtro: FiltroReserva = new FiltroReserva();
  paginacao: Paginacao = new Paginacao();
  carregando: boolean;
  toogle = true;
  orcamentos = [];

  constructor(
    public router: Router,
    private reservaService: ReservaService
    ) {}

  ngOnInit() {
    
    this.buscarMeusOrcamentos();
  }

  buscarMeusOrcamentos() {
    this.carregando = true;
    const agencia = window.localStorage.getItem('g8h7d8');
    if (agencia !== null) {
      let a = JSON.parse(atob(agencia));
      this.filtro.AgenciaId = a.Id;
    } else {
      this.filtro.AgenciaId = 0;
    }

    this.reservaService.buscarMeusOrcamentos(this.filtro, this.paginacao.NumeroPagina ).subscribe(
      dados => {
        this.paginacao.NumeroPagina = dados.MetaData.NumeroPagina;
        this.paginacao.TotalItens = dados.MetaData.TotalItens;
        this.paginacao.ItensPorPagina = dados.MetaData.itensPorPagina;
        this.paginacao.TotalPaginas = dados.MetaData.TotalPaginas;

        this.carregando = false;
        this.orcamentos = dados.Items;
      },

      erro => console.log(erro)
      );
      this.carregando = false;
  }

  buscarPagina(pagina) {
    this.paginacao.NumeroPagina = pagina;
    this.buscarMeusOrcamentos();
  }

  acessarOrcamento(event, reserva) {
    event.preventDefault();
    this.router.navigate(['/orcamento/' + reserva.Id]);

  }
}
