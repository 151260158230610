// tslint:disable: max-line-length
// import { RebuscaComponent } from './../../app/turismo/rebusca/rebusca.component';
import { Cidade, Pais } from './../endereco.model';
import { Aeroporto } from './aeroporto.model';
import { BuscaAereoRQ, Aereo } from './aereo.model';
import { BuscaHotelRQ } from './hotel.model';
import { BuscaServicoRQ } from './servico.model';
import * as moment from 'moment';
import { BuscaPacoteRQ } from './pacote.model';
import { Injectable } from '@angular/core';
import { __spreadArrays, __read } from 'tslib';

export enum TIPOBUSCAAEREO {
  idaVolta = 'ida-volta',
  ida = 'ida',
  surface = 'surface',
  multiplosTrechos = 'multiplos-trechos'
}

export class Opcoes {
  public gol = true;
  public azul = true;
  public amadeus = true;
  public sabre = true;
  public diretos = false;

  public TodasFamilias = false;
  public resultadosAereos = 9999;
  public resultadosHoteis = 1000;
}

export class Buscas {
  public aereo: boolean;
  public hotel: boolean;
  public ticket: boolean;
}

export class Trecho {
  public cidadeOrigem: Cidade;
  public cidadeDestino: Cidade;
  public AeroportoPartida: Aeroporto;
  public AeroportoChegada: Aeroporto;
  public dataIda: any;
  public dataVolta: any;
  public minDataIda: any;
  public minDataVolta: any;

  constructor() {
    this.cidadeOrigem = new Cidade();
    this.cidadeDestino = new Cidade();
    this.AeroportoPartida = new Aeroporto();
    this.AeroportoChegada = new Aeroporto();
  }
}

@Injectable()
export class Quarto {
  public qtdeAdultos = 1;
  public qtdeCriancas = 0;
  public qtdeIdosos: number;
  public idadesCriancas: number[] = [];
  public show: any = false;
  public nacionalidadePaxQuarto: any;

  constructor() {}

  adicionarAdulto() {
    if (this.qtdeAdultos > 0) {
      this.qtdeAdultos++;
    }
  }

  removerAdulto() {
    if (this.qtdeAdultos > 1) {
      this.qtdeAdultos--;
    } else {
      alert('Mínimo 1 adulto');
    }
  }

  adicionarCrianca() {
    this.qtdeCriancas++;
    this.idadesCriancas.push(0);
  }

  removerCrianca() {
    if (this.qtdeCriancas > 0) {
      this.qtdeCriancas--;
      this.idadesCriancas.pop();
    }
  }

  adicionarIdade(quarto, x) {
    if (quarto.idadesCriancas[x] < 17) {
      quarto.idadesCriancas[x]++;
    }
  }

  removerIdade(quarto, x) {
    if (quarto.idadesCriancas[x] > 0) {
      quarto.idadesCriancas[x]--;
    }
  }
}

@Injectable()
export class Busca {
  TIPOBUSCAAEREO(arg0: string) {
    throw new Error('Method not implemented.');
  }
  primeira = true;
  novoOrcamento = true;
  orcamentoId = 0;
  ReservaId = 0;
  tipoBuscaAereo: TIPOBUSCAAEREO;
  tipoBusca: Buscas;
  destinoCodigo = '';
  somenteCarro = false;
  trechos: Trecho[];
  quartos: Quarto[];
  AgenciaId: any;
  opcoesAvancadas: Opcoes;
  AereoConsolidacao = false;
  guid: string;
  aereo: Aereo;
  nrnoites = 0;
  usuario: any;
  public nacionalidadePax: Pais;
  PacoteId = 0;

  constructor() {
    this.tipoBusca = new Buscas();
    this.opcoesAvancadas = new Opcoes();
    this.quartos = [];
    this.quartos.push(new Quarto());
    this.trechos = [];
    this.trechos.push(new Trecho());
    this.nacionalidadePax = (new Pais());
    this.tipoBuscaAereo = TIPOBUSCAAEREO.idaVolta;
  }

  getQtdeQuartos(): number {
    if (this.quartos !== undefined) {
      return this.quartos.length;
    } else { return 0; }
  }

  getQtdeAdultos(): number {
    let soma = 0;

    for (const quarto of this.quartos) {
      soma += quarto.qtdeAdultos;
    }
    return soma;
  }

  getQtdeCriancas(): number {
    let soma = 0;
    for (const quarto of this.quartos) {
      soma += quarto.qtdeCriancas;
    }
    return soma;
  }

  getQtdeChd(): number {
    let soma = 0;
    if (this.quartos !== undefined) {
      for (const quarto of this.quartos) {
        soma += quarto.idadesCriancas.filter(x => x >= 2).length;
      }
    }
    return soma;
  }

  getQtdeInf(): number {
    let soma = 0;
    if (this.quartos !== undefined) {
      for (const quarto of this.quartos) {
        soma += quarto.idadesCriancas.filter(x => x < 2).length;
      }
    }
    return soma;
  }

  getIdadesChdInf(): string {
    let idades = '';
    if (this.quartos !== undefined) {
      for (const quarto of this.quartos) {
        const idadesCriancas = quarto.idadesCriancas;
        for (const idade of idadesCriancas) {
          idades += idade + ',';
        }
      }
    }
    return idades;
  }

  getQtdePessoasPorQuarto(quarto: Quarto): number {
    const adultos = quarto.qtdeAdultos;
    const idosos = quarto.qtdeIdosos;
    const criancas = quarto.qtdeCriancas;
    const soma = adultos + idosos + criancas;
    return soma;
  }

  decodeBusca(json: string): Busca {
    const buscaDecodificada = JSON.parse(json);
    const busca = Object.create(Busca.prototype);
    const quarto = Object.create(Quarto.prototype);

    buscaDecodificada.quartos = buscaDecodificada.quartos.map(x => {
      x = this.AssignQuarto(x);
      return x;
    });

    return Object.assign(busca, buscaDecodificada);
  }

  AssignQuarto(quarto) : Quarto {
    let TypeQuarto = Object.create(Quarto.prototype);

    return Object.assign(TypeQuarto, quarto);
  }

  getBuscaHotel(): BuscaHotelRQ {
    const buscaHotel = new BuscaHotelRQ();

    let agencia = localStorage.getItem('g8h7d8');
    if (agencia != null) {
        const agenciaID = JSON.parse(atob(agencia));
        buscaHotel.AgenciaId = agenciaID.Id;
      }

    buscaHotel.Guid = this.guid;
    buscaHotel.PacoteId = this.PacoteId;
    for (let i = 0; i < this.quartos.length; i++) {
      buscaHotel.Quartos.push({
        NumeroPesquisa: i + 1,
        Qtde: 1,
        Adultos: this.quartos[i].qtdeAdultos,
        Criancas: this.quartos[i].idadesCriancas
      });
    }

    if (this.tipoBusca.aereo && this.primeira) {
      let data = moment(this.aereo.Seguimentos[0].Voos[this.aereo.Seguimentos[0].Voos.length - 1].DataChegada.toString(), 'YYYY-MM-DD HH:mm');
      const month = data.format('M');
      const day = data.format('D');
      const year = data.format('YYYY');
      let dataComp = moment(year + '-' + month + '-' + day, 'YYYY-MM-DD HH:mm', false);
      dataComp = dataComp.add(4, 'hours');
      switch (this.tipoBuscaAereo) {
        case TIPOBUSCAAEREO.ida:
          if (this.primeira) {
            buscaHotel.Checkin = this.trechos[0].dataIda;
          } else {
            buscaHotel.Checkin = this.trechos[0].dataIda;
          }
          buscaHotel.Checkout = moment(this.trechos[0].dataIda).add(3, 'days');
          buscaHotel.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
          break;

        case TIPOBUSCAAEREO.idaVolta:
          if (this.primeira) {
            if (data.valueOf() >= dataComp.valueOf()) {
              buscaHotel.Checkin = data.format('YYYY-MM-DD');
            } else {
              buscaHotel.Checkin = data.subtract(1, 'day').format('YYYY-MM-DD');
            }

            data = moment(this.aereo.Seguimentos[1].Voos[0].DataPartida.toString(), 'YYYY-MM-DD');
            buscaHotel.Checkout = data.format('YYYY-MM-DD');
          } else {
            buscaHotel.Checkin = this.trechos[0].dataIda;
            buscaHotel.Checkout = this.trechos[0].dataVolta;
          }

          buscaHotel.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
          buscaHotel.Cidade.Nome = this.trechos[0].cidadeDestino.Nome;
          buscaHotel.Cidade.CidadeVinculada = this.trechos[0].cidadeDestino.Nome;
          buscaHotel.Cidade.Estado = this.trechos[0].cidadeDestino.Estado;

          break;

        case TIPOBUSCAAEREO.multiplosTrechos:
          if (this.primeira) {
          } else {
            buscaHotel.Checkin = this.trechos[0].dataIda;
          }
          if (this.trechos.length > 1) {
            // Para voos chegando após 4 da manhã, subtrai um dia na diária do hotel
            if (data.valueOf() >= dataComp.valueOf()) {
              buscaHotel.Checkin = data.format('YYYY-MM-DD');
            } else {
              buscaHotel.Checkin = data.subtract(1, 'day').format('YYYY-MM-DD');
            }

            buscaHotel.Checkout = this.trechos[this.trechos.length - 1].dataIda;

            this.trechos[0].dataVolta = this.trechos[this.trechos.length - 1].dataIda;

          } else {
            buscaHotel.Checkout = this.trechos[0].minDataVolta;
          }

          buscaHotel.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
          break;
      }
    } else {
      buscaHotel.Checkin = this.trechos[0].dataIda;
      buscaHotel.Checkout = this.trechos[0].dataVolta;
      buscaHotel.Cidade.Id = this.trechos[0].cidadeDestino.Id;
      buscaHotel.Cidade.CidadeVinculada = this.trechos[0].cidadeOrigem;
    }

    return buscaHotel;
  }

  getBuscaServico(): BuscaServicoRQ {
    const buscaServico = new BuscaServicoRQ();
    let agencia = localStorage.getItem('g8h7d8');
    if (agencia != null) {
        const agenciaID = JSON.parse(atob(agencia));
        buscaServico.AgenciaId = agenciaID.Id;
      }
    buscaServico.Guid = this.guid;

    buscaServico.IdadesChd = [];
    for (const quarto of this.quartos) {
      // buscaServico.IdadesChd = [...buscaServico.IdadesChd, ...quarto.idadesCriancas];

      buscaServico.IdadesChd = __spreadArrays(__read(buscaServico.IdadesChd), __read(quarto.idadesCriancas));

      buscaServico.Adt = this.getQtdeAdultos();
    }

    if (this.tipoBusca.aereo) {
      switch (this.tipoBuscaAereo) {
        case TIPOBUSCAAEREO.ida:

            buscaServico.De = this.trechos[0].dataIda;

            buscaServico.Ate = this.trechos[0].minDataVolta;
            buscaServico.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
            break;

        case TIPOBUSCAAEREO.idaVolta:
          if (this.primeira) {
            let data = moment(this.aereo.Seguimentos[0].Voos[this.aereo.Seguimentos[0].Voos.length - 1].DataChegada.toString(), 'YYYY-MM-DD HH:mm');

            // Para voos chegando após 4 da manhã, subtrai um dia na diária do hotel
            if (data.hour() >= 4) {
              buscaServico.De = data;
            } else {
              buscaServico.De = data.subtract(1, 'day');
            }

            data = moment(this.aereo.Seguimentos[1].Voos[0].DataPartida.toString(), 'YYYY-MM-DD HH:mm');
            buscaServico.Ate = data;
          } else {
            buscaServico.De = this.trechos[0].dataIda;
            buscaServico.Ate = this.trechos[0].dataVolta;
          }

          buscaServico.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
          buscaServico.Cidade.Nome = this.trechos[0].cidadeDestino.Nome;
          buscaServico.Cidade.CidadeVinculada = this.trechos[0].cidadeDestino.Nome;
          buscaServico.Cidade.Estado = this.trechos[0].cidadeDestino.Estado;
          break;

        case TIPOBUSCAAEREO.multiplosTrechos:
          if (this.primeira) {
          } else {
            buscaServico.De = this.trechos[0].dataIda;
          }
          if (this.trechos.length > 1) {

            const data = moment(this.aereo.Seguimentos[0].Voos[this.aereo.Seguimentos[0].Voos.length - 1].DataChegada.toString(), 'YYYY-MM-DD HH:mm');

            // Para voos chegando após 4 da manhã, subtrai um dia na diária do hotel
            if (data.hour() >= 4) {
              buscaServico.De = data;
            } else {
              buscaServico.De = data.subtract(1, 'day');
            }

            buscaServico.Ate = this.trechos[this.trechos.length - 1].dataIda;

            this.trechos[0].dataVolta = this.trechos[this.trechos.length - 1].dataIda;

          } else {
            buscaServico.Ate = this.trechos[0].minDataVolta;
          }
          buscaServico.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
          break;
      }
    } else {
      buscaServico.De = this.trechos[0].dataIda;
      buscaServico.Ate = this.trechos[0].dataVolta;
      buscaServico.Cidade.Id = this.trechos[0].cidadeDestino.Id;
      buscaServico.Cidade.CidadeVinculada = this.trechos[0].cidadeOrigem;
    }


    return buscaServico;
  }

  getBuscaPacote(): BuscaPacoteRQ {
    const buscaPacote = new BuscaPacoteRQ();
    buscaPacote.AgenciaId = this.AgenciaId;
    buscaPacote.Guid = this.guid;
    buscaPacote.Adt = 0;
    buscaPacote.Chd = 0;
    for (const quarto of this.quartos) {
      buscaPacote.Adt += quarto.qtdeAdultos;
      buscaPacote.Chd += quarto.qtdeCriancas;
    }
    buscaPacote.DestinoCodigo = this.destinoCodigo;

    return buscaPacote;
  }

  getBuscaAereo(): BuscaAereoRQ {
    const buscaAereo = new BuscaAereoRQ();
    if (this.ReservaId == undefined) {
      buscaAereo.Adt = this.getQtdeAdultos();
      buscaAereo.Chd = this.getQtdeChd();
      buscaAereo.Inf = this.getQtdeInf();
      buscaAereo.Idades = this.getIdadesChdInf();
    } else {
      buscaAereo.Idades = this.getIdadesChdInf();
      buscaAereo.ReservaId = this.ReservaId;
    }

    let agencia = localStorage.getItem('g8h7d8');
    if (agencia != null) {
        const agenciaID = JSON.parse(atob(agencia));
        buscaAereo.AgenciaId = agenciaID.Id;
      }

    buscaAereo.AereoConsolidacao = this.AereoConsolidacao;
    buscaAereo.MaximoRegistros = this.opcoesAvancadas.resultadosAereos;
    buscaAereo.FiltroAereo.TodasFamilias = !this.opcoesAvancadas.TodasFamilias;
    buscaAereo.VooDireto = this.opcoesAvancadas.diretos;
    buscaAereo.SegmentosBusca = [];
    buscaAereo.TipoBusca = this.tipoBuscaAereo;

    if (this.tipoBuscaAereo === TIPOBUSCAAEREO.idaVolta) {
      const x = this.trechos[0];
      buscaAereo.SegmentosBusca.push({
        AeroportoPartida: x.AeroportoPartida.Iata,
        AeroportoChegada: x.AeroportoChegada.Iata,
        PaisChegada: null,
        DataPartida: x.dataIda.format('YYYY-MM-DD')
      });
      buscaAereo.SegmentosBusca.push({
        AeroportoPartida: x.AeroportoChegada.Iata,
        AeroportoChegada: x.AeroportoPartida.Iata,
        PaisChegada: null,
        DataPartida: x.dataVolta.format('YYYY-MM-DD')
      });
    } else {
      for (const trecho of this.trechos) {
        buscaAereo.SegmentosBusca.push({
          AeroportoPartida: trecho.AeroportoPartida.Iata,
          AeroportoChegada: trecho.AeroportoChegada.Iata,
          PaisChegada: trecho.AeroportoChegada.Cidade.Estado.Pais,
          DataPartida: trecho.dataIda.format('YYYY-MM-DD')
        });
      }
    }

    buscaAereo.Guid = this.guid;

    return buscaAereo;
  }
}
