import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { Busca, Orcamento } from 'src/app/model';
import { ReservaService } from 'src/app/services';

export const BUSCA = 'busca';
export const ORCAMENTO = 'orcamentousuario';

@Component({
  selector: 'app-lista-pacotes',
  templateUrl: './lista-pacotes.component.html',
  styleUrls: ['./lista-pacotes.component.scss']
})
export class ListaPacotesComponent implements OnInit {
  Orcamento: Orcamento = new Orcamento();
  busca: Busca = new Busca();
  eventsSubject: Subject<void> = new Subject<void>();

  constructor(
    public reservaService: ReservaService,
    public _router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    const orcamento = window.sessionStorage.getItem(ORCAMENTO);
    if (orcamento != null) {
      this.Orcamento = JSON.parse(orcamento);
    }
  }

  aba() {
    if (this.route.snapshot.params.tipo != null) {
        return this.route.snapshot.params.tipo;
    } else {
        let x = this._router.url.split('/');
        return x[x.length - 1];
    }
  }

  rota(texto) {
    this._router.navigate([`/listagem-pacotes/${texto}`]);
  }

  atualizarOrcamento(event, adicionou) {
    window.sessionStorage.setItem(ORCAMENTO, JSON.stringify(this.Orcamento));
  }

  rotaAnterior() {
    const tipo = this.route.snapshot.params.tipo;
    console.log(tipo);
    switch (tipo) {
      case 'pacote-hotel':
        return this.rota('/pacotes');
      case 'pacote-servico':
        return this.rota('/pacote-hotel');
      case 'pre-orcamento':
        return this.rota('/pacote-servico');
    }
  }

  rotaAvancar() {
    const tipo = this.route.snapshot.params.tipo;
    console.log(tipo);
    switch (tipo) {
      case 'pacote-hotel':
        return this.rota('/pacote-servico');
      case 'pacote-servico':
        return this.rota('/pre-orcamento');
    }
  }

}
