import { Orcamento } from 'src/app/model';
// tslint:disable: prefer-for-of
// tslint:disable: no-shadowed-variable
// tslint:disable: only-arrow-functions
// tslint:disable: max-line-length

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReservaService, ProfissaoService, CidadeService, LoginService } from 'src/app/services';
import { Reserva, ReservaPagamento } from 'src/app/model';
import * as moment from 'moment';
import { __read, __spreadArrays } from 'tslib';
import { SOMENTE_LINK_PAGAMENTO } from 'src/app/app.config';

@Component({
    selector: 'app-reserva-pagamentos',
    templateUrl: './reserva-pagamentos.component.html',
    styleUrls: ['./reserva-pagamentos.component.scss']
})
export class ReservaPagamentosComponent implements OnInit {

    bandeiras = [
        { Descricao: 'Master' },
        { Descricao: 'Visa' },
        { Descricao: 'Amex' },
        { Descricao: 'Elo' },
        { Descricao: 'Hipercard' },
        { Descricao: 'Hiper' },
        { Descricao: 'Dinners Club' },
        { Descricao: 'JCB' },
        { Descricao: 'Discover' },
        { Descricao: 'American Express' },
        { Descricao: 'Mais!' },
        { Descricao: 'Cabal' },
        { Descricao: 'Soro Cred' },
        { Descricao: 'Banes Card' },
        { Descricao: 'Soro Cred' },
        { Descricao: 'UnionPay' },
    ];

    hoje = moment();

    dados: any = {};
    profissoes = [];

    @Output() alterou = new EventEmitter<any>();
    @Output() fechou = new EventEmitter<any>();
    @Input() reserva: Reserva;
    @Input() orcamento: Orcamento;

    pagamentos: ReservaPagamento[] = [];
    pagamentosRealizados: ReservaPagamento[] = [];

    podeAdicionarPagamento = true;
    adicionandoPagamento = false;
    carregando = false;
    carregandoInfo = true;
    podePagar = false;
    btnLinkPagamento: boolean = false;;
    valorPago = 0;
    saldo = 0;
    sequencia = 0;

    mensagensErro = [];
    mensagemSucesso: string;
    usuario: any;
    reservaErro: any;
    formasPagamento: any;
    TaxaNaPrimeiraParcela: boolean;
    TaxaMkpDiff: number;
    AplicaMkp: boolean;
    bloqueiaTaxa: boolean;
    DescontouTaxa: any;

    //Comissão
    comissaoPendente: any;
    pagamentosComissoes: any;
    pagamentoValorNormal: any;
    usouPagamentoComissao: boolean = false;

    somenteLinkPagamento: boolean = SOMENTE_LINK_PAGAMENTO;

    constructor(
        private reservaService: ReservaService,
        private profissaoService: ProfissaoService,
        private loginService: LoginService,
        private cidadeService: CidadeService
    ) { }

    ngOnInit() {
        this.carregandoInfo = true;
        this.verificarPodePagar();
        this.usuario = this.loginService.user();

        if (this.somenteLinkPagamento)
            this.btnLinkPagamento = true;
    }

    linkPagamento() {
        this.btnLinkPagamento = !this.btnLinkPagamento;
    }

    gerarLinkPagamento() {
        this.pagamentos = this.pagamentos.map((x) => {
            x.CondicaoPagamentoId = x.CondicaoPagamento.Id;
            x.FormaSelecionada = x.formaSelecionada.FormaPagamento;
            x.emailLinkPagamento = true;
            return x;
        });

        let pagamentos = {
            PagamentosRQ: this.pagamentos,
            DescontoAplicado: this.reserva.DescontoAplicado,
        };
        this.reservaService
            .linkPagamento(pagamentos.PagamentosRQ, this.reserva.Id)
            .subscribe(
                (dados) => {
                    alert("Link para pagamento com cartão enviado por Email.");
                    this.buscarPagamentos(false);
                    this.alterou.emit(this.pagamentosRealizados);
                },
                (error) => {
                    alert(error.error.mensagem);
                }
            );

    }

    verificarPodePagar() {
        this.reservaService.verificarPodePagar(this.reserva.Id).subscribe(
            () => {
                this.novoPagamento();
                this.podePagar = true;
                this.buscarPagamentos(true);
                this.carregandoInfo = false;
            },
            erro => {
                this.reservaErro = erro.error;
                this.carregandoInfo = false;

            });

    }

    estornar(pagamento, index) {
        const resposta = window.confirm(`Tem certeza que deseja estornar o pagamento ?`);

        if (resposta) {
            this.reservaService.estornarPagamento(pagamento.Id).subscribe(
                dados => {
                    this.pagamentosRealizados[index].Status = dados.Status;
                    this.alterou.emit();
                }, erro => {
                    alert(erro.error.mensagem);
                });

        }
    }

    buscarPagamentos(formas: boolean) {
        this.reservaService.buscarPagamentos(this.reserva.Id).subscribe(
            dados => {
                if (formas !== false) {
                    this.formasPagamento = dados.FormasPgto;
                    this.formasPagamento = this.formasPagamento.map(x => {
                        x.FormaPagamento.Condicoes = x.FormaPagamento.Condicoes.map(y => {
                            y.FormaPagamento = {
                                Id: x.FormaPagamento.Id,
                                Descricao: x.FormaPagamento.Descricao
                            };
                            return y;
                        });
                        return x;
                    });
                }
                this.pagamentosRealizados = dados.Pagamentos;
                this.valorPago = this.pagamentosRealizados.filter(x => x.Status === 2 || x.Status === 4).map(x => x.Valor).reduce(function (a, b) {
                    return a + b;
                }, 0);

                this.pagamentosComissoes = this.pagamentosRealizados.filter(x => (x.Status === 2 || x.Status === 4) && x.TipoPagamento === 0).map(x => x.Valor).reduce(function (a, b) {
                    return a + b;
                }, 0);

                this.comissaoPendente = (this.reserva.ValorTotalComissao * (this.reserva.Agencia.Comissao / 100)) - this.pagamentosComissoes;
                this.saldo = this.reserva.ValorTotal - this.valorPago;
            }, erro => {
                console.log(erro);
            });
        console.log(this.pagamentos)
        this.carregando = false;
    }


    novoPagamento() {
        this.adicionandoPagamento = true;
        const pgto = new ReservaPagamento();
        this.sequencia = this.sequencia + 1;
        pgto.Guid = this.sequencia;
        pgto.Valor = 0;
        pgto.PaxNaoPagantes = [];
        if (this.reserva.Pessoas.length > 0) {
            pgto.PaxPaganteId = this.pessoasPagantes()[0].Id;
        }
        this.pagamentos.push(pgto);
        this.podeAdicionarPagamento = false;
    }

    calcularSaldoGeral() {
        const saldo = this.saldo - (this.somaPagamentosInformados() * 1);
        return parseFloat(saldo.toFixed(2));

    }

    somaPagamentosInformados() {
        const soma = this.pagamentos.map(x => x.Valor).reduce(function (a, b) {
            return (a * 1) + (b * 1);
        }, 0);
        return parseFloat(soma.toFixed(2));
    }

    pessoasPagantes() {
        const idsT = this.pagamentos.map(y => y.PaxNaoPagantes.map(x => x));
        let ids = [];
        for (let i = 0; i < idsT.length; i++) {
            ids = ids.concat(idsT[i]);
        }

        return this.reserva.Pessoas.filter(x =>
            (!x.PaxPaganteId || x.PaxPaganteId === x.Id)
            && (ids.indexOf(x.Id) === -1)
        );
    }

    possuiPaxNaoPagantes(paxPaganteId, guid) {
        const x = this.pagamentos.filter(x => x.PaxPaganteId === paxPaganteId && x.Guid !== guid).filter(x => x.PaxNaoPagantes.length > 0);
        return (x.length > 0);
    }

    pessoasNaoPagantes(pagamento) {
        const idPagante = pagamento.idPagante;
        const idsT = this.pagamentos.map(y => y.PaxNaoPagantes.map(x => x));
        let ids = [];
        for (let i = 0; i < idsT.length; i++) {
            ids = ids.concat(idsT[i]);
        }

        let naoPagantes = this.reserva.Pessoas.filter(x =>
            pagamento.PaxNaoPagantes.indexOf(x.Id) >= 0 ||
            ((!x.PaxPaganteId || x.PaxPaganteId === idPagante)
                && x.Id !== idPagante
                && ids.indexOf(x.Id) < 0
                && this.pagamentos.map(y => y.PaxPaganteId).indexOf(x.Id) === -1)
        );

        // naoPagantes = [this.reserva.Pessoas.filter(x => x.Id !== pagamento.PaxPaganteId && x.PaxPaganteId === pagamento.PaxPaganteId), ...naoPagantes];

        naoPagantes = __spreadArrays([this.reserva.Pessoas.filter(x => x.Id !== pagamento.PaxPaganteId && x.PaxPaganteId === pagamento.PaxPaganteId)], __read(naoPagantes));

        return naoPagantes;
    }


    selecionarPaxNaoPagante(pax, pagamento) {

        if (!pax.PaxPaganteId || pax.PaxPaganteId === 0) {
            const id = pax.Id;
            const i = pagamento.PaxNaoPagantes.indexOf(id);
            if (i === -1) {
                pagamento.PaxNaoPagantes.push(id);
            } else {
                pagamento.PaxNaoPagantes.splice(i, 1);
            }
        } else {
            alert('Esse pax não pode ser alterado');
        }

    }

    checado(pax, pagamento) {
        return pagamento.PaxNaoPagantes.indexOf(pax.Id) > -1 || pax.PaxPaganteId === pagamento.PaxPaganteId;
    }

    onChangeParcelas(pagamento) {

        let valorOriginal = pagamento.Valor;

        if (pagamento.formaSelecionada.FormaPagamento.TipoPagamento != 4) {
            this.TaxaNaPrimeiraParcela = false;
        }

        if (!this.TaxaNaPrimeiraParcela) {
            this.TaxaMkpDiff = 0;

            this.pagamentos = this.pagamentos.map((x) => {
                x.Valor = 0;
                x.Taxas = 0;
                return x;
            });

            pagamento.Valor = this.calcularSaldoGeral() * 1;

            this.AplicaMkp = false;

            if (pagamento.CondicaoPagamento.Parcelas > 1) {

                this.AplicaMkp = true;

                let TaxasRestante = this.reserva.TaxaTotal - this.reserva.ValorPagoTaxas;
                let TaxasRestanteComMkp = 0;

                if (TaxasRestante > 0) {
                    TaxasRestanteComMkp = this.reserva.TaxaTotalMkp - this.reserva.ValorPagoTaxasMkp;
                }
                else if (TaxasRestante < 0) {
                    TaxasRestante = 0;
                }

                this.TaxaMkpDiff = TaxasRestanteComMkp - TaxasRestante;

                pagamento.Taxas = 0;

                this.pagamentos = this.pagamentos.map((x) => {
                    x.Valor = 0;
                    x.Taxas = 0;
                    return x;
                });

                pagamento.Valor = valorOriginal;

                if (pagamento.Taxas == 0) {
                    pagamento.Taxas = TaxasRestanteComMkp;
                }
                if (TaxasRestanteComMkp > 0) {
                    this.bloqueiaTaxa = true;
                    if (pagamento.Taxas > TaxasRestanteComMkp) {
                        pagamento.Taxas = TaxasRestanteComMkp;
                    }
                } else {
                    pagamento.Taxas = 0;
                    this.bloqueiaTaxa = false;
                }

                if (pagamento.Taxas > 0 && !this.DescontouTaxa) {
                    pagamento.Valor -= pagamento.Taxas;
                    pagamento.Valor += this.TaxaMkpDiff;
                    this.DescontouTaxa = true;
                }

                pagamento.ValorMkpTaxa = this.reserva.ValorMkpTaxa;
            }
            else {
                this.DescontouTaxa = false;
            }
        }
    }


    selecionouFormaPagamento(pagamento) {
        // COMISSAO = 0,
        // FATAURADO = 1,
        // CARTAO_CREDITO = 2,
        // BOLETO_VISTA = 3,
        // FINANCIAMENTO = 4
        if (!this.somenteLinkPagamento)
            this.btnLinkPagamento = false;

        if (this.usouPagamentoComissao) {
            pagamento.Valor = this.pagamentoValorNormal;
        }

        switch (pagamento.formaSelecionada.FormaPagamento.TipoPagamento) {
            case 0:   // COMISSAO = 0,

                this.pagamentoValorNormal = pagamento.Valor;
                let comissao: number = this.reserva.ValorTotalComissao * (this.reserva.Agencia.Comissao / 100);
                if (pagamento.Valor > comissao || pagamento.Valor === 0) {
                    pagamento.Valor = comissao - this.pagamentosComissoes;
                }
                pagamento.Valor = pagamento.Valor.toFixed(2);
                this.usouPagamentoComissao = true;

                pagamento.Descricao = 'Comissão';
                break;
            case 1: // FATAURADO = 1,
                pagamento.Descricao = 'Faturado';
                pagamento.Valor = this.calcularSaldoGeral() * 1;
                break;
            case 2: // CARTAO_CREDITO = 2,
                if (pagamento.Valor === 0) {
                    pagamento.Valor = this.calcularSaldoGeral() * 1;
                }
                pagamento.Cartoes = [{}];
                pagamento.Descricao = 'Cartão Crédito';
                break;
            case 3: // BOLETO_VISTA = 3,
                if (pagamento.Valor === 0) {
                    pagamento.Valor = this.calcularSaldoGeral() * 1;
                }
                pagamento.Boletos = [{ Parcela: 1 }];
                pagamento.Descricao = 'Boleto à vista';
                break;
            case 4: // FINANCIAMENTO = 4
                if (pagamento.Valor === 0) {
                    pagamento.Valor = this.calcularSaldoGeral() * 1;
                }
                pagamento.Financiamentos = [{}];
                pagamento.Descricao = 'Financiamento';
                if (this.profissoes.length <= 0) {
                    this.profissaoService.buscarPorParametro('', 1).subscribe(
                        dados => {
                            this.profissoes = dados.Items;
                        },
                        error => {
                            console.log(error);
                        }
                    );
                }
                break;
            case 7: // CONFIRMACAO = 7,
                pagamento.Descricao = 'Confirmação de Terceiros';
                pagamento.Valor = this.calcularSaldoGeral() * 1;
                break;
        }

        if (pagamento?.formaSelecionada?.FormaPagamento?.TipoPagamento !== 0) {
            this.usouPagamentoComissao = false;
        }

        pagamento.CondicaoPagamento = pagamento.formaSelecionada.FormaPagamento.Condicoes[0];
        this.podeAdicionarPagamento = this.podeAdicionarPagamento || this.pagamentos.filter(x => !x.formaSelecionada).length === 0;

    }


    removerPagamento(indice) {

        this.pagamentos.splice(indice, 1);
        if (this.pagamentos.length === 0) {
            this.podeAdicionarPagamento = true;
        }
    }



    efetuarPagamento() {
        this.carregando = true;
        this.mensagemSucesso = '';
        this.mensagensErro = [];

        this.pagamentos = this.pagamentos.map(x => {
            x.CondicaoPagamentoId = x.CondicaoPagamento.Id;
            x.FormaSelecionada = x.formaSelecionada.FormaPagamento;
            return x;
        });

        const pagamentos = {
            PagamentosRQ: this.pagamentos,
            DescontoAplicado: this.reserva.DescontoAplicado
        };


        this.reservaService.enviarPagamentos(pagamentos, this.reserva.Id).subscribe(
            dados => {
                this.dados = dados;
                const guids = dados.filter(x => x.Erros.length > 0).map(x => x.Guid);
                for (let i = 0; i < this.pagamentos.length; i++) {
                    if (guids.indexOf(this.pagamentos[i].Guid) > -1) {
                        this.pagamentos[i].Erros = dados.filter(y => this.pagamentos[i].Guid === y.Guid)[0].Erros;
                    } else {
                        this.pagamentos[i].Processado = true;
                        this.pagamentos[i].Success = 'Pagamento registrado com sucesso!';
                    }
                }
                const pagamentosRegistrados = this.dados.filter(x => !x.Erros || x.Erros.length === 0);
                this.pagamentos = this.pagamentos.filter(x => x.Erros && x.Erros.length > 0);
                console.log(pagamentosRegistrados, this.pagamentosRealizados);

                // this.pagamentosRealizados = [...this.pagamentosRealizados, ...pagamentosRegistrados];
                this.pagamentosRealizados = __spreadArrays([this.pagamentosRealizados], __read(pagamentosRegistrados));

                this.carregando = false;

                this.buscarPagamentos(false);
                this.alterou.emit(this.pagamentosRealizados);
                window.location.reload();

            }, erros => {
                console.log('Erro ao enviar os pagamentos');
                console.log(erros);
                this.mensagensErro.push(erros.error.mensagem);
                // this.dados = erros;
                //
                // if(erros.error.dados){
                //   let guids = erros.error.dados.filter(x => x.Erros && x.Erros.length > 0).map(x => x.Guid);
                //   this.pagamentos = this.pagamentos.filter(x => guids.indexOf(x.Guid) > -1);
                //   this.pagamentos = this.pagamentos.map(x =>{
                //     x.Erros = erros.error.dados.filter(y => y.Guid == x.Guid)[0].Erros;
                //     return x;
                //   });
                // }

                // console.log(this.pagamentos);
                // this.carregando = false;
                // this.buscarPagamentos();
                this.carregando = false;
            });


    }

    selecionarProfissao(pagamento) {
        pagamento.Profissao = pagamento.profissao.Descricao;
        pagamento.ProfissaoCodigo = pagamento.profissao.CodigoPagtur;
    }

    buscaCepPax(pagamento) {
        this.cidadeService.buscarEnderecoPorCep(pagamento.CEP).subscribe(
            dados => {
                pagamento.Logradouro = dados.logradouro;
                pagamento.Bairro = dados.bairro;
                pagamento.Complemento = dados.complemento;
                pagamento.Cidade = dados.localidade;
                pagamento.Estado = dados.uf;
            });
    }

    validaData(data) {
        data = moment(data, 'DDMMYYYY', true);
        const now = moment();
        return data && data.isValid() && data < now;
    }

    setDataNascimento($event, obj) {
        obj.DataNascimento = $event;
    }

    validaComissao(pagamentos) {
        if (pagamentos.length > 0 && pagamentos.every(x => x.formaSelecionada)) {
            return pagamentos.some(x => x.formaSelecionada.FormaPagamento.TipoPagamento === 0 && x.Valor > this.comissaoPendente);
        } else {
            return false;
        }

    }
}
