<div class="pagebg">
  <div class="banner-image">
    <div class="banner-text">
      <h2>Hotéis</h2>
    </div>
  </div>
  <div class="container">

    <div class='row mb-3 mt-3'>
      <div class='container col-md-12 row'>
        <h4 class="col-md-5">{{"Selecione um Hotel"| translate }}</h4>
        <button class="btn btn-success buttonOrcamento col-md-7" (click)="rotaAvancar()" [disabled]="!temHotel()">{{"Continuar" |
          translate}}</button>
      </div>
    </div>

    <app-view-loading *ngIf="buscando"></app-view-loading>


    <div class="card-section row">
      <div class="col-md-4 mt-3 mb-3" *ngFor="let hotel of pacoteReserva.Hoteis; let i = index">
        <div class="card">
          <img class="card-img-top" src="{{pacoteReserva.PacoteImagens[0]?.Imagem }}">
          <div class="card-body">
            <h5 class="card-title">{{hotel.Nome}}</h5>
            <h6 class="card-subtitle mb-2 text-muted"><i class="fas fa-star" *ngFor='let i of [].constructor(hotel.Estrelas)'></i></h6>
            <p class="card-text">{{hotel.Endereco}}</p>
            <div class="row justify-content-between m-3">
              <a (click)="abrirModal(contentHotelDetalhes, pacoteReserva, i)" class="col-md-5 nav-link">
                {{'Detalhes' | translate}}
              </a>
              <a (click)="abrirModal(contentHotelQuartos, hotel, i);" class="nav-link col-md-7">{{ "Opções de Quartos" | translate}}</a>
            </div>

            <div class="d-flex justify-content-between">
              <div>
                <h6>A partir de:</h6>
                <h3>{{hotel.Quartos[0].MoedaListagem.Simbolo}}{{somaTotal(hotel.Quartos).toFixed(2)}}</h3>
              </div>
              <div>
                <button (click)="selecionarHotel(hotel)" [disabled]="hotelSelecionado(hotel)" class="btn btn-success float-end mt-2">{{ hotelSelecionado(hotel) ? "Selecionado" : "Selecionar" |
                    translate}}</button>
                <button *ngIf="hotelSelecionado(hotel)" class="btn btn-block btn-outline-success float-end mt-2" (click)="removerHotel(hotel)">{{"Remover" | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #contentHotelQuartos let-modal>
  <app-hotel-quartos [Hotel]="objHotel" [pacote]="true" (selecionouHotel)="atualizaHotel($event)"></app-hotel-quartos>
</ng-template>

<ng-template #contentHotelDetalhes let-modal>
  <app-hotel-detalhes [hotel]="objHotel" [pacote]="true"></app-hotel-detalhes>
</ng-template>

<!-- <ng-template #contentDetalhesPacote let-modal>
  <div class="modal-header">
    <h3 class="modal-title text-center" id="modal-basic-title">{{PacoteDetalhes.Pacote.Nome}}</h3>
    <button type="button" class="close btn" aria-label="Close" (click)="fecharModal()"></button>
  </div>
  <div class="modal-body">
    <img [src]="PacoteDetalhes?.PacoteImagens[0]?.Imagem" class="img-fluid card-img-top"
      style="max-height: 500px; object-fit: fill" [alt]="PacoteDetalhes?.Nome" />
    <div class="p-2">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation" *ngFor="let inclui of PacoteDetalhes?.PacotesInclui; let i = index">
          <button class="nav-link" [ngClass]="i == 0 ? 'active' : ''" id="tabs{{i}}" data-bs-toggle="tab"
            [attr.data-bs-target]="'#tab' + i" type="button" style="text-decoration: none !important;" role="tab"
            [attr.aria-controls]="'tab' + i" [attr.aria-selected]="i == 0 ? true : false">{{inclui?.Titulo}}
          </button>
        </li>
      </ul>
      <div class="tab-content p-2 mt-2" id="myTabContent">
        <div *ngFor="let inclui of PacoteDetalhes?.PacotesInclui; let i = index" class="tab-pane fade" id="tab{{i}}"
          [ngClass]="i == 0 ? 'show active' : ''" role="tabpanel" [attr.aria-labelledby]="'tabs' + i"
          [innerHTML]="inclui?.Descritivo"></div>
      </div>
    </div>
  </div>
</ng-template> -->
