import { Component, OnInit , Output, EventEmitter } from '@angular/core';
import { Paginacao} from 'src/app/model';
import { AgenciaService } from 'src/app/services';

@Component({
  selector: 'app-agencia-selecionar',
  templateUrl: './agencia-selecionar.component.html',
  styleUrls: ['./agencia-selecionar.component.scss']
})
export class AgenciaSelecionarComponent implements OnInit {

  @Output() selecionou = new EventEmitter<any>();
  @Output() removeu = new EventEmitter<any>();
  mensagemSuccesso = '';
  parametroId: any = '';
  parametroBusca = '';
  mensagemErro = '';
  carregando = false;
  dados = [];
  agencia: any;
  paginacao: Paginacao = new Paginacao();
  timer = null;

  buscarDebounce() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.buscarPorParametro(), 600);

  }

  constructor(private agenciaService: AgenciaService) { }

  ngOnInit() {
    this.buscar();
  }

  buscar() {
    let busca = this.agenciaService.buscarPorParametro(this.parametroBusca, this.paginacao.NumeroPagina);
    if (this.parametroId != undefined && this.parametroId != '') {
      this.paginacao.NumeroPagina = 1;
      busca = this.agenciaService.buscarPorId(this.parametroId);
    }
    busca.subscribe(
      dados => {

        this.dados = dados.Items;
        this.paginacao.NumeroPagina = dados.MetaData.NumeroPagina;
        this.paginacao.TotalItens = dados.MetaData.TotalItens;
        this.paginacao.ItensPorPagina = dados.MetaData.itensPorPagina;
        this.paginacao.TotalPaginas = dados.MetaData.TotalPaginas;
      },
      erro => {
        this.dados = [];
        console.log('Erro ao Buscar');
        console.log(erro);
        this.mensagemErro = this.errorHandler(erro, 'Buscar agências');
      },
    );
  }

  buscarPorParametro() {
    this.paginacao.NumeroPagina = 1;
    this.buscar();
  }

  buscarPagina(pagina) {
    this.paginacao.NumeroPagina = pagina;
    this.buscar();
  }

  selecionar(agencia) {
    this.selecionou.emit(agencia);
    this.agencia = agencia;
    localStorage.setItem('g8h7d8', btoa(JSON.stringify(agencia)));
  }

  teste() {
    alert("teste")
  }

  limpar() {
    this.agencia = null;
    localStorage.removeItem('g8h7d8');
    this.removeu.emit();
  }

  public errorHandler(erro, acao: string = null): string {

    let mensagem = 'Erro';
    if (acao !== null) {
      mensagem += ' ao tentar ' + acao + ': ';
    }
    switch (erro.status) {
      case 0: mensagem += 'o servidor não respondeu, tente novamente mais tarde!'; break;
      case 401: mensagem += 'você não tem autorização para executar esta ação!'; break;
      case 404: mensagem += erro.error.mensagem ; break;
      case 406: mensagem += 'a requisição não foi aceita, tente novamente!' ; break;
      case 500: mensagem += 'Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte' ; break;
      default : mensagem += erro.statusText;
    }

    return mensagem;

  }

}
