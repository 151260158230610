import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService, UsuarioService } from 'src/app/services';
import { TranslateService } from '@ngx-translate/core';
import { LOGOLIGHT, FRASELOGIN } from 'src/app/app.config';
import { Usuario } from '../../src/app/model'
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  logolight = LOGOLIGHT;
  fraselogin = FRASELOGIN;
  carregando = false;
  logged = false;
  title = 'mmc';
  usuario: any;
  agencia: any;
  usuariolayout: any;
  @Input() usuarioIdioma: Usuario;
  errors: string[];
  recuperarSenha: boolean;
  public user: any = { email: '', password: '' };
  escondeHtml = false;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private translate: TranslateService,
    private usuarioService: UsuarioService
  ) {
    translate.setDefaultLang('pt');
  }

  ngOnInit() {
    this.recuperarSenha = false;
    this.isLoggedIn();
    this.tratamentoInit();

    let user = window.localStorage.getItem('gs57d8');


    if (user != null) {
      this.usuariolayout = JSON.parse(atob(user));
    }

    else {
      this.usuarioService.getUserById(this.loginService.user().Id).subscribe(
        dados => {

          this.usuariolayout = dados.Items[0];
          console.log(this.usuariolayout);
          window.localStorage.setItem('gs57d8', btoa(JSON.stringify(this.usuariolayout)));
        });
    }
  }

  ngDoCheck() {
    //verifica se os dados do usuário estão setados no cache, pois a maioria das vezes o usuario não é setado dentro do
    //fluxo normal de login, por um motivo que nunca consegui descobrir. Então enquanto não setar, ele executará esta função.
    this.usuario = JSON.parse(window.localStorage.getItem('loggedUser'));
    this.useLanguage(this.usuario != null && this.usuario.Agencia != null && this.usuario.Agencia.Idioma != null ? this.usuario.Agencia.Idioma : "pt");

  }

  tratamentoInit() {
    const user = this.loginService.user();
    if (user !== null) {
      this.usuario = user;
    }

    if (window.location.href.split('/').indexOf("voucher") != -1 || window.location.href.split('/').indexOf("orcamentodownload") != -1) {
      this.escondeHtml = true;
    }

    let permi = window.localStorage.getItem("permission");

    if (!permi) {
      this.usuarioService.buscarPermissoes();
    }

    // let user = window.localStorage.getItem('gs57d8');

    // if (user != null) {
    //   this.usuarioIdioma = JSON.parse(atob(user));
    // }
  }

  useLanguage(language: string) {
    this.translate.setDefaultLang(language);
    this.translate.use(language);
  }

  isLoggedIn() {
    this.logged = this.loginService.isLoggedIn();
    if (!this.logged) {
      this.usuario = null;
    }
    return this.logged;
  }

  login() {
    this.carregando = true;
    this.loginService.logar(this.user.email, this.user.password).subscribe(
      dados => {
        this.carregando = false;
        this.isLoggedIn();
        this.usuario = this.loginService.user();
        this.router.navigate(['']);
      }, erro => {
        console.log('Erro de login');
        console.log(erro);
        this.carregando = false;
        if (erro.status === 400) {
          this.errors = [erro.error.error_description];
        } else {
          this.errors = ['Houve algum erro, Tente novamente'];
        }
      });
  }
}
