import { Aeroporto } from '../model';
import { WebService } from '../model';

export class CidadesWebServices {
  Id: number;
  Cidade?: any = {Nome : ''};
  CidadeId: number;
  IdCidadeFornecedor: string;
  WebService?: any = {Descricao : ''};
  WebServiceId: number;
  Ativo = true;
}

export class Cidade {
    Nome = '';
    Estado: Estado = new Estado();
    IdErp?: any;
    CodigoIbge: number;
    MostraOpcaoBusca: boolean;
    CidadeVinculadaId?: any;
    EstadoId: number;
    CidadeVinculada?: any = {Nome : ''};
    CidadesVinculadas?: any;
    CidadesWebServices?: CidadesWebServices[];
    Aeroportos?: Aeroporto[];
    Bairros?: any;
    Id: number;
    Erros?: any;
    // tslint:disable-next-line:variable-name
    display_field: string;
    public Imagem: string;
    public ImagemApresentacao: any;
    public Alteracoes: any;
}

export class Estado {
  Id: number;
  Cidades: Cidade[];
  Nome: string;
  Pais?: any = {Nome : ''};
  Iata: any;
}

export class Pais {
  Id: number;
  Estados: Estado[];
  Nome: string;
  Iata: any;
}
