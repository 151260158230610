<div class="theme-search-area-section first theme-search-area-section-curved theme-search-area-section-fade-white">
  <div class="theme-search-area-section-inner">
    <label *ngIf="label?.length > 0" class="_label _pl-0"> {{label}}</label>
    <ng-content></ng-content>
      <span class="help-block-error" *ngIf="(input.touched || input.dirty) && input.errors?.required"> -
        {{requiredMessage}}</span>
      <span class="help-block-error" *ngIf="(input.touched || input.dirty) && input.errors?.minlength"> - Preenchimento
        mínimo: {{input.errors?.minlength.actualLength}}/{{input.errors?.minlength.requiredLength}}</span>
      <span class="help-block-error"
        *ngIf="(input.touched || input.dirty) && (input.errors?.email || input.errors?.cpf || input.errors?.cnpj || input.errors?.pattern )">
        - {{errorMessage}}</span>

  </div>
</div>
