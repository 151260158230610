<div class="container">
  <div class='row'>
    <div class='col-3 text-end center'>
      <h5 class="title">{{"Selecione um Consultor" | translate}}</h5>
    </div>
    <div class='col text-center center'>
      <select class="form-select" name="consultorSelecionado" [(ngModel)]="consultorSelecionado">
        <option *ngFor="let c of dados" [ngValue]="c" >{{c.Nome}} {{c.Sobrenome}} </option>
      </select>
    </div>
    <div class='col-2 text-center center'>
      <button (click)="selecionar(); clicked = !clicked" [disabled]="clicked || consultorSelecionado == null" data-bs-dismiss="modal" class="btn btn-success" >{{"Confirmar" | translate}}</button>
    </div>
  </div>
</div>