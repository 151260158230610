import { Component, OnInit } from '@angular/core';
import { VoucherService } from 'src/app/services';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss']
})
export class VoucherComponent implements OnInit {

  voucher = null;
  key : any;

  erros = null;
  carregando = true;

  email = '';
  errors: string[];
  success: string;
  form: FormGroup;
  loadingEnvio = false;
  mostraForm = false;
  Tipo : string;

  constructor(private voucherService: VoucherService,
    public route: ActivatedRoute) { }



  ngOnInit() {

    this.key= this.route.snapshot.params['key'];
    if (this.key !== undefined) {
      switch(this.key[0].toUpperCase()) {
       case "A": {
          this.Tipo = "aéreo";
          break;
       }
       case "H": {
          this.Tipo = "hotel";
          break;
       }
       case "G": {
          this.Tipo = "seguro";
          break;
       }
       case "C": {
          this.Tipo = "carro";
          break;
       }
       default: {
          this.Tipo = "serviço";
          break;
       }
    }
      setTimeout(() => { this.carregarObjeto(this.key); }, 500);
    }

  }

  salvar(){
    window.open(this.voucher, "_blank");
  }

  carregarObjeto(key){

    // this.voucher = this.voucherService.exibirVoucherUrl(key);

    this.voucherService.exibirVoucher(key).subscribe(
      dados => {
        this.voucher = URL.createObjectURL(dados);
      },
      erros => {
        this.erros = erros;
      }
    )
  }

  VoucherEmHtml() {
    this.voucherService.exibirVoucherHtml(this.key).subscribe(
      dados => {
        var wnd = window.open("about:blank", "", "_blank");
        wnd.document.write(dados);

      },
      erros => {
        this.erros = erros;
      }
    )
  }


  pageRendered(e: CustomEvent) {
    this.carregando = false;
  }

  onError(event){
    console.log("ERRO AO CARREGAR O PDF:");
    console.log(event);
  }

  imprimir(){
    window.open(this.voucher);
  }

  EnviarVoucher() {
    delete this.errors;
    delete this.success;
    this.loadingEnvio = true;
    this.voucherService.enviarVoucher(this.key, this.email, this.Tipo).subscribe(
      dados => {
        this.success = 'E-mail enviado!';
        this.loadingEnvio = false;
      }, erro => {
        console.log('Erro ao enviar !');
        console.log(erro.error.mensagem);
        if (erro.status === 400) {
          this.errors = [erro.error.mensagem];
        } else {
          this.errors = ['Houve algum erro, tente novamente'];
        }
        this.loadingEnvio = false;
      });
  }

}
