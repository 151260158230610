// tslint:disable: max-line-length
// tslint:disable: max-line-length

import { Component, OnInit, Input,  Output, EventEmitter } from '@angular/core';
import { ServicoService } from 'src/app/services';
import { __read, __spreadArrays } from 'tslib';
@Component({
  selector: 'app-servico-cancela',
  templateUrl: './servico-cancela.component.html',
  styleUrls: ['./servico-cancela.component.scss']
})
export class ServicoCancelaComponent implements OnInit {

  @Output() alterou = new EventEmitter<any>();
  @Output() fechou = new EventEmitter<any>();

  @Input() carregando = false;
  @Input() servico: any;
  mensagensErro: any[];
  mensagemSucesso: string;

  constructor(private servicoService: ServicoService) { }

  ngOnInit() {
  }

  cancelarServico() {
    delete this.mensagensErro;
    delete this.mensagemSucesso;
    this.carregando = true;
    this.servicoService.cancelarServico(this.servico).subscribe(
      dados => {
        console.log(dados);
        this.servico = dados.Servico;
        this.alterou.emit(this.servico);
        this.mensagemSucesso = 'Cancelamento efetivado!';
        this.carregando = false;

      }, erro => {
        this.carregando = false;
        console.log(erro);
        this.mensagensErro = this.errorHandler(erro);

      });
  }

  public errorHandler(erro): string[] {
    let mensagem = [];
    switch (erro.status) {
      case 0: mensagem.push('o servidor não respondeu, tente novamente mais tarde!'); break;
      case 400: mensagem.push('Houve algum erro na execução, tente novamente!'); break;
      case 401: mensagem.push('você não tem autorização para executar esta ação!'); break;
      case 404: mensagem.push('Solicitação inválida!' ); break;
      case 406: mensagem.push(erro.error.mensagem ); break;
      case 500: mensagem.push('Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte' ); break;
      default : mensagem.push(erro.statusText);
    }
    if (erro.error != null && erro.error.mensagens != null) {
      mensagem = __spreadArrays([mensagem], __read(erro.error.mensagens));
    }
    return mensagem;

  }
}
