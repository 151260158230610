import { Component, Input, OnInit } from '@angular/core';
import { HotelService, Util } from 'src/app/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Hotel } from 'src/app/model';

@Component({
  selector: 'app-hotel-detalhes',
  templateUrl: './hotel-detalhes.component.html',
  styleUrls: ['./hotel-detalhes.component.scss']
})
export class HotelDetalhesComponent implements OnInit {

  constructor(private modalService: NgbModal, private hotelService: HotelService) { }

  @Input()
  hotel: any;
  @Input()
  pacote:boolean = false;

  carregando = false;
  mensagensErro: any[];

  lat: number = -23.8779431;
  lng: number = -49.8046873;
  zoom: number = 15;

  pacoteReserva;

  ngOnInit(): void {
    if(this.pacote == true){
      this.pacoteReserva = this.hotel;
    }else{
    this.GetHotelDetalhes();
    }
  }

  GetHotelDetalhes() {
    this.carregando = true;
    this.hotelService.detalhesHotel(this.hotel).subscribe(
      dados => {
        this.hotel = dados;
        this.lat = Number(this.hotel.Latitude);
        this.lng = Number(this.hotel.Longitude);

        if (this.hotel.Offline) {
          this.hotel.Detalhes.Imagens = this.hotel.Detalhes.Imagens.map(x => {
            x = Util.pathImagemApi(x);
            return x;
          })
        }

        this.carregando = false;
      }, erro => {
        this.carregando = false;
        this.mensagensErro = this.errorHandler(erro);
      });
  }

  fecharModal() {
    this.modalService.dismissAll();
  }

  public errorHandler(erro): string[] {

    let mensagem = [];
    switch (erro.status) {
      case 0: mensagem.push('o servidor não respondeu, tente novamente mais tarde!'); break;
      case 400:

        if (erro.error && erro.error.mensagem)
          mensagem.push(erro.error.mensagem);
        else
          mensagem.push("Houve algum erro na execução, tente novamente!");

        break;
      case 401: mensagem.push('você não tem autorização para executar esta ação!'); break;
      case 404: mensagem.push("Solicitação inválida!"); break;
      case 406: mensagem.push(erro.error.mensagem); break;
      // case 500: mensagem.push('Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte' ); break;
      case 500: mensagem.push(erro.error.ExceptionMessage); break;
      default: mensagem.push(erro.statusText);
    }

    return mensagem;
  }
  
}
