<div class="row">

  <div *ngIf="carregando" class="overlay">
    <img src="assets/img/loading1.gif" class="imagem">
  </div>

  <div id="print-section" style="width: 220mm; height: 310mm;" class="col-md-9">
      <pdf-viewer *ngIf="orcamento" [src]="orcamento" [render-text]="true" (error)="onError($event)" (page-rendered)="pageRendered($event)"></pdf-viewer>
  </div>

  <div class="col-md-3 mt-5">
    <!-- <button class="btn no-print btn-success btn-block" (click)="salvar()"> Salvar</button> -->
    <button class="btn no-print btn-success btn-block" (click)="imprimir()"> Imprimir/Salvar</button>
    <button class="btn no-print btn-success btn-block" (click)="mostraForm = !mostraForm"> Enviar</button>

    <form *ngIf="mostraForm" class="form mt-5" #form="ngForm" novalidate (keydown.enter)="$event.preventDefault()">
      <div class="alert alert-danger" *ngIf="errors">
        <ul>
          <li *ngFor="let erro of errors">
            {{erro}}
          </li>
        </ul>
      </div>
      <div class="alert alert-success" *ngIf="success">
        <p>{{success}}</p>
      </div>
      <div class="form-group row">
        <div class="col-sm-12">
          <app-input label="E-mail">
            <input type="text" [disabled]="loadingEnvio" class="form-control full-width" [(ngModel)]="email"
              required="required" name="email" placeholder="Informe o E-mail">
          </app-input>
        </div>
      </div>

      <div class="form-group row mt-4">
        <div class="col-sm-12">
          <button [disabled]="!form.valid" [hidden]="loadingEnvio" type="submit"
            class="btn btn-primary  btn-block" (click)="EnviarOrcamento()">Enviar {{"Orçamento" | translate}}</button>
        </div>
      </div>
    </form>

    <!-- <div *ngIf="loadingEnvio" class="overlay">
      <img src="assets/img/loading1.gif" class="imagem">
    </div> -->
  </div>

</div>
