// Imports
import { Component, OnInit, Input } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

// Models and Services
import { Alteracoes, LogAlteracoes, LogRQ, Paginacao } from 'src/app/model';
import { LogService } from 'src/app/services';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {

  constructor(
    private logService: LogService,
    private modalService: NgbModal
  ) { }

  moment: any = moment;

  @Input() entidade: string;
  @Input() chave;
  @Input() alteracoes: Alteracoes;
  @Input() texto = 'Ver mais';
  @Input() showDetalhes: boolean = false;

  logRq: LogRQ = new LogRQ();
  dados: LogAlteracoes[] = [];

  paginacao: Paginacao = new Paginacao();

  dataInicial: any;
  dataInicioFiltro: any;
  dataFinal: any;
  dataFimFiltro: any;
  itemModal: any;

  closeResult: string;

  show: boolean = false

  ngOnInit() {
    setTimeout(() => {
      console.log(this.chave)
      this.logRq.entidade = this.entidade;
      this.logRq.chave = this.chave;
    }, 3000)
  }

  buscar() {
    this.dados = [];
    this.dataInicioFiltro = "";
    this.dataFimFiltro = "";

    if (this.dataInicial != null && this.dataFinal != null) {
      this.dataInicioFiltro = this.moment(this.dataInicial).format('YYYY-MM-DD');
      this.dataFimFiltro = this.moment(this.dataFinal).format('YYYY-MM-DD');
    }

    this.logService.buscarPorEntidade(this.logRq, this.paginacao.NumeroPagina, this.dataInicioFiltro, this.dataFimFiltro).subscribe(
      dados => {
        this.dados = dados.Items;

        this.paginacao.NumeroPagina = dados.MetaData.NumeroPagina;
        this.paginacao.TotalItens = dados.MetaData.TotalItens;
        this.paginacao.TotalPaginas = dados.MetaData.TotalPaginas;
        this.paginacao.ItensPorPagina = dados.MetaData.itensPorPagina;
      },
      error => {
        console.log("Erro ao buscar log: " + error);
      }
    );
  }

  buscarPagina(pagina) {
    this.paginacao.NumeroPagina = pagina;
    this.buscar();
  }

  buscarPorParametro() {
    this.paginacao.NumeroPagina = 1;
    this.buscar();
    this.show = true;
  }

  open(content) {
    this.buscar();
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  abrirModal(content, item){
    this.itemModal = item;
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title' });
  }


  private getDismissReason(reason: any): string {
    if(reason === ModalDismissReasons.ESC){
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK){
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }



}
