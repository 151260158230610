import { RevisaOrcamentoComponent } from './views/listagem/revisa-orcamento/revisa-orcamento.component';
import { PacoteHotelComponent } from './views/lista-pacotes/pacote-hotel/pacote-hotel.component';
import { ListaServicoComponent } from './views/listagem/lista-servico/lista-servico.component';
import { ListaAereoComponent } from './views/listagem/lista-aereo/lista-aereo.component';
import { ListaHotelComponent } from './views/listagem/lista-hotel/lista-hotel.component';
import { ListaPacotesComponent } from './views/lista-pacotes/lista-pacotes.component';
import { MeusOrcamentosComponent } from './views/meus-orcamentos/meus-orcamentos.component';
import { PacotesComponent } from './views/lista-pacotes/pacotes/pacotes.component';
import { AreaTesteComponent } from './views/area-teste/area-teste.component';
import { OrcamentoComponent } from './views/orcamento/orcamento.component';
import { ListagemComponent } from './views/listagem/listagem.component';
import { HomeComponent } from './views/home/home.component';
import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { VoucherComponent } from './voucher/voucher.component';
import { OrcamentodownloadComponent } from './orcamentodownload/orcamentodownload.component';
import { PacoteServicoComponent } from './views/lista-pacotes/pacote-servico/pacote-servico.component';
import { PreOrcamentoComponent } from './views/lista-pacotes/pre-orcamento/pre-orcamento.component';

const routes: Routes = [
  { path: '', component: HomeComponent},
  {
    path: 'listagem-pacotes/:tipo',
    component: ListaPacotesComponent,
    // children: [
    //   {path: 'pacotes', component: PacotesComponent},
    //   {path: 'pacote-hotel', component: PacoteHotelComponent},
    //   {path: 'pacote-servico', component: PacoteServicoComponent},
    //   {path: 'pre-orcamento', component: PreOrcamentoComponent}
    // ]
  },
  {
    path: 'listas',
    component: ListagemComponent,

    children: [
      {path: 'aereo', component: ListaAereoComponent},
      {path: 'hotel', component: ListaHotelComponent},
      {path: 'servico', component: ListaServicoComponent},
      {path: 'revisa-orcamento', component: RevisaOrcamentoComponent}
    ]
  },
  { path: 'servico', component: ListaServicoComponent},
  { path: 'servico-orcamento', component: RevisaOrcamentoComponent},
  { path: 'meus-orcamentos', component: MeusOrcamentosComponent},
  { path: 'meus-orcamentos/:id', component: MeusOrcamentosComponent},
  { path: 'orcamento/:id', component: OrcamentoComponent},
  { path: 'areaTeste', component: AreaTesteComponent},
  { path: 'busca/:id', component: HomeComponent },
  { path: 'adicionar/:id/:tipo', component: ListagemComponent},
  { path: 'adicionar/:id/aereo', component: ListaAereoComponent},
  { path: 'adicionar/:id/hotel', component: ListaHotelComponent},
  { path: 'adicionar/:id/servico', component: ListaServicoComponent},
  { path: 'voucher/:key', component: VoucherComponent },
  { path: 'orcamentodownload/:simplificado/:id', component: OrcamentodownloadComponent },


];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: true });
