import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { LoginService } from './login.service';

import { LogRQ } from '../model';

import { API } from '../app.config';
import { CLIENT_ID } from '../app.config';

@Injectable()
export class LogService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private login: LoginService) {}


    buscarPorEntidade(log: LogRQ, pagina, datainicial: any = '', datafinal: any = ''): Observable<any> {

      return this.http.get<any>( `${API}/api/log/entidade/${log.entidade}?pagina=${pagina}&chave=${log.chave}&DataInicial=${datainicial}&DataFinal=${datafinal}`);
    }


  }
