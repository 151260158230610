<div class="pagebg">
  <div class="banner-image">
    <div class="banner-text">
      <h2>{{"Pacotes" | translate}}</h2>
    </div>
  </div>
  <div class="container">
    <div class="row m-3">
      <div class="col-md-4" id="searchText">
        <h5>{{"Selecione seu pacote" | translate }}</h5>
      </div>

      <div class="col-md-8 d-flex justify-content-end" id="searchText">
        <div class="col-md-6 row flex-row">
          <div class="col-md-8">
            <select class="form-control" [(ngModel)]="DestinoId" style="color: gray">
              <option [ngValue]="0" selected>{{ DestinoId != 0 ? "Todos" : "Selecione um Destino"| titlecase}}</option>
              <option *ngFor="let d of Destinos" [ngValue]="d.Id">
                {{ d.Descricao | titlecase }}
              </option>
            </select>
          </div>
          <div class="col-md-4">
            <button (click)="buscarPacoteDestino(DestinoId)" class="btn btn-search" type="button" id="button-addon2">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-4 search-box">
        <input type="text" class="form-control" placeholder="Pesquisar" id="search-area" [(ngModel)]="parametroBusca"/>
        <div class="input-group-append">
          <button (click)="buscarPacotesLst()" class="btn btn-search" type="button" id="button-addon2">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div> -->
    </div>

    <div *ngIf="loading">
      <app-view-loading></app-view-loading>
    </div>

    <div class="card-section mb-5" *ngIf="!loading">
      <div class="card" *ngFor="let pacote of Pacotes; let i = index">
        <img src="{{ pacote?.PacoteImagens.length > 0  ? pacote?.PacoteImagens[0].Imagem : backgroundimage}}"
          class="card-img-top">
        <div class="card-body">
          <div class="row">
            <div class="col zeroPaddingMargin">
              <h6 class="card-subtitle text-muted">{{ pacote.Cidade.Nome }}</h6>
            </div>
            <div class="col duration">
              <span class="duration">{{ pacote.MinimoDeNoites }} {{"Noite" | translate}}(s)</span>
            </div>
          </div>
          <h5 class="card-title">{{ pacote.Nome }}</h5>
          <div class="card-bottom">
            <button type="button" class="btn-details" data-bs-toggle="modal" data-bs-target="#detailsModal"
              (click)="PacoteDetalhes = pacote">
              {{"Detalhes do Pacote" | translate}}
            </button>
            <button class="btn btn-search" type="button" (click)="abrirModal(pacote, contentHotelDetalhes)">
              {{"Selecionar" | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <app-paginacao [paginacao]="paginacao" (alterarPagina)="buscarPagina($event)"></app-paginacao>
  </div>
</div>

<div class="modal fade bd-example-modal-lg" tabindex="-1" id="detailsModal" role="dialog"
  aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{"Detalhes do Pacote" | translate}}
        </h5>
        <i type="button" class="btn btn-search" data-bs-dismiss="modal" class="fas fa-times"></i>
      </div>
      <div class="modal-body">
        <div class="container">

          <div>
            <img [src]="PacoteDetalhes?.PacoteImagens[0]?.Imagem" class="img-fluid card-img-top"
              style="max-height: 500px; object-fit: fill" [alt]="PacoteDetalhes?.Nome" />
          </div>
          <div class="p-2">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation"
                *ngFor="let inclui of PacoteDetalhes?.PacotesInclui; let i = index">
                <button class="nav-link" [ngClass]="i == 0 ? 'active' : ''" id="tabs{{i}}" data-bs-toggle="tab"
                  [attr.data-bs-target]="'#tab' + i" type="button" style="text-decoration: none !important;" role="tab"
                  [attr.aria-controls]="'tab' + i" [attr.aria-selected]="i == 0 ? true : false">{{inclui?.Titulo}}
                </button>
              </li>
            </ul>
            <div class="tab-content p-2 mt-2" id="myTabContent">
              <div *ngFor="let inclui of PacoteDetalhes?.PacotesInclui; let i = index" class="tab-pane fade"
                id="tab{{i}}" [ngClass]="i == 0 ? 'show active' : ''" role="tabpanel"
                [attr.aria-labelledby]="'tabs' + i" [innerHTML]="inclui?.Descritivo"></div>
            </div>
          </div>

          <!-- <ul class="nav nav-tabs">
            <li class="active nav-item">
              <a data-bs-toggle="tab" class="nav-link active" href="#home">{{"Descrição" | translate }}</a>
            </li>
            <li class="nav-item">
              <a data-bs-toggle="tab" class="nav-link" href="#inclui">{{"Inclui" | translate }}</a>
            </li>
            <li class="nav-item">
              <a data-bs-toggle="tab" class="nav-link" href="#menu1">{{"Observações Gerais" | translate }}</a>
            </li>
            <li class="nav-item">
              <a data-bs-toggle="tab" class="nav-link" href="#menu2">Dia a Dia</a>
            </li>
          </ul>
          <div>
            <div id="carouselExampleSlidesOnly">
              <div class="carousel-inner">
                <div class="carousel-item carousel-item-modal active">
                  <img class="d-block" src="{{ PacoteDetalhes.Imagem }}" />
                </div>
              </div>
            </div>
          </div>
          <div class="tab-content">
            <div id="home" class="tab-pane active">
              <p [innerHTML]="PacoteDetalhes.Introducao"></p>
            </div>
            <div id="inclui" class="tab-pane">
              <p *ngFor="let inclui of PacoteDetalhes.PacotesInclui">✔ {{inclui.Titulo}}</p>
            </div>
            <div id="menu1" class="tab-pane fade">
              <p [innerHTML]="PacoteDetalhes.DadosVoucher">
              </p>
            </div> -->
          <!-- <div id="menu2" class="tab-pane fade">
              <p>
              </p>
            </div>
          </div>
            </div> -->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-search" data-bs-dismiss="modal">
            {{"Voltar" | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>


  <ng-template #contentHotelDetalhes let-modal>
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{"Detalhes do Pacote" | translate}}
        </h5>
        <i type="button" class="btn btn-search" data-bs-dismiss="modal" class="fas fa-times"
          (click)="fecharModal()"></i>
      </div>
      <div class="modal-body">
        <div class="container" *ngFor="let trecho of busca.trechos; let i = index">
          <div class="row dateInput justify-content-start">

            <div class="col-md-4" *ngIf="Pacote.PacotePeriodos.length == 0">
              <span class="input-group-text" id="inputGroup-sizing-default">{{ "Entrada" | translate }}</span>
              <input type="date" class="form-control" [(ngModel)]="trecho.dataIda"
                (ngModelChange)="setDataIda($event, busca.trechos, i)" min="{{minDate | date: 'yyyy-MM-dd'}}"
                max="{{maxDate | date: 'yyyy-MM-dd'}}" onkeydown="return false" />
            </div>

            <div class="col-md-4" *ngIf="Pacote.PacotePeriodos.length > 0">
              <span class="input-group-text" id="inputGroup-sizing-default">{{ "Entrada" | translate }}</span>
              <select class="form-control" [(ngModel)]="trecho.dataIda"
                (ngModelChange)="setDataIda($event, busca.trechos, i)">
                <option *ngFor="let dataValida of datasvalidas" [ngValue]="dataValida">
                  {{ dataValida | date: "dd/MM/yyyy" }}
                </option>
              </select>
            </div>

            <div class="col-md-4">
              <span class="input-group-text" id="inputGroup-sizing-default">{{ "Noites" | translate }}</span>
              <select class="form-control align-self-end" disabled>
                <option value="{{busca.nrnoites}}">{{busca.nrnoites + ' Noites'}}</option>
              </select>
            </div>

            <div class="col-md-4" *ngIf="desabilitaTarifa == false">
              <span class="input-group-text" id="inputGroup-sizing-default">{{ "Saída" | translate }}</span>
              <input autocomplete="off" class="form-control h-100" type="date" placeholder="Volta"
                [(ngModel)]="trecho.dataVolta" disabled />
            </div>

          </div>

          <div class="row mt-5">
            <h4>{{"Viajantes" | translate}}</h4>
          </div>
          <div class="room" *ngFor="let quarto of busca.quartos; let i = index">
            <div class="row">
              <h6>{{"Quarto" | translate}}{{ i + 1 }}</h6>
            </div>
            <div class="row">
              <div class="col">
                <p>{{"Adultos" | translate}}</p>
              </div>
              <div class="col d-flex flex-end">
                <div class="col-2 text-center">
                  <button class="minus-plus" (click)="quarto.removerAdulto()">
                    <i class="fas fa-minus-circle"></i>
                  </button>
                </div>
                <div class="col-2 text-center">
                  {{ quarto.qtdeAdultos }}
                </div>
                <div class="col-2 text-center">
                  <button class="minus-plus" (click)="quarto.adicionarAdulto()">
                    <i class="fas fa-plus-circle"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <p>{{"Crianças" | translate}}</p>
              </div>
              <div class="col d-flex flex-end">
                <div class="col-2 text-center">
                  <button class="minus-plus" (click)="quarto.removerCrianca()">
                    <i class="fas fa-minus-circle"></i>
                  </button>
                </div>
                <div class="col-2 text-center">
                  {{ quarto.qtdeCriancas }}
                </div>
                <div class="col-2 text-center">
                  <button class="minus-plus" (click)="quarto.adicionarCrianca()">
                    <i class="fas fa-plus-circle"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="row" *ngFor="
                            let idadeCriancas of quarto.idadesCriancas;
                            let x = index
                          ">
              <div class="col">
                <p>{{ "Idade Criança " + (x + 1) | translate }}</p>
              </div>
              <div class="col d-flex flex-end">
                <div class="col-2 text-center">
                  <button class="minus-plus" (click)="quarto.removerIdade(quarto, x)">
                    <i class="fas fa-minus-circle"></i>
                  </button>
                </div>
                <div class="col-2 text-center">
                  {{ idadeCriancas }}
                </div>
                <div class="col-2 text-center">
                  <button class="minus-plus" (click)="quarto.adicionarIdade(quarto, x)">
                    <i class="fas fa-plus-circle"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="input-group mt-4">
            <div *ngIf="semAgencia" class="col-md-12 col-sm-12">
              <div class="alert alert-danger">
                <p>{{ "Selecione uma Agência para continuar" | translate }}</p>
              </div>
            </div>
            <div *ngIf="erros?.length > 0" class="col-md-12 col-sm-12">
              <div class="alert alert-danger" style="width: 100%">
                <ul>
                  <li *ngFor="let mensagem of erros">{{ mensagem }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-search" (click)="incrementaQuartos()">
        {{"Adicionar Quarto" | translate }}
      </button>
      <button type="button" class="btn btn-outline-search" *ngIf="busca.quartos.length > 1"
        (click)="decrementaQuartos()">
        {{"Remover Quarto" | translate }}
      </button>
      <button type="button" [disabled]="desabilitaTarifa" class="btn btn-search" data-bs-dismiss="modal"
        (click)="fecharModal(); BuscaPacotes(Pacote);">
        {{"Tarifar Pacote" | translate }}
      </button>
    </div>
  </ng-template>