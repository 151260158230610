import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PacotelstService } from 'src/app/services/turismo/pacotelst.service';

@Component({
  selector: 'app-cancela-pacote',
  templateUrl: './cancela-pacote.component.html',
  styleUrls: ['./cancela-pacote.component.css']
})
export class CancelaPacoteComponent implements OnInit {

  @Input() Pacote: any;

  @Output() alterou = new EventEmitter<any>();
  carregando = false;
  mensagensErro: any[];
  mensagemSucesso: string;
  cancelado = false;

  constructor(private modalService:NgbModal, private pacoteService: PacotelstService) { }

  ngOnInit(): void {
  }

  imparMensagens() {
    delete this.mensagemSucesso;
    delete this.mensagensErro;
  }

  cancelar() {
    delete this.mensagensErro;
    delete this.mensagemSucesso;
    this.carregando = true;
    this.pacoteService.cancelarPacoteDestino(this.Pacote.Id).subscribe(
      dados => {
        Object.assign(dados, this.Pacote);
        this.cancelado = true;
        this.alterou.emit(this.Pacote);
        this.mensagemSucesso = 'Pacote cancelado!';

        // this.Pacote.map(x => {
        //   x.ValorListagem = x.ValorListagem.toFixed(2);
        //   x.ValorTaxaListagem = x.ValorTaxaListagem.toFixed(2);
        // })

        this.carregando = false;

      }, erro => {
        this.cancelado = true;
        this.carregando = false;
        console.log(erro);
        this.mensagensErro = this.errorHandler(erro);
      });

  }

  fecharModal() {
    this.modalService.dismissAll();
  }

  public errorHandler(erro): string[] {

    let mensagem = [];
    switch (erro.status) {
      case 0: mensagem.push('o servidor não respondeu, tente novamente mais tarde!'); break;
      case 400: mensagem.push('Houve algum erro na execução, tente novamente!'); break;
      case 401: mensagem.push('você não tem autorização para executar esta ação!'); break;
      case 404: mensagem.push('Solicitação inválida!'); break;
      case 406: mensagem.push(erro.error.mensagem); break;
      case 500: mensagem.push('Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte'); break;
      default: mensagem.push(erro.statusText);
    }
    if (erro.error != null && erro.error.mensagem != null) {
      mensagem = [erro.error.mensagem];
    }
    return mensagem;

  }

}
