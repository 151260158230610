import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-servico-detalhes',
  templateUrl: './servico-detalhes.component.html',
  styleUrls: ['./servico-detalhes.component.scss']
})
export class ServicoDetalhesComponent implements OnInit {

  @Input() objServico: any;

  diasDaSemana = [
    { diaSemana: 'Dom' },
    { diaSemana: 'Seg' },
    { diaSemana: 'Ter' },
    { diaSemana: 'Qua' },
    { diaSemana: 'Qui' },
    { diaSemana: 'Sex' },
    { diaSemana: 'Sáb' }
  ]

  constructor(private modalService:NgbModal) { }

  ngOnInit(): void {
  }

  fecharModal(){
    this.modalService.dismissAll();
  }

}
