// tslint:disable: max-line-length


import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { HotelService } from 'src/app/services';
import { Hotel } from 'src/app/model';
import { __read, __spreadArrays } from 'tslib';
// declare var $: any;

@Component({
  selector: 'app-hotel-reserva',
  templateUrl: './hotel-reserva.component.html',
  styleUrls: ['./hotel-reserva.component.scss']
})
export class HotelReservaComponent implements OnInit {

 constructor(
   // private modalService: NgbModal,
   private hotelService: HotelService
   ) { }

 @Output() alterou = new EventEmitter<any>();
 @Output() fechou = new EventEmitter<any>();

 @Input()
 hotel: Hotel;
 carregando = false;
 politica: any;
 mensagensErro: any[];
 mensagemSucesso: string;

 ngOnInit() {
   this.buscarPolitica();
 }

 buscarPolitica() {
   this.carregando = true;
   this.hotelService.buscarPolitica(this.hotel).subscribe(
     dados => {

       this.politica = dados;
       this.hotel = this.politica.Hotel;
      //  console.log("Dados OnInit:",this.politica)
       this.alterou.emit(this.hotel);
       this.carregando = false;
     }, erro => {
       this.carregando = false;
       console.log(erro);
       this.mensagensErro = this.errorHandler(erro);
     });
 }

 // fecharModal() {
 //   $('.modal.fade.in').modal('hide')  // closes all active pop ups.
 //   $('.modal-backdrop').remove()
 // }

 reservar() {
   this.carregando = true;
   this.politica.Politica.Observacoes = this.hotel.Observacao;
   this.hotelService.reservarHotel(this.politica.Politica).subscribe(
     dados => {

       this.hotel = dados.Hotel;
       this.carregando = false;
       if (this.hotel.Status === 3 || this.hotel.Status === 4) {
         this.mensagemSucesso = `Hotel ${this.hotel.Nome} reservado com sucesso`;
       } else if (this.hotel.Status === 1) {
         this.mensagemSucesso = `Hotel ${this.hotel.Nome} aguardando retorno do fornecedor`;
       } else if (this.hotel.Status === 7) {
         this.mensagemSucesso = `A reserva do hotel ${this.hotel.Nome} tem problemas técnicos, entre em contato com seu consultor`;
       } else {
         this.mensagemSucesso = `Entre em contato com seu consultor, houve algum problema não identificado nesta reserva!`;
       }

       this.alterou.emit(this.hotel);

     }, erro => {
       this.carregando = false;
       console.log(erro);
       this.mensagensErro = this.errorHandler(erro);
       this.alterou.emit(this.hotel);
     });
 }


 public errorHandler(erro): string[] {

   let mensagem = [];
   switch (erro.status) {
     case 0: mensagem.push('o servidor não respondeu, tente novamente mais tarde!'); break;
     case 400: mensagem.push('Houve algum erro na execução, tente novamente!'); break;
     case 401: mensagem.push('você não tem autorização para executar esta ação!'); break;
     case 404: mensagem.push('Solicitação inválida!' ); break;
     case 406: mensagem.push(erro.error.mensagem ); break;
     // case 500: mensagem.push('Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte' ); break;
     case 500: mensagem.push(erro.error.ExceptionMessage ); break;
     default : mensagem.push(erro.statusText);
   }
   if (erro.error != null && erro.error.mensagens != null) {
     mensagem = __spreadArrays([mensagem], __read(erro.error.mensagens));
   }
   return mensagem;

 }

 QuartosComPrePagamento(hotel) {
   return hotel.Quartos.some(x => x.ExigePrePagamento);
 }

}
