import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-hotel-quartos',
  templateUrl: './hotel-quartos.component.html',
  styleUrls: ['./hotel-quartos.component.scss']
})
export class HotelQuartosComponent implements OnInit {

  @Input()
  Hotel: any;
  @Input()
  pacote:Boolean = false;

  @Output() selecionouHotel = new EventEmitter<any>();

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  fecharModal(){
    this.modalService.dismissAll();
  }

  selecionou() {
    this.selecionouHotel.emit(this.Hotel);
    this.fecharModal();
  }
 
  pensoesIguais(){
    if (this.Hotel.MesmaPensao) {
      if (this.Hotel.Quartos.length <= 1) {
        return true;
      }
      // Verifica se as pensões em todos os quartos são iguais
      const primeiraPensao = this.Hotel.Quartos[0].CodigoPensao;
      return this.Hotel.Quartos.every((quartos: any) => quartos.CodigoPensao === primeiraPensao);
    }

    return true;
  }
  selecionaQuarto(hotel, Agrupador) {
    if (Agrupador >= 0)
        for (let i = 0; i < hotel.Quartos.length; i++) {
            hotel.Quartos[i] = hotel.opcoes[i].find(x => x.Agrupador == Agrupador);
        }
  }
}
