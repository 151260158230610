<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'Gerar Lâmina' | translate}}</h4>
  <a class="close" *ngIf="!carregando" aria-label="Close" style="cursor: pointer" (click)="fecharModal()">
    <i class="fas fa-times" (click)="fecharModal()"></i>
  </a>
</div>

<div class="modal-body">
  <ng-container *ngIf="indexStep === 0">
    <div class="row col-md-12 m-3 row justify-content-center">
      <h4 class="col-md-6 text-center">{{"Escolha um Aéreo/Hotel" | translate}}</h4>
    </div>


    <ul class="nav nav-tabs" id="tabInfoLaminas" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="hoteis-tab" data-bs-toggle="tab" data-bs-target="#hoteis" type="button" role="tab" aria-controls="hoteis" aria-selected="true"> {{"Hotéis" | translate}}</button>
      </li>
      <li class="nav-item" role="presentation" *ngIf="(Reserva.Aereos?.length > 0 || Reserva.AereoPacote?.length > 0)">
        <button class="nav-link" id="aereos-tab" data-bs-toggle="tab" data-bs-target="#aereos" type="button" role="tab" aria-controls="aereos" aria-selected="false"> {{"Aéreos" | translate}}</button>
      </li>
    </ul>

    <div class="tab-content p-2 mt-2" id="tabInfoLaminasContent">

      <!-- HOTÉIS -->
      <div class="tab-pane fade show active" id="hoteis" aria-labelledby="hoteis-tab" role="tabpanel">

        <div class="row m-4 border" *ngFor="let hotel of Reserva.Hoteis">
          <div class="col-md-1 p-4" style="align-self: center">
            <input class="form-control form-check-input" style="width: 25px; height: 25px" type="checkbox" [name]="'name1-checkbox' + hotel.Id" [id]="'id1-checkbox' + hotel.Id" [checked]="hotel.Id == itemLamina.HotelId" (click)="selecionarHotel(hotel.Id, hotel.Quartos[0].ValorFechamento, hotel.Quartos[0].MoedaFechamento, hotel.Quartos[0].Adultos, hotel.Nome)">
          </div>
          <div class="col-md-11 p-3 row">
            <span class="col-md-4" style="align-self:center"> <strong>{{hotel.Nome}}</strong>
              <app-estrela class="ml-2" [estrela]="hotel.Estrelas"></app-estrela>
            </span>
            <div class="col-md-6" style="font-weight: 600; align-self: center;">
              <span class="text-center">{{hotel.Quartos[0]?.Descricao}}({{hotel.Quartos[0]?.DescricaoPensao}})</span><br>
              <span class="text-center">{{"Checkin" | translate}}: {{hotel.CheckinOpcao}}, {{"Checkout" | translate}}:
                {{hotel.CheckoutOpcao}}</span>
            </div>

            <div class="col-md-2 text-right align-self-center">
              {{'Total' | translate}}:<br><strong class="texto-Preco" style="color:black;">{{hotel.Quartos[0].MoedaFechamento?.Sigla}}&nbsp;{{hotel.Quartos[0]?.ValorFechamento |
                currency:'BRL':'':'2.2-2'}}</strong>
            </div>
          </div>
        </div>

        <div class="row m-4 border" *ngFor="let hotel of Reserva.HotelPacote">
          <div class="col-md-1 p-4" style="align-self: center">
            <input class="form-control form-check-input" style="width: 25px; height: 25px" type="checkbox" [name]="'name-checkbox' + hotel.Id" [id]="'id-checkbox' + hotel.Id" [checked]="hotel.Id == itemLamina.HotelId" (click)="selecionarHotel(hotel.Id, hotel.Quartos[0].ValorFechamento, hotel.Quartos[0].MoedaFechamento, hotel.Quartos[0].Adultos, hotel.Nome)">
          </div>
          <div class="col-md-11 p-3 row">
            <span class="col-md-4" style="align-self:center"> <strong>{{hotel.Nome}}</strong>
              <app-estrela class="ml-2" [estrela]="hotel.Estrelas"></app-estrela>
            </span>
            <div class="col-md-4" style="font-weight: 600; align-self: center;">
              <span class="text-center">{{hotel.Quartos[0]?.Descricao}}({{hotel.Quartos[0]?.DescricaoPensao}})</span><br>
              <span class="text-center">{{"Checkin" | translate}}: {{hotel.CheckinOpcao}}, {{"Checkout" | translate}}:
                {{hotel.CheckoutOpcao}}</span>
            </div>
            <div class="col-md-4 text-right align-self-center">
              {{'Total' | translate}}:<br><strong class="texto-Preco" style="color:black;">{{hotel.Quartos[0].MoedaFechamento?.Sigla}}&nbsp;{{hotel.Quartos[0]?.ValorFechamento |
                currency:'BRL':'':'2.2-2'}}</strong>
            </div>
          </div>
        </div>
      </div>

      <!-- AÉREOS -->
      <div class="p-2 mt-2" class="tab-pane fade" id="aereos" aria-labelledby="aereos-tab" role="tabpanel" *ngIf="(Reserva.Aereos?.length > 0 || Reserva.AereoPacote?.length > 0)">
        <div *ngFor="let aereo of Reserva.Aereos; let i = index;" class="row d-flex align-items-center w-100" style="border-bottom: 1px solid #a4abb3; margin: 2rem 0rem;">
          <div class="col-md-12 text-center" *ngIf="aereo.Id == itemLamina.AereoId">
            <span>Selecionado</span>
          </div>
          <a (click)="selecionarAereo(aereo.Id, aereo.ValorFechamento, aereo.TaxasFechamentoTotal, aereo.Adt);">
            <div class="col-md-12 w-100">
              <div class="row">
                <app-aereo [Laminas]="true" class="w-100" [aereo]="aereo"></app-aereo>
              </div>
              <div class="row" *ngIf="aereo.PaxesTarifa?.length > 0">
                <div class="col-md-12">
                  <table class="table">
                    <thead>
                      <tr class="text-center">
                        <th>{{'Tipo' | translate}}</th>
                        <th>{{'Qtde' | translate}}</th>
                        <th>{{'Tarifa(un)' | translate}}</th>
                        <th>{{'Taxas de serviço(un)' | translate}}</th>
                        <th>{{'Taxas(un)' | translate}}</th>
                        <th>{{'Valor por pax' | translate}}</th>
                        <th>{{'SubTotal' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let tarifa of aereo.PaxesTarifa" class="text-center">
                        <td>
                          {{(tarifa.TipoPax == 0 ? 'Adulto' : (tarifa.TipoPax == 1 ? 'Criança' : 'Bebê')) | translate}}
                        </td>
                        <td>{{tarifa.QtdPax}}</td>
                        <td>{{aereo.MoedaListagem.Sigla}} {{tarifa.ValorTarifaListagem | currency:'BRL':'':'1.2-2' }}
                        </td>
                        <td>{{aereo.MoedaListagem.Sigla}} {{tarifa.ValorTaxaServicoListagem |
                        currency:'BRL':'':'1.2-2'}}</td>
                        <td>{{aereo.MoedaListagem.Sigla}} {{tarifa.ValorTaxaListagem | currency:'BRL':'':'1.2-2' }}
                        </td>
                        <td>{{aereo.MoedaListagem.Sigla}} {{tarifa.ValorTarifaListagem + tarifa.TotalTaxasListagem +
                        tarifa.ValorTaxaDUListagem| currency:'BRL':'':'1.2-2' }}</td>
                        <td>{{aereo.MoedaListagem.Sigla}} {{tarifa.SubTotalListagem | currency:'BRL':'':'1.2-2' }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div *ngFor="let aereo of Reserva.AereoPacote; let i = index;" class="row d-flex align-items-center w-100" style="border-bottom: 1px solid #a4abb3; margin: 2rem 0rem;">
          <div class="col-md-12 text-center" *ngIf="aereo.Id == itemLamina.AereoId">
            <span>Selecionado</span>
          </div>
          <a (click)="selecionarAereo(aereo.Id, aereo.ValorFechamento, aereo.TaxasFechamentoTotal, aereo.Adt);">
            <div class="col-md-12 w-100">
              <div class="row">
                <app-aereo [Laminas]="true" class="w-100" [aereo]="aereo"></app-aereo>
              </div>
              <div class="row" *ngIf="aereo.PaxesTarifa?.length > 0">
                <div class="col-md-12">
                  <table class="table">
                    <thead>
                      <tr class="text-center">
                        <th>{{'Tipo' | translate}}</th>
                        <th>{{'Qtde' | translate}}</th>
                        <th>{{'Tarifa(un)' | translate}}</th>
                        <th>{{'Taxas de serviço(un)' | translate}}</th>
                        <th>{{'Taxas(un)' | translate}}</th>
                        <th>{{'Valor por pax' | translate}}</th>
                        <th>{{'SubTotal' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let tarifa of aereo.PaxesTarifa" class="text-center">
                        <td>{{(tarifa.TipoPax == 0 ? 'Adulto' : (tarifa.TipoPax == 1 ? 'Criança' : 'Bebê')) |
                      translate}}</td>
                        <td>{{tarifa.QtdPax}}</td>
                        <td>{{aereo.MoedaListagem.Sigla}} {{tarifa.ValorTarifaListagem | currency:'BRL':'':'1.2-2' }}
                        </td>
                        <td>{{aereo.MoedaListagem.Sigla}} {{tarifa.ValorTaxaServicoListagem |
                      currency:'BRL':'':'1.2-2'}}</td>
                        <td>{{aereo.MoedaListagem.Sigla}} {{tarifa.ValorTaxaListagem | currency:'BRL':'':'1.2-2' }}
                        </td>
                        <td>{{aereo.MoedaListagem.Sigla}} {{tarifa.ValorTarifaListagem + tarifa.TotalTaxasListagem +
                      tarifa.ValorTaxaDUListagem| currency:'BRL':'':'1.2-2' }}</td>
                        <td>{{aereo.MoedaListagem.Sigla}} {{tarifa.SubTotalListagem | currency:'BRL':'':'1.2-2' }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>

    </div>

    <div class="col-md-12 d-flex justify-content-center mt-1 mb-3">
      <button class="btn btn-success" [disabled]="!Steps.FirstStep" (click)="bucarFormasPagamento(); indexStep = (indexStep + 1)">{{'Próxima Etapa' | translate}}</button>
    </div>
  </ng-container>

  <!-- Pagamentos -->
  <ng-container *ngIf="indexStep == 1">
    <div class="row justify-content-center mb-3 mt-3">
      <div class="col-md-12 text-center">
        <label class="mb-4">{{'Escolha a forma de exibir o valor' | translate}}</label>
      </div>

      <div class="text-center col-md-12 d-flex justify-content-center" style="gap: 30px">
        <div>
          <input type='radio' [(ngModel)]="pagamentoLamina.ValorPorPessoa" [value]="false" id="id-radio-divide-off" name="radio-divide-off" (ngModelChange)="alterarFormaPagamento()">
          <label for="id-radio-divide-off">{{'Usar Valor Total' | translate}}</label>
        </div>
        <div>
          <input type='radio' [(ngModel)]="pagamentoLamina.ValorPorPessoa" [value]="true" id="id-radio-divide-on" name="radio-divide-on" (ngModelChange)="alterarFormaPagamento()">
          <label for="id-radio-divide-on">{{'Divide Valor por Adultos' | translate}}({{pagamentoLamina?.QntdAdtHotel}})</label>
        </div>
      </div>

    </div>

    <div class="row mt-5 mb-5 row justify-content-center">
      <div class="col-md-4">
        <label for="">Forma de Pagamento</label>
        <select [required]="true" class="form-control input-sm" [(ngModel)]="formaPagamento">
          <option *ngFor="let f of FormasPagamento" [ngValue]="f"> {{f.FormaPagamento.Descricao}} </option>
        </select>
      </div>

      <div class="col-md-4" [hidden]="formaPagamento?.Id == undefined">
        <label>{{'Condições' | translate}}</label>
        <select required class="form-control input-sm" [(ngModel)]="condicaoPagamento" (change)="selecionouCondicoes(condicaoPagamento)" (ngModelChange)="selecionouCondicoes(condicaoPagamento)">
          <ng-container *ngFor="let c of formaPagamento?.FormaPagamento?.Condicoes">
            <option [ngValue]="c">
              {{c.Parcelas}}x de {{pagamentoLamina?.CambioPagamento?.Sigla}}
              {{(pagamentoLamina?.ValorTotal * c.Coeficiente).toFixed(2) }} {{" / " + c.Descricao}}
            </option>
          </ng-container>
        </select>
      </div>
      <div class="col-md-2" [hidden]="!mostraValorTotal">
        <label>{{'Valor Total' | translate}}</label>
        <input disabled="true" class="form-control input-sm" [ngModel]="pagamentoLamina?.ValorTotal">
      </div>
    </div>
    <div class="row mt-3 mb-3">
      <div class="col-md-12 row">
        <h6 class="col-md-12">{{"Mais Opções" | translate}}<span style="font-size: 12px important" class="text-danger">*{{"Qualquer Informação Inserida é Responsabilidade do Usuário" | translate }}!</span></h6>
        <div class="col-md-12">
          <div class="w-100 row">
            <div class="col-md-5" *ngIf="permissaoCadastro">
              <label>{{'Frase Opcional' | translate}}</label>
              <input autocomplete="off" type="text" class="form-control input-sm" maxlength="40" [(ngModel)]="itemLamina.FraseOpcional">
            </div>
            <div class="col-md-5" *ngIf="permissaoCadastro">
              <label>{{'Nome do Hotel' | translate}}</label>
              <input autocomplete="off" type="text" class="form-control input-sm" [(ngModel)]="itemLamina.NomeHotel">
            </div>
            <div class="col-md-2">
              <label for="">{{"Tradução" | translate}} </label>
              <select [required]="true" class="form-control input-sm" [(ngModel)]="itemLamina.Traducao">
                <option *ngFor="let t of traducoesDisponiveis" [ngValue]="t"> {{t == "pt" ? "Português" : ""}}
                  {{t == "es" ? "Espanhol" : ""}} {{t == "en" ? "Inglês" : ""}} </option>
              </select>
            </div>
          </div>
          <div class="w-100 row">
            <div class="col-md-4 ml-3 mt-3">
              <label for="">{{"Mostrar Logo da Agência" | translate}}?</label>
              <div class="text-center col-md-12 d-flex" style="gap: 20px">
                <div style="gap: 10px">
                  <input type='radio' [(ngModel)]="itemLamina.UtilizarLogoAgencia" [value]="true" [selected]="true" name="radio-logo-agencia" id="radio-logo-agencia-id">
                  <label for="radio-logo-agencia">{{'Sim' | translate}}</label>
                </div>
                <div style="gap: 10px">
                  <input type='radio' [(ngModel)]="itemLamina.UtilizarLogoAgencia" [value]="false" name="radio-logo-agencia1" id="radio-logo-agencia-id1">
                  <label for="radio-logo-agencia1">{{'Não' | translate}}</label>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center" style="gap: 20px">
        <button class="btn btn-warning col-md-3" (click)="indexStep = (indexStep - 1)">{{'Voltar Etapa' | translate}}</button>
        <button class="btn btn-success col-md-3" [disabled]="!Steps.SecondStep" (click)="buscarImagensTemplate(); indexStep = (indexStep + 1)">{{'Próxima Etapa' | translate}}</button>
      </div>
    </div>
  </ng-container>
  <!-- Fim Pagamentos -->

  <!-- Templates -->
  <ng-container *ngIf="indexStep == 2">
    <div>
      <div *ngIf="carregando">
        <img src="assets\img\loading1.gif" class="imagem">
      </div>

      <div class="col-md-12 row justify-content-center m-3">
        <h4 class="col-md-6 text-center">{{"Escolha um template pra gerar sua imagem" | translate}}!</h4>
      </div>

      <div class="row col-md-12 m-2">
        <div class="col-md-3 d-flex justify-content-center p-3" *ngFor="let Template of templatesLamina; let i = index;">
          <div class="h-100 w-100 card">
            <div class="col-md-12 p-0">
              <img [src]="Template.URL" style="height: 100%; width: 100%; object-fit: fill;">
            </div>
            <div class="col-md-12 text-center d-flex justify-content-center p-3 card-footer">
              <div>
                <input type="checkbox" style="width: 25px; height: 25px" [checked]="Template.Id == itemLamina.TemplateId" (click)="selecionarTemplate(Template.Id)">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div>
          <app-paginacao [paginacao]="paginacaoTemplate" (alterarPagina)="buscarImagensTemplatePagina($event)"></app-paginacao>
        </div>
      </div>
      <div class="row justify-content-center mb-3" style="gap: 20px">
        <button class="col-md-3 btn btn-warning mr-2" (click)="indexStep = (indexStep - 1)">{{'Voltar Etapa' | translate}}</button>
        <button class="col-md-3 btn btn-success ml-2" [disabled]="!Steps.ThirdStep" (click)="buscarImagensFundo(); indexStep = (indexStep + 1)">{{'Próxima Etapa' | translate}}</button>
      </div>
    </div>
  </ng-container>
  <!-- Fim Templates -->

  <!-- Imagens -->
  <ng-container *ngIf="indexStep == 3">
    <div>
      <div class="col-md-12 d-flex justify-content-center m-3">
        <h4 class="col-md-6 text-center">{{"Escolha uma imagem de fundo" | translate}}!</h4>
      </div>

      <div class="col-md-12 d-flex justify-content-center mb-3 mt-2" style="gap: 20px">
        <div style="gap: 10px">
          <input type='radio' [(ngModel)]="buscaApi" [value]="true" name="radio-buscar-api" id="radio-buscar-api-id" (ngModelChange)="alterarFormaBusca($event)" class="form-check-input">
          <label for="radio-buscar-api">{{'Buscar na API' | translate}}</label>
        </div>
        <div style="gap: 10px">
          <input type='radio' [(ngModel)]="buscaApi" [value]="false" name="radio-buscar-unsplash" id="radio-buscar-unsplash-id" (ngModelChange)="alterarFormaBusca($event)" class="form-check-input">
          <label for="radio-buscar-unsplash">{{'Buscar no Unsplash' | translate}}</label>
        </div>
      </div>

      <ng-container *ngIf="buscaApi">
        <div class="row justify-content-center mb-4">
          <div class="col-md-5 m-0 p-0">
            <input placeholder="{{'Buscar por Nome' | translate}}" fullWidth fieldSize="medium" shape="rectangle" placeholder="Buscar" autocomplete="off" class="w-100 h-100 form-control" [(ngModel)]="parametroBusca" />
          </div>
          <div class="col-md-1 p-0 m-0 text-center">
            <button [disabled]="parametroBusca.length == 0" (click)="buscarImagensFundo()" class="btn btn-primary" title="Buscar">
              <i class="fa fa-search"></i></button>
          </div>
        </div>

        <div *ngIf="carregando" class="overlay">
          <img src="assets\img\loading1.gif" class="imagem">
        </div>

        <div class="row m-2">
          <div class="col-md-3 d-flex justify-content-center p-3 mb-2" *ngFor="let Lamina of imagensLamina; let i = index;">
            <div class="col-md-12 card p-0 m-0">
              <div class="card-header text-center">
                <span class="card-title" style="white-space:prewrap">{{Lamina.Nome}}</span>
              </div>
              <div class="card-body p-0">
                <img [src]="Lamina.URL" style="height: 100%; width: 100%; object-fit: fill;" loading="lazy">
              </div>
              <div class="text-center d-flex justify-content-center p-3 card-footer">
                <div>
                  <input type="checkbox" style="width: 25px; height: 25px" [checked]="Lamina.Id == itemLamina.ImagemId" (click)="selecionarImagem(Lamina.Id)">
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!buscaApi">
        <div class="row justify-content-center mb-4">
          <div class="col-md-5 m-0 p-0">
            <input placeholder="{{'Buscar No Unsplash' | translate}}" fullWidth fieldSize="medium" shape="rectangle" placeholder="Buscar" autocomplete="off" class="w-100 h-100 form-control" [(ngModel)]="parametroBusca" />
          </div>
          <div class="col-md-1 p-0 m-0 text-center">
            <button [disabled]="parametroBusca.length == 0" (click)="buscarImagensUnsplash()" class="btn btn-primary" title="Buscar">
              <i class="fa fa-search"></i></button>
          </div>
        </div>

        <div *ngIf="carregando" class="overlay">
          <img src="assets\img\loading1.gif" class="imagem">
        </div>

        <div class="row m-2">
          <div class="col-md-3 d-flex justify-content-center p-3 mb-2" *ngFor="let Lamina of imagensLaminaUnsplash; let i = index;">
            <div class="col-md-12 card p-0 m-0">
              <div class="card-header text-center">
                <span class="card-title" style="white-space:prewrap">{{Lamina.Nome}}</span>
              </div>
              <div class="card-body p-0">
                <img [src]="Lamina.URL" style="height: 100%; width: 100%; object-fit: fill;" loading="lazy">
              </div>
              <div class="card-footer text-center d-flex justify-content-center p-3">
                <div>
                  <input type="checkbox" style="width: 22px; height: 22px" [checked]="Lamina.URL == itemLamina.ImagemURL" (click)="selecionarImagemUnsplash(Lamina.URL)">
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="buscaApi">
        <app-paginacao [paginacao]="paginacao" (alterarPagina)="buscarImagensFundoPagina($event)"></app-paginacao>
      </ng-container>

      <ng-container *ngIf="buscaApi">
        <!-- <app-paginacao [paginacao]="paginacao" (alterarPagina)="buscarImagensFundoPagina($event)"></app-paginacao> -->
      </ng-container>

      <div class="row justify-content-center mb-3" style="gap: 20px">
        <button class="col-md-3 btn btn-warning mr-2" (click)="indexStep = (indexStep - 1)">{{'Voltar Etapa' | translate}}</button>
        <button class="col-md-3 btn btn-success" [disabled]="!Steps.FourthStep" (click)="gerarImagemHTML(); indexStep = (indexStep + 1)">{{'Próxima Etapa' | translate}}</button>
      </div>
    </div>
  </ng-container>
  <!-- Fim Imagens -->

  <!-- Download -->
  <ng-container *ngIf="indexStep == 4">
    <div *ngIf="carregando" class="col-md-12 row justify-content-center">
      <div class="col-md-5 d-flex justify-content-center p-3">
        <div class="card p-0 d-flex justify-content-center align-items-center">
          <img src="assets\img\loading1.gif" style="height: auto; width: 30%; object-fit: fill; object-position: center;">
        </div>
      </div>
    </div>

    <div class="col-md-12 row justify-content-center mb-2 mt-2">
      <h4 class="col-md-6 text-center">{{"Faça o download da sua imagem" | translate}}!</h4>
    </div>

    <div class="col-md-12 row justify-content-center">
      <div class="col-md-5 d-flex justify-content-center p-3" *ngIf="imgDownload?.length > 0">
        <div class="h-100 w-100 card">
          <div class="p-0">
            <img [src]="imgDownload" style="height: auto; width: 100%; object-fit: fill;">
          </div>
          <div class="text-center d-flex justify-content-center p-3 card-footer">
            <button class="btn btn-primary" (click)="saveFile()">{{"Baixar Imagem" | translate}}
              <i class="fa fa-download" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center mb-3 mt-5" style="gap: 20px">
      <button class="col-md-3 btn btn-warning mr-2" (click)="indexStep = (indexStep - 1)">{{'Voltar Etapa' | translate}}</button>
      <button class="col-md-3 btn btn-success ml-2" (click)="fecharModal()">{{'Fechar' | translate}}</button>
    </div>
  </ng-container>
  <!-- Fim Download -->

  <div class="modal-footer" style="display:block">
    <div *ngIf="mensagemErro != ''" class="alert alert-danger col col-md-12" role="alert">
      <strong>{{ mensagemErro}}</strong>
      <a style="cursor: pointer" (click)="mensagemErro = ''">
        <span aria-hidden="true">&times;</span>
      </a>
    </div>
  </div>
</div>
