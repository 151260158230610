<div class="modal-header">
  <h3 class="modal-title text-center" id="modal-basic-title">{{hotel.Nome}}</h3>
  <button type="button" class="close btn" [disabled]="carregando" aria-label="Close" (click)="fecharModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="!carregando && !pacote">
    <p style="padding-top: 10px;" class="mb-3">
      {{hotel?.Endereco}}, {{hotel?.Cidade?.Nome}}
    </p>
    <p [innerHTML]="hotel?.Detalhes?.Descricao" class="mb-3">
    </p>
    <ul>
      <li *ngFor="let Facilidade of hotel?.Detalhes?.Facilidades">
        {{Facilidade}}
      </li>
    </ul>

    <!-- <div style="max-height: 400px; overflow-y: hidden; overflow-x: auto;" class="row">
        <div class="row" *ngFor="let Imagem of hotel?.Detalhes?.Imagens; let i = index;">
          <img class="d-block w-100" [src]="Imagem" alt="Hotel Imagens">
        </div>
      </div> -->

  </div>
  <div *ngIf="pacote == true">
    <div class="card">
      <img [src]="pacoteReserva?.PacoteImagens[0]?.Imagem" class="card-img-top card-fluid mb-2"
        [alt]="'Imagem do ' + pacoteReserva.Nome" style="max-height: 600px; object-fit:fill">
      <div class="card-body">
        <!-- <h3 class="card-title text-center cor-primaria mb-4">{{pacoteReserva.Nome}}</h3> -->
        <div class="p-2">
          <ul class="nav nav-tabs" id="tabInfoServico" role="tablist">
            <li class="nav-item" role="presentation"
              *ngFor="let inclui of pacoteReserva?.Pacote?.PacotesInclui; let x = index">
              <button class="nav-link" [ngClass]="x == 0 ? 'active' : ''" id="tabservico{{x}}" data-bs-toggle="tab"
                [attr.data-bs-target]="'#tabservicox' + x" type="button" style="text-decoration: none !important;"
                role="tab" [attr.aria-controls]="'tabservicox' + x"
                [attr.aria-selected]="x == 0 ? true : false">{{inclui?.Titulo}}
              </button>
            </li>
          </ul>
          <div class="tab-content p-2 mt-2" id="tabInfoServicoContent">
            <div *ngFor="let inclui of pacoteReserva?.Pacote?.PacotesInclui; let x = index" class="tab-pane fade"
              id="tabservicox{{x}}" [ngClass]="x == 0 ? 'show active' : ''" role="tabpanel"
              [attr.aria-labelledby]="'tabservico' + x" [innerHTML]="inclui?.Descritivo"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer" style="display:block">
  </div>