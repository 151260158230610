import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Paginacao } from '../../model';

@Component({
  selector: 'app-paginacao',
  templateUrl: './paginacao.component.html',
  styleUrls: ['./paginacao.component.scss'],
})
export class PaginacaoComponent implements OnInit {

  @Input() paginacao: Paginacao = new Paginacao();

  @Output() alterarPagina = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  paginar(pagina: any) {

    this.alterarPagina.emit(pagina);
  }

}
