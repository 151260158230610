import { Alteracoes } from './base';
import { Cidade } from './endereco.model';
import { Empresa } from './cadastro/empresa.model';
import { Agencia } from './cadastro/agencia.model';
import { PerfilUsuario } from './configuracao/perfil-usuario.model';

export  class Usuario {
  Nome: string;
  Sobrenome: string;
  Email: string;
  Validade?: any;
  Telefone: string;
  DataNascimento?: any;
  Empresa: Empresa;
  EmpresaId?: any;
  MetaVendaMensal: number;
  Agente: boolean;
  AgenteAdmin: boolean;
  TrocarSenha: boolean;
  Agencia: Agencia;
  AgenciaId?: any;
  Perfis: PerfilUsuario[];
  AgenciasConsultor?: any;
  Alteracoes: Alteracoes;
  Cep: string;
  Logradouro: string;
  Numero: string;
  Complemento: string;
  Bairro: string;
  Cidade: Cidade;
  CidadeId: number;
  Id: number;
  Erros?: any;

  access: string;
  refresh: string;
  tokenExpire: number;

  constructor() {
    this.Cidade = new Cidade();
    this.Agencia = new Agencia();
    this.Empresa = new Empresa();

  }
}
