import { Orcamento } from './../../../../model/turismo/orcamento.model';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

export const ORCAMENTO = 'orcamentousuario';

@Component({
  selector: 'app-orcamento-aereo-card',
  templateUrl: './orcamento-aereo-card.component.html',
  styleUrls: ['./orcamento-aereo-card.component.scss']
})
export class OrcamentoAereoCardComponent implements OnInit {

  @Input() index: any;
  @Input() aereo: any;
  @Input() pacote: any;

  @Output() removeuAereo = new EventEmitter<any>();

  user: any;

  constructor() { }


  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('loggedUser'));
  }

  removeu(aereo: any) {
    this.removeuAereo.emit(aereo);
  }

  delete() {
    const confirmacao = confirm('Deletar aéreo?');
    if (confirmacao === true) {
      sessionStorage.removeItem(ORCAMENTO);
      /*
        TO-DO:
          CONSERTAR ESSA FUNÇÃO, ESTÁ APAGANDO TUDO SENDO QUE DEVERIA
          APAGAR APENAS O ITEM "AEREOS" QUE ESTÁ ANINHADO NO ITEM
          ORCAMENTO NA SESSION STORAGE
      */
    }
  }
}
