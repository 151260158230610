// tslint:disable: variable-name

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Aereo, Busca, Orcamento, Usuario } from 'src/app/model';
import { ListagemComponent } from 'src/app/views/listagem/listagem.component';
@Component({
  selector: 'app-aereo',
  templateUrl: './aereo.component.html',
  styleUrls: ['./aereo.component.scss']
})
export class AereoComponent extends ListagemComponent implements OnInit {

  @Output() selecionouAereo = new EventEmitter<any>();
  @Output() removeuAereo = new EventEmitter<any>();
  @Input() aereo: Aereo;
  @Input() usuario: Usuario = new Usuario();
  @Input() busca: Busca = new Busca();
  @Input() selecionar = true;
  @Input() orcamento: Orcamento = new Orcamento;
  @Input() Laminas: boolean = false;

  // Orcamento: Orcamento = new Orcamento();
  tipoBusca: any;
  criancas: any;
  progress: any;
  adultos: any;
  rota: any;
  user: any;
  multiplosTrechos: any;
  viewLoaded = false;

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('loggedUser'));
    if(!this.Laminas){
    this.multiTrechos();
    }
  }

  selecionou(aereo: any) {
    this.selecionouAereo.emit(aereo);
  }

  removeu(aereo: any) {
    this.removeuAereo.emit(aereo);
  }

  multiTrechos() {
    const multiplosTrechos = JSON.parse(sessionStorage.getItem('busca'))
    this.multiplosTrechos = multiplosTrechos.tipoBuscaAereo;
    (this.multiplosTrechos == 'multiplos-trechos' ? true : false);
  }

  console(a) {
    console.log(a)
  }

}
