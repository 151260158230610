// tslint:disable: max-line-length
// tslint:disable: use-life-cycle-interface

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AereoService } from 'src/app/services';
import { Aereo } from 'src/app/model';
import { __read, __spreadArrays } from 'tslib';

@Component({
  selector: 'app-aereo-emite',
  templateUrl: './aereo-emite.component.html',
  styleUrls: [
    './aereo-emite.component.scss',
    '../../../../assets/styles/layoutPattern.scss'
  ]
})
export class AereoEmiteComponent implements OnInit {
  constructor( private aereoService: AereoService ) { }

  @Output() alterou = new EventEmitter<any>();
  @Output() fechou = new EventEmitter<any>();
  @Input() aereo: any;

  cienteAlterouValor = false;

  carregando = false;
  progress: number;
  aereoRetarifado: Aereo;
  mensagensErro: any[];
  mensagemSucesso: string;


  ngOnInit() {
    this.progress = 1;

  }

  emitirAereo() {
    this.carregando = true;
    delete this.mensagensErro;
    delete this.mensagemSucesso;

    this.aereoService.emitirAereo(this.aereo).subscribe(
      dados => {
        this.aereo = dados;
        this.carregando = false;
        this.alterou.emit(this.aereo);
        this.progress++;
      }, erros => {
        console.log(erros);
        this.mensagensErro = this.errorHandler(erros);
        this.carregando = false;
      });
  }

  public errorHandler(erro): string[] {

    let mensagem = [];
    switch (erro.status) {
      case 0: mensagem.push('o servidor não respondeu, tente novamente mais tarde!'); break;
      case 400: mensagem.push('Houve algum erro na execução, tente novamente!'); break;
      case 401: mensagem.push('você não tem autorização para executar esta ação!'); break;
      case 404: mensagem.push('Solicitação inválida!'); break;
      case 406: mensagem.push(erro.error.mensagem); break;
      case 500: mensagem.push('Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte'); break;
      default: mensagem.push(erro.statusText);
    }
    if (erro.error != null && erro.error.mensagens != null) {
      mensagem = __spreadArrays([mensagem], __read(erro.error.mensagens));
    }
    return mensagem;

  }
}
