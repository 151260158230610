import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';



import { PerfilMarkup } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class PerfilMarkupService {

  constructor(private http: HttpClient) {}


    buscarPorParametro(param: string, pagina: Number): Observable<any> {
      const headers = new HttpHeaders().set('noloader', 't');
      if (param.length > 0) {
        return this.http.get<any>( `${GERENCIAMENTO}/api/PerfilMarkup/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers});
      } else {
        return this.http.get<any>( `${GERENCIAMENTO}/api/PerfilMarkup?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers});
      }
    }

    buscarPorId(id: number): Observable<any> {
        return this.http.get<any>( `${GERENCIAMENTO}/api/PerfilMarkup/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any> {
      return this.http.delete<any>( `${GERENCIAMENTO}/api/PerfilMarkup/${id}`);
    }

    salvar(perfilmarkup: PerfilMarkup): Observable<any> {
      if (perfilmarkup.Id > 0) {
        return this.http.put<any>( `${GERENCIAMENTO}/api/PerfilMarkup/${perfilmarkup.Id}`, perfilmarkup);
      } else {
        return this.http.post<any>( `${GERENCIAMENTO}/api/PerfilMarkup/`, perfilmarkup);
      }

    }

    vincularMarkup(id: number, markupId: number): Observable<any> {
    return this.http.post<any>( `${GERENCIAMENTO}/api/perfilmarkup-markup/vincular/${id}/${markupId}`, {});
    }

    desvincularIMarkup(id: number, markupId: number): Observable<any> {
      return this.http.delete<any>( `${GERENCIAMENTO}/api/perfilmarkup-markup/desvincular/${id}/${markupId}`, {});
    }

  }
