<div class="trecho">
  <div class="circle-left">
    <i class="fas fa-circle"></i>
  </div>
  <div class="traco">
  
  </div>
  <div class="circle-right">
    <i class="fas fa-circle"></i>
  </div>
</div>