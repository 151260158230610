import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Agencia } from '../../model';
import { EMPRESAS, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class AgenciaService {

  constructor(private http: HttpClient) { }

  // tslint:disable-next-line: ban-types
  buscarPorParametro(param: string, pagina: Number): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    if (param.length > 0) {
      // tslint:disable-next-line: max-line-length
      return this.http.get<any>(`${EMPRESAS}/api/Agencia/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers });
    } else {
      return this.http.get<any>(`${EMPRESAS}/api/Agencia?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers });
    }
  }

  buscarTodos() {
    return this.http.get<any>(`${EMPRESAS}/api/Agencia?Pagina=1&ItensPorPagina=20`);
  }

  buscarTodosToList() {
    return this.http.get<any>(`${EMPRESAS}/api/agencia/list/agenciastolist`);
  }


  buscarPorId(id: number): Observable<any> {
    return this.http.get<any>(`${EMPRESAS}/api/Agencia/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  }

  // tslint:disable-next-line: ban-types
  remover(id: Number): Observable<any> {
    return this.http.delete<any>(`${EMPRESAS}/api/Agencia/${id}`);
  }

  salvar(agencia: Agencia): Observable<any> {
    if (agencia.Id > 0) {
      return this.http.put<any>(`${EMPRESAS}/api/Agencia/${agencia.Id}`, agencia);
    } else {
      return this.http.post<any>(`${EMPRESAS}/api/Agencia/`, agencia);
    }

  }

  uploadLogo(arquivo: any, id: number): Observable<any> {

    const url = `${EMPRESAS}/api/agencia/logo/${id}/`;

    const headers = new HttpHeaders().append('noloader', 'noloader');
    const formData = new FormData();
    formData.append('Arquivo', arquivo);

    return this.http.post(url, formData, { headers });
  }

  vinculaIntegrador(id: number, integradorId: number): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/agencia-integrador/vincular/${id}/${integradorId}`, {});
  }

  desvincularIntegrador(id: number, integradorId: number): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/agencia-integrador/desvincular/${id}/${integradorId}`, {});
  }

  vincularConsultor(id: number, consultorId: number): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/agencia-consultor/vincular/${id}/${consultorId}`, {});
  }

  desvincularConsultor(id: number, consultorId: number): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/agencia-consultor/desvincular/${id}/${consultorId}`, {});
  }

  aumentarLimiteCredito(id: number, valor: number, obs: string): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/agencia-gestao/aumentarlimite/${id}`, { valor, observacao: obs });
  }

  revogarLimiteCredito(id: number, valor: number, obs: string): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/agencia-gestao/revogarlimite/${id}`, { valor, observacao: obs });
  }

  bloquearAgencia(id: number, valor: number, obs: string): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/agencia-gestao/bloquear/${id}`, { valor, observacao: obs });
  }

  liberarAgencia(id: number, valor: number, obs: string): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/agencia-gestao/liberar/${id}`, { valor, observacao: obs });
  }

  reprovarAgencia(id: number, valor: number, obs: string): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/agencia-gestao/reprovar/${id}`, { valor, observacao: obs });
  }

  salvarFormaPagamento(id: number, agenciaForma: any): Observable<any> {
    return this.http.post<any>(`${EMPRESAS}/api/agencia-formapgto/salvar/${id}`, agenciaForma);
  }

}
