import { Component, EventEmitter, Input, OnInit, Output,ViewChild,ElementRef } from '@angular/core';
import { Paginacao, Usuario } from 'src/app/model';
import { AgenciaService, LoginService } from 'src/app/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// tslint:disable: max-line-length

export const BUSCA = 'busca';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit  {
  mensagemErro = '';
  parametroBusca = '';
  mensagemSuccesso = '';
  parametroId: any = '';
  agencia: any;
  nome: string;

  dados = [];
  paginacao: Paginacao = new Paginacao();
  @ViewChild("alterarsenha") alterarsenha: ElementRef;

  @Output() deslogou = new EventEmitter<any>();
  @Input() usuario: Usuario;

  constructor(
    private loginService: LoginService,
    private agenciaService: AgenciaService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.buscar();

    let agencia = window.localStorage.getItem('g8h7d8');

    if (agencia != null) {
      agencia = JSON.parse(atob(agencia));
      this.agencia = agencia;
      this.nome = this.agencia.NomeFantasia
    } else if (this.usuario.Agencia != (null || undefined)) {
      this.nome = this.usuario.Agencia.NomeFantasia;
    } else {
      this.nome = 'Selecione uma agência'
    }
  }

  ngDoCheck() {
    if ((window.localStorage.getItem('g8h7d8') && !this.agencia) || ( window.localStorage.getItem('g8h7d8') && this.agencia && (this.agencia != JSON.parse(atob(window.localStorage.getItem('g8h7d8')))))) {
      const agencia = JSON.parse(atob(window.localStorage.getItem('g8h7d8')));
      this.agencia = agencia;
      if (this.agencia != (null||undefined)) {
        this.nome = this.agencia.NomeFantasia
      } else {
        this.nome = 'Selecione uma agência'
      }
    }
  }

  ngAfterViewInit() {
        this.verificaTrocarSenha();

  }
  openModalSenha(content) {

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered: true, size: 'lg' });
  }

  limpaPesquisa() {
    sessionStorage.removeItem(BUSCA);
  }

  senhaAlterada(dados) {
    this.logout();
  }

  buscar() {
    this.mensagemErro = '';
    this.mensagemSuccesso = '';
    let busca = this.agenciaService.buscarPorParametro(this.parametroBusca, this.paginacao.NumeroPagina);
    if (this.parametroId != undefined && this.parametroId != '') {
      this.paginacao.NumeroPagina = 1;
      busca = this.agenciaService.buscarPorId(this.parametroId);
    }
    busca.subscribe(
      dados => {
        this.dados = dados.Items;
        this.paginacao.NumeroPagina = dados.MetaData.NumeroPagina;
        this.paginacao.TotalItens = dados.MetaData.TotalItens;
        this.paginacao.ItensPorPagina = dados.MetaData.itensPorPagina;
        this.paginacao.TotalPaginas = dados.MetaData.TotalPaginas;
      },
      erro => {
        this.dados = [];
        console.log('Erro ao Buscar');
        console.log(erro);
        this.mensagemErro = this.errorHandler(erro, 'Buscar agências');
      },
    );
  }
  verificaTrocarSenha() {
    // console.log('Verificar trocar senha: ', this.usuario.TrocarSenha);
    if (this.usuario.TrocarSenha) {
      this.openModalSenha(this.alterarsenha);

      //  this.alterarsenha.nativeElement.click()
    };
    if (this.usuario.TrocarSenha == undefined)
      setTimeout(() => this.verificaTrocarSenha(), 600)
  }
  logout() {
    this.loginService.logout();
    this.deslogou.emit('Deslogou');
  }

  trocarAgencia(agencia) {
    this.agencia = agencia;
    this.agencia.Empresa = [];
    this.agencia.Cidade = [];
    this.agencia.PerfilMarkup = [];
    this.agencia.Comercial = [];

    localStorage.setItem('g8h7d8', btoa(JSON.stringify(agencia)));
  }

  removeuAgencia() {
    this.agencia = null;
  }

  public errorHandler(erro, acao: string = null): string {

    let mensagem = 'Erro';
    if (acao !== null) {
      mensagem += ' ao tentar ' + acao + ': ';
    }
    switch (erro.status) {
      case 0: mensagem += 'o servidor não respondeu, tente novamente mais tarde!'; break;
      case 401: mensagem += 'você não tem autorização para executar esta ação!'; break;
      case 404: mensagem += erro.error.mensagem ; break;
      case 406: mensagem += 'a requisição não foi aceita, tente novamente!' ; break;
      case 500: mensagem += 'Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte' ; break;
      default : mensagem += erro.statusText;
    }

    return mensagem;

  }
}
