import { Component, OnInit } from '@angular/core';
import { FAVICONLIGHT,ENDERECOTELEFONE,WPP,INSTAGRAM,FACEBOOK } from 'src/app/app.config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
 faviconlight = FAVICONLIGHT;
 enderecotelefone = ENDERECOTELEFONE;
 wpp = WPP;
 instagram = INSTAGRAM;
 facebook = FACEBOOK;
  constructor() { }

  ngOnInit() {
  }

}
