<div class="modal-header">
    <div>
      <h2 class="modal-title">Reservar Pacote</h2>
    </div>
  </div>
  <div class="col-sm-12 mb-2 modal-body">
    <h2 class="cor-primaria text-center">{{Pacote.Pacote.Nome}}</h2>
  </div>
  <div class="col-sm-12 mb-2">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 text-center">
        <h5>Data da Viagem</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="row" style="justify-content: center;">
          <div class="col-sm-12 col-md-3 col-lg-3">
            Ida: {{Pacote.Checkin| date :'dd/MM/yyyy'}}
          </div>
          <div class="col-sm-12 col-md-3 col-lg-3">
            Volta: {{Pacote.Checkout| date :'dd/MM/yyyy'}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row m-0">
    <div class="-flex justify-content-end modal-footer">
  
      <button [disabled]="carregando" *ngIf="!reservado" (click)="reservar()" class="btn btn-primary"
        type="submit">{{'Reservar'}}</button>
  
      <button (click)="fecharModal()" [disabled]="carregando" class="btn btn-outline-danger" type="submit">{{'Voltar'
        }}</button>
    </div>
  </div>
  
  
  <div *ngIf="mensagemSucesso || mensagensErro">
    <div class="col-md-12" *ngIf="mensagemSucesso">
      <div class="alert alert-success alert-dismissible fade show" role="alert">
        <div innerHTML="{{mensagemSucesso}}">
        </div>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    </div>
    <div class="col-md-12" *ngIf="mensagensErro">
      <div class="alert alert-danger alert-dismissible fade show" role="alert">
        <ul>
          <li *ngFor="let m of mensagensErro">
            {{m}}
          </li>
        </ul>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    </div>
  </div>