<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{"Recuperação de senha" | translate}} </h4>
</div>
<div class="modal-body">

  <form class="form" #form="ngForm" novalidate (keydown.enter)="$event.preventDefault()">
    <div class="alert alert-danger" *ngIf="errors">
      <ul>
        <li *ngFor="let erro of errors">
          {{erro}}
        </li>
      </ul>
    </div>
    <div class="alert alert-success" *ngIf="success">
      <p>{{success}}</p>
    </div>
    <div class="form-group row">
      <div class="col-sm-12">
        <app-input label="E-mail">
          <input [disabled]="loadingRecuperarSenha" type="text" class="form-control full-width" [(ngModel)]="email"
            required="required" name="email" placeholder="Informe seu E-mail" />
        </app-input>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-12">
        <button [disabled]="!form.valid" [hidden]="loadingRecuperarSenha" type="submit"
          class="btn btn-success  btn-block" (click)="recuperarSenha()">{{"Recuperar Senha" | translate}}</button>
      </div>
    </div>
  </form>
</div>
