import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trecho-simbol',
  templateUrl: './trecho-simbol.component.html',
  styleUrls: ['./trecho-simbol.component.scss']
})
export class TrechoSimbolComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
