// tslint:disable: max-line-length
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Empresa, EmailDados } from '../../model';
import { EMPRESAS, ITENSPORPAGINA } from '../../app.config';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class EmpresaService {

  constructor(private http: HttpClient) {}


    buscarPorParametro(param: string, pagina: number): Observable<any> {
      const headers = new HttpHeaders().set('noloader', 't');
      if (param.length > 0) {
        return this.http.get<any>( `${EMPRESAS}/api/Empresa/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers});
      } else {
        return this.http.get<any>( `${EMPRESAS}/api/Empresa?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers});
      }
    }

    buscarPorId(id: number): Observable<any> {
        return this.http.get<any>( `${EMPRESAS}/api/Empresa/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: number): Observable<any> {
      return this.http.delete<any>( `${EMPRESAS}/api/Empresa/${id}`);
    }

    salvar(empresa: Empresa): Observable<any> {
      if (empresa.Id > 0) {
        return this.http.put<any>( `${EMPRESAS}/api/Empresa/${empresa.Id}`, empresa);
      } else {
        return this.http.post<any>( `${EMPRESAS}/api/Empresa/`, empresa);
      }

    }

    uploadLogoIntranet(arquivo: any, id: number): Observable<any> {

      const url = `${EMPRESAS}/api/empresa/logointranet/${id}/`;

      const headers = new HttpHeaders().append('noloader', 'noloader');
      const formData = new FormData();
      formData.append('Arquivo', arquivo);

      return this.http.post(url, formData, {headers});

    }

    uploadLogoInternet(arquivo: any, id: number): Observable<any> {

      const url = `${EMPRESAS}/api/empresa/logointernet/${id}/`;
      const headers = new HttpHeaders().append('noloader', 'noloader');

      const formData = new FormData();
      formData.append('Arquivo', arquivo);

      return this.http.post(url, formData, {headers});

    }

    buscarConsultor( empresaId: number, valor: string): Observable<any> {

      return this.http.get<any>( `${EMPRESAS}/api/empresa/consultores/${empresaId}/${valor}`);
    }

    enviarEmailContato(email: EmailDados): Observable<any> {
      return this.http.patch<any>(`${EMPRESAS}/api/empresa/enviaremailcontato`, email);
    }
  }
