<div *ngIf="carregando" class="overlay">
  <img src="assets/img/loading1.gif" class="imagem">
</div>
<div class="modal-header">
  <div class='row'>
    <div class='col'>
      <h4 class="modal-title" id="modal-basic-title">{{"Alterar Agência" | translate}}</h4>
    </div>
    <div class='col text-end'>
      <button class="btn btn-success" (click)="limpar()" data-bs-dismiss="modal">{{"Remover Agência" | translate}}</button>
    </div>
  </div>
</div>
<div class="modal-body">
      <div class="strpied-tabled-with-hover">
        <div class="card-header _pb-20">
          <h4 class="card-title">Agências</h4>
          <div class="row">
            <div class="col col-md-3">
              <input type="number" fullWidth fieldSize="medium" shape="rectangle" placeholder="ID" autocomplete="off"
              name="buscarporid" [(ngModel)]="parametroId" (input)="buscarDebounce()" class="form-control"/>
            </div>
            <div class="col col-md-8">
              <input fullWidth fieldSize="medium" shape="rectangle" placeholder="Buscar" autocomplete="off"
              name="buscar" [(ngModel)]="parametroBusca" [disabled]="(parametroId != null && parametroId != '') ? true : false" (input)="buscarDebounce()" class="form-control"/>
            </div>
            <div class="col col-md-1">
              <button [disabled]="parametroBusca.length == 0" (click)="buscarPorParametro()" class="btn btn-success" status="primary" size="small" title="Buscar"  outline ghost><i class="fa fa-search"></i></button>
            </div>
          </div>
        </div>
        <div class="table-full-width table-responsive _pt-20">
          <table class="table table-hover table-striped">
            <thead>
              <th>ID</th>
              <th>{{"Status" | translate}}</th>
              <th>{{"Razão Social" | translate}}</th>
              <th>{{"Nome Fantasia" | translate}}</th>
              <th>CNPJ</th>
              <th>{{"Selecionar" | translate}}</th>
            </thead>
            <tbody>
              <tr *ngFor="let dado of dados">
                <td>{{dado.Id}}</td>
                <td [ngSwitch]="dado.Situacao">
                  <span *ngSwitchCase="1">{{"Bloqueada" | translate}}</span>
                  <span *ngSwitchCase="2">{{"Pendente" | translate}}</span>
                  <span *ngSwitchCase="3">{{"Reprovada" | translate}}</span>
                </td>
                <td>{{dado.RazaoSocial | titlecase}}</td>
                <td>{{dado.NomeFantasia | titlecase}}</td>
                <td>{{dado.CNPJ}}</td>
                <td>
                  <button (click)="selecionar(dado)" type="button" class="btn btn-success botao-selecionar" data-bs-dismiss="modal">
                    {{"Selecionar" | translate}}
                  </button>
                  <!-- <button (click)="selecionar(dado)" type="button" class="btn btn-success botao-selecionar" data-bs-dismiss="modal" style="margin-left:5px">
                    Selecionar
                  </button> -->
                </td>
              </tr>
            </tbody>
          </table>
          <hr>

        <app-paginacao [paginacao]="paginacao" (alterarPagina)="buscarPagina($event)" ></app-paginacao>
        </div>
      </div>
</div>
