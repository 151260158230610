import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';



import { Cambio, CambioTaxa } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class CambioService {

  constructor(private http: HttpClient) {}


    buscarPorParametro(param: string, pagina: Number): Observable<any> {
      const headers = new HttpHeaders().set('noloader', 't');
      if (param.length > 0) {
        return this.http.get<any>( `${GERENCIAMENTO}/api/Cambio/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers});
      } else {
        return this.http.get<any>( `${GERENCIAMENTO}/api/Cambio?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers});
      }
    }

    buscarPorId(id: number): Observable<any> {
        return this.http.get<any>( `${GERENCIAMENTO}/api/Cambio/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any> {
      return this.http.delete<any>( `${GERENCIAMENTO}/api/Cambio/${id}`);
    }

    salvar(cambio: Cambio): Observable<any> {
      if (cambio.Id > 0) {
        return this.http.put<any>( `${GERENCIAMENTO}/api/Cambio/${cambio.Id}`, cambio);
      } else {
        return this.http.post<any>( `${GERENCIAMENTO}/api/Cambio/`, cambio);
      }

    }

    atualizarCambioTaxas(listaCambioTaxa: CambioTaxa[]): Observable<any> {
      return this.http.post<any>( `${GERENCIAMENTO}/api/cambiotaxa/atualizar`, listaCambioTaxa);
    }

  }
