import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable()
export class LoaderService {
    isLoading = new Subject<boolean>();
    show() {
        this.isLoading.next(true);
        // console.log("Mostra o loader");
    }
    hide() {
        this.isLoading.next(false);
        // console.log("Esconde o loader")
    }
}
