import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef, TemplateRef } from '@angular/core';
import { Paginacao} from 'src/app/model';
import { CambioService } from 'src/app/services';
import * as moment from "moment";

@Component({
  selector: 'app-cambio',
  templateUrl: './cambio.component.html',
  styleUrls: ['./cambio.component.scss']
})
export class CambioComponent implements OnInit {

  private logModalref;

  parametroBusca = '';
  parametroId: any = '';
  carregando = false;
  mensagemErro = '';
  mensagemSuccesso = '';

  moment: any = moment;

  dados = [];
  paginacao: Paginacao = new Paginacao();

  alteracoesCambio : any;
  chaveCambio : any;


  timer = null;
  buscarDebounce(){
    clearTimeout(this.timer);
    this.timer = setTimeout(() =>  this.buscarPorParametro(), 600);

  }

  constructor(private cambioService: CambioService) { }

  ngOnInit() {
    this.buscar();
  }


  buscar(){
    this.mensagemErro = '';
    this.mensagemSuccesso = '';
    let busca = this.cambioService.buscarPorParametro(this.parametroBusca, this.paginacao.NumeroPagina);
    if (this.parametroId != undefined && this.parametroId != ''){
      this.paginacao.NumeroPagina = 1;
      busca = this.cambioService.buscarPorId(this.parametroId);
    }
    busca.subscribe(
      dados => {

        this.dados = dados.Items;


        this.paginacao.NumeroPagina = dados.MetaData.NumeroPagina;
        this.paginacao.TotalItens = dados.MetaData.TotalItens;
        this.paginacao.ItensPorPagina = dados.MetaData.itensPorPagina;
        this.paginacao.TotalPaginas = dados.MetaData.TotalPaginas;
      },
      erro => {
        this.dados = [];
        console.log('Erro ao Buscar');
        console.log(erro);
        this.mensagemErro = this.errorHandler(erro, 'Buscar câmbios');
      },
    );
  }

  buscarPorParametro(){
    this.paginacao.NumeroPagina = 1;
    this.buscar();
  }


  buscarPagina(pagina){
    this.paginacao.NumeroPagina = pagina;
    this.buscar();
  }

  public errorHandler(erro, acao: string = null): string{

    let mensagem = 'Erro';
    if (acao !== null){
      mensagem += ' ao tentar ' + acao + ': ';
    }
    switch (erro.status){
      case 0: mensagem += 'o servidor não respondeu, tente novamente mais tarde!'; break;
      case 401: mensagem += 'você não tem autorização para executar esta ação!'; break;
      case 404: mensagem += erro.error.mensagem ; break;
      case 406: mensagem += 'a requisição não foi aceita, tente novamente!' ; break;
      case 500: mensagem += 'Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte' ; break;
      default : mensagem += erro.statusText;
    }

    return mensagem;

  }

  log(chave, alteracoes) {

    this.alteracoesCambio = alteracoes;

    this.chaveCambio = chave;


    this.logModalref = ({
      size: 'lg',
      modalClass: '',
      hideCloseButton: false,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: false,
      backdropClass: 'modal-backdrop'
    });

    this.logModalref.onClose.subscribe(() => {
    });

  }
}
