import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { LoginService } from './login.service';


import { Usuario } from '../model/usuario.model';

import { API, ITENSPORPAGINA } from '../app.config';
import { CLIENT_ID } from '../app.config';

// tslint:disable: ban-types
// tslint:disable: max-line-length


@Injectable()
export class UsuarioService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private login: LoginService) { }

  private usuario: Usuario;


  getUserById(id: number): Observable<any> {
    return this.http.get<any>(`${API}/api/usuario/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  }

  buscarPorParametro(param: string, pagina: Number): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');

    if (param.length > 0) {
      return this.http.get<any>(`${API}/api/usuario/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers });
    } else {
      return this.http.get<any>(`${API}/api/usuario?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers });
    }
  }

  buscarPorAgencia(param: string, pagina: Number, agenciaId: any): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');

    return this.http.get<any>(`${API}/api/usuario/poragencia/${agenciaId}/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers });
  }


  buscarPorEmpresa(param: string, pagina: Number, empresaId: any): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');

    return this.http.get<any>(`${API}/api/usuario/porempresa/${empresaId}/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers });
  }

  alterarSenha(id: number, senha: string, senhaAtual: string): Observable<any> {
    return this.http.patch<any>(`${API}/api/usuario/password/`, { Id: id, SenhaAtual: senhaAtual, Senha: senha });
  }

  recuperarSenha(email: string): Observable<any> {
    console.log('service');
    return this.http.patch<any>(`${API}/api/usuario/recuperarSenha/`, { Email: email });
  }

  salvarDados(usuario: Usuario): Observable<Usuario> {
    if (usuario.Id > 0) {
      return this.http.put<Usuario>(`${API}/api/usuario/${usuario.Id}`, usuario);
    } else {
      return this.http.post<any>(`${API}/api/usuario/`, usuario);
    }
  }

  buscarMinhasPermissoes(): Observable<any> {
    return this.http.get<any>(`${API}/api/usuario/permissoes`);
  }

  remover(id: Number): Observable<any> {
    return this.http.delete<any>(`${API}/api/usuario/${id}`);
  }

  buscarPerfis(valor: string, tipo: string): Observable<any> {
    const empresa = tipo === 'E' ? 1 : 0;
    const agencia = tipo === 'A' ? 1 : 0;

    return this.http.get<any>(`${API}/api/perfilusuario/list/${valor}?empresa=${empresa}&agencia=${agencia}`);
  }

  vincularPerfil(usuarioId: number, perfilId: number): Observable<any> {
    return this.http.put<any>(`${API}/api/usuario/${usuarioId}/vincularperfil/${perfilId}`, {});
  }

  desvincularPerfil(usuarioId: number, perfilId: number): Observable<any> {
    return this.http.put<any>(`${API}/api/usuario/${usuarioId}/desvincularperfil/${perfilId}`, {});
  }

  buscarConsultor(agenciaId: number, valor: string): Observable<any> {
    return this.http.get<any>(`${API}/api/usuario/consultor/${agenciaId}/${valor}`);
  }

  buscarPermissoes() {
    this.buscarMinhasPermissoes().subscribe(
      dados => {
        window.localStorage.setItem('permission', btoa(JSON.stringify(dados.filter(x => x.Tipo === 'permissao').map(x => x.Permissao))));

        if (dados.filter(x => x.Tipo === 'permissao').map(x => x.Permissao).length === 0) {
          setTimeout(() => this.buscarPermissoes(), 300);
        }

      }, erros => {
        console.log('Erro ao buscar permissoes');
        console.log(erros);
      },
    );
  }

}
