<div class='row aereo-card'>
  <div class='col' *ngFor="let seguimento of aereo.Seguimentos; let i = index">
    <div class="row">
      <div class="col ida" *ngIf="i % 2 == 0">
        <div class="row jc-sb">
          <div class="col subtitle">
            <h6><i class="fas fa-plane-departure"></i>{{"Ida" | translate}}</h6>
          </div>
        </div>
        <div *ngFor="let voo of seguimento.Voos; let k = index">
          <div class="row jc-se">
            <div class="col as-c">
              <img
                src="assets/img/cias/{{ voo.CiaMarketing }}.png"
                alt="{{ voo.CiaMarketing}}"
                title="{{ voo.CiaMarketing }}"
              />
            </div>
            <div class="col text-end">
              <p *ngIf="k == 0 || (k > 0 && voo.AeroportoPartida == seguimento.Voos[k - 1].AeroportoChegada)">{{ voo.AeroportoPartida }}</p>
              <p *ngIf="k > 0 && voo.AeroportoPartida != seguimento.Voos[k - 1].AeroportoChegada" style="color:red">{{ voo.AeroportoPartida }}*</p>
              <h6>{{ voo.DataPartida | date : 'dd/MM/yyyy HH:mm'}}</h6>
            </div>
            <div class="col text-center">
              <div class="col voo-detalhes">
                <p>{{ voo.Duracao.substring(0, 5) }}</p>
                <app-trecho-simbol></app-trecho-simbol>
              </div>
            </div>
            <div class="col">
              <p>{{ voo.AeroportoChegada }}</p>
              <h6>{{ voo.DataChegada | date: "dd/MM/yyyy HH:mm" }}</h6>
            </div>
          </div>

          <div class="row">
            <div class="col">
              {{"Voo" | translate}} N°{{ voo.NumeroVoo }} | {{"Classe" | translate}}: {{voo.ClasseTarifaria}} | {{"Bagagem" | translate}}:
              {{ seguimento.BagagemQuantidade }}
            </div>
          </div>
        </div>
      </div>
      <div class="col" *ngIf="i % 2 != 0">
        <div class="row jc-sb">
          <div class="col subtitle">
            <h6><i class="fas fa-plane-arrival"></i>{{"Volta" | translate}}</h6>
          </div>
        </div>

        <div *ngFor="let voo of seguimento.Voos; let k = index">
          <div class="row jc-se">
            <div class="col as-c">
              <img
                src="assets/img/cias/{{ voo.CiaMarketing }}.png"
                alt="{{ voo.CiaMarketing }}"
                title="{{ voo.CiaMarketing }}"
              />
            </div>
            <div class="col">
              <p *ngIf="k == 0 || (k > 0 && voo.AeroportoPartida == seguimento.Voos[k - 1].AeroportoChegada)">{{ voo.AeroportoPartida }}</p>
              <p *ngIf="k > 0 && voo.AeroportoPartida != seguimento.Voos[k - 1].AeroportoChegada" style="color:red">{{ voo.AeroportoPartida }}*</p>
              <h6>{{ voo.DataPartida | date : 'dd/MM/yyyy HH:mm'}}</h6>
            </div>
            <div class="col text-center">
              <div class="col voo-detalhes">
                <p>{{ voo.Duracao.substring(0, 5) }}</p>
                <app-trecho-simbol></app-trecho-simbol>
              </div>
            </div>
            <div class="col">
              <p>{{ voo.AeroportoChegada }}</p>
              <h6>{{ voo.DataChegada | date: "dd/MM/yyyy HH:mm" }}</h6>
            </div>
          </div>

          <div class="row">
            <div class="col">
              {{"Voo" | translate}} N°{{ voo.NumeroVoo }} | {{"Classe" | translate}}: {{voo.ClasseTarifaria}} | {{"Bagagem" | translate}}:
              {{ seguimento.BagagemQuantidade }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class='col-2 as-c'>
    <div class='row text-center' *ngIf="user.EmpresaId != (undefined || null)">
      <div class='col mb-3 as-c' >
        <h6>{{"Fornecedor" | translate}}</h6>
        <img src="assets/img/cias/{{ aereo.Fornecedor }}.png" alt="{{ aereo.Fornecedor }}" title="{{ aereo.Fornecedor }}"/>
      </div>

    </div>
    <div class="row text-center">
      <div class="col">
        <p class="subtitle">{{"Total" | translate}}</p>
        <h5> {{aereo.MoedaListagem.Simbolo}} {{aereo.TarifasListagemTotal | currency:'BRL':'':'2.2-2' }}</h5>
        <p>+ {{'Taxas' | translate}}: {{aereo.MoedaListagem.Simbolo}} {{aereo.TaxasListagemTotal |  currency:'BRL':'':'2.2-2'}}</p>
        <p>+ {{'RC' | translate}}: {{aereo.MoedaListagem.Simbolo}} {{aereo.TaxasDUListagemTotal |  currency:'BRL':'':'2.2-2'}}</p>
      </div>
    </div>
  </div>
</div>
