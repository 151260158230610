import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UsuarioService } from 'src/app/services/';
@Component({
  selector: 'app-recuperar-senha',
  templateUrl: './recuperar-senha.component.html',
  styleUrls: ['./recuperar-senha.component.scss']
})
export class RecuperarSenhaComponent implements OnInit {

  @Output()
  recuperou = new EventEmitter<any>();

  email = '';
  senha = '';
  errors: string[];
  success: string;
  form: FormGroup;
  loadingRecuperarSenha = false;
  constructor(
    private usuarioService: UsuarioService) { }

  ngOnInit() { }

  recuperarSenha() {
    delete this.errors;
    delete this.success;
    this.loadingRecuperarSenha = true;
    this.usuarioService.recuperarSenha(this.email).subscribe(
      dados => {
        this.success = 'E-mail com a recuperação da senha enviado!';
        this.loadingRecuperarSenha = false;
      }, erro => {
        console.log('Erro de recuperar');
        console.log(erro.error.mensagem);
        if (erro.status === 400) {
          this.errors = [erro.error.mensagem];
        } else {
          this.errors = ['Houve algum erro, tente novamente'];
        }
        this.loadingRecuperarSenha = false;
      });
  }
}
