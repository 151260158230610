<div class="modal-header">
  <div class='row'>
    <div class='col'>
      <h3>Alterar Senha</h3>
      <i class="fas fa-times" (click)="fecharModal()"></i>
    </div>
  </div>
</div>
<div class="modal-body">
  <div class="container">
    <div class="">
      <form class="form" novalidate (keydown.enter)="$event.preventDefault()" role="form" #form="ngForm">
        <div class="form-group row">
          <div class="col-sm-4">
            <app-input>
              <label>Senha Atual</label>
              <input type="password" class="form-control full-width" [(ngModel)]="senhaAtual" name="senhaAtual" [required]="true">
            </app-input>
          </div>

          <div class="col-sm-4">
            <app-input>
              <label>Nova Senha</label>
              <input [disabled]="loadingAlterarSenha" type="password" class="form-control full-width" [(ngModel)]="senha" name="senha" [required]="true">
            </app-input>
          </div>

          <div class="col-sm-4">
            <app-input>
              <label>Confirme</label>
              <input [disabled]="loadingAlterarSenha" type="password" class="form-control full-width" [(ngModel)]="resenha" name="resenha" [required]="true">
            </app-input>
          </div>
        </div>

        <div class="form-group row" style="margin-top: 15px;">
          <div class="col-sm-12" style="text-align: end;">
            <button [disabled]="!form.valid || this.alterada" [hidden]="loadingAlterarSenha || loadingAlterarDados" type="submit" class="btn btn-primary  btn-block" (click)="alterarSenha()" style="margin-right: 10px;">Alterar</button>
            <button [disabled]="alteracaoObrigatoria" type="button" class="btn btn-primary btn-block" (click)="fecharModal()">Sair</button>
          </div>

          <span *ngIf="alteracaoObrigatoria" class="font-weight-light font-italic">Alteração de senha obrigatória</span>

        </div>
      </form>
      <div [hidden]="!sucessoAlterarSenha" class="alert-success">Senha Alterada com sucesso!</div>
      <div class="alert-danger" *ngIf="erroAlterarSenha">{{erroAlterarSenha}}</div>
    </div>
  </div>
</div>
