import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Reserva, ReservaPagamento } from '../../model/';
import { API, ITENSORCAMENTO } from '../../app.config';

@Injectable()
export class ReservaService {
  constructor(private http: HttpClient) { }

  gerarLinkPagamento(pagamentoId: number): Observable<any> {
    return this.http.get<any>(`${API}/api/reserva/criar/link-cartaopagamento/${pagamentoId}`);
  }

  linkPagamento(pagamentos: any, reservaId: number): Observable<any> {
    return this.http.post<any>(`${API}/api/contrato/linkparapagamento/${reservaId}`, pagamentos);
  }

  adicionarItemOrcamento(reserva: Reserva, id: number): Observable<any> {
    return this.http.patch<any>(`${API}/api/Reserva/${id}`, reserva);
  }

  salvarOrcamento(reserva: Reserva): Observable<any> {
    return this.http.post<any>(`${API}/api/Reserva/`, reserva);
  }

  buscarMeusOrcamentos(filtro: any, pagina: number): Observable<any> {
    return this.http.post<any>(`${API}/api/reservas-minhas?Pagina=${pagina}&ItensPorPagina=${ITENSORCAMENTO}`, filtro);
  }

  buscarOrcamento(id: number): Observable<any> {
    return this.http.get<any>(`${API}/api/Reserva/${id}`);
  }

  buscarListaAgentes(AgenciaId: any, Valor: any): Observable<any> {
    return this.http.get<any>(`${API}/api/usuario/Agente/${AgenciaId}/${Valor}`);
  }

  AlterarAgenteReserva(ReservaId: any, AgenciaId: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json').set('noloader', 't');
    return this.http.put<any>(`${API}/api/reserva/agente/${ReservaId}/${AgenciaId}`, {headers});
  }

  buscarPorParametroPost(valor, pagina: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json').set('noloader', 't');
    return this.http.post<any>(`${API}/api/usuario/listpost?Pagina=1&ItensPorPagina=${ITENSORCAMENTO}`, valor , {headers});
  }

  buscaReservaBusca(id: number): Observable<any> {
    return this.http.get<any>(`${API}/api/reservas/pesquisa/${id}`);
  }

  salvarDadosPessoas(pessoas: any): Observable<any> {
    const id = pessoas[0].ReservaId;
    return this.http.post<any>(`${API}/api/reservas/paxes/${id}`, pessoas);
  }

  buscarConsultor(consultor): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    return this.http.get<any>(`${API}/api/usuario/consultor/${consultor}`, { headers });
  }

  alterarConsultor(reservaId, consultorId): Observable<any> {
    return this.http.put<any>(`${API}/api/reserva/consultor/${reservaId}/${consultorId}`, {});
  }

  buscarPagamentos(id): Observable<any> {
    return this.http.get<any>(`${API}/api/reservas/pagamentos/${id}`);
  }

  verificarPodePagar(reservaId): Observable<any> {
    return this.http.get<any>(`${API}/api/reserva/verificar-pagar/${reservaId}`);
  }

  enviarPagamentos(pagamentos, reservaId): Observable<any> {
    return this.http.post<any>(`${API}/api/reservas/pagamentos/${reservaId}`, pagamentos);
  }

  estornarPagamento(pagamentoId): Observable<any> {
    return this.http.delete<any>(`${API}/api/reservas/pagamentos/${pagamentoId}`);
  }

  consultarPagamento(pagamentoId): Observable<any> {
    return this.http.get<any>(`${API}/api/reservas/pagamentos/consultar/${pagamentoId}`);
  }

  urlOrcamento(reservaID, simplificado): string {
    return `${API}/api/orcamento/${simplificado}/${reservaID}`;
    // return this.http.get<any>(`${TURISMO}/api/orcamento//${reservaID}`);
  }

  exibirOrcamento(reservaID, simplificado): Observable<any> {
    return this.http.get<any>(`${API}/api/orcamento/${simplificado}/${reservaID}`, { responseType: 'blob' as 'json' })
      .pipe(map(res => {
        return new Blob([res], { type: 'application/pdf', });
      }));
  }

  enviarOrcamento(id, simplificado, email): Observable<any> {
    return this.http.patch<any>(`${API}/api/OrcamentoEnvio/${id}/${simplificado}?html=1`, { Email: email });
  }

  alterarReservaPagamento(reservapagamento: ReservaPagamento): Observable<any> {
    return this.http.put<any>(`${API}/api/reservas/pagamentos/alterar/${reservapagamento.Id}`, reservapagamento);
  }

  LiberarVoucher(id: number): Observable<any> {
    return this.http.put<any>(`${API}/api/reserva/${id}/liberarvoucher`, {});
  }

}
