<div class="results">
    <div *ngIf="aba() == 'pacotes'">
        <app-pacotes [events]="aba() == 'pacotes' ? eventsSubject.asObservable() : null" (avancar)="rotaAvancar()" [busca]="busca" [Orcamento]="Orcamento.PacoteReservas" (removeu)="atualizarOrcamento($event, false)" (selecionou)="atualizarOrcamento($event, true)"></app-pacotes>
    </div>
    <div *ngIf="aba() == 'pacote-hotel'">
        <app-pacote-hotel [events]="aba() == 'pacote-hotel' ? eventsSubject.asObservable() : null" (avancar)="rotaAvancar()" [busca]="busca" [Orcamento]="Orcamento.PacoteReservas" (removeu)="atualizarOrcamento($event, false)" (selecionou)="atualizarOrcamento($event, true)"></app-pacote-hotel>
    </div>
    <div *ngIf="aba() == 'pacote-servico'">
        <app-pacote-servico [events]="aba() == 'pacote-servico' ? eventsSubject.asObservable() : null" (avancar)="rotaAvancar()" [busca]="busca" [Orcamento]="Orcamento.PacoteReservas" (removeu)="atualizarOrcamento($event, false)" (selecionou)="atualizarOrcamento($event, true)"></app-pacote-servico>
    </div>
    <div *ngIf="aba() == 'pre-orcamento'">
        <app-revisa-orcamento [busca]="busca" [Orcamento]="Orcamento" [Pacote]="true"></app-revisa-orcamento>
    </div>
</div>