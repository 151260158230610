<div class="meus-orcamentos">
  <div class="container">
    <div class='row'>
      <div class='col text-center mobile-h5'>
        <h5 class="title">{{'Meus Orçamentos' | translate}} </h5>
      </div>
    </div>
    <div class="content">
      <div class="leftDiv">
        <h5 class="h5-mobile">{{"Buscar Orçamentos" | translate}}</h5>
        <div class="row">
          <div class='col'>
            <span class="input-group-text" id="inputGroup-sizing-default">{{'Nome' | translate}} PAX</span>
            <input [(ngModel)]="filtro.pax" type="text" placeholder="Nome do Agente" class="form-control" aria-label="Default"
              aria-describedby="inputGroup-sizing-default">
          </div>
        </div>
        <div class="row">
          <div class="mobile-div">
          <div class="col mobile-left">
            <span class="input-group-text" id="inputGroup-sizing-default">{{"De" | translate}}</span>
            <input [(ngModel)]="filtro.Data1" type="date" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default">
          </div>
          <div class="col mobile-right">
            <span class="input-group-text" id="inputGroup-sizing-default">{{"Até" | translate}}</span>
            <input [(ngModel)]="filtro.Data2" type="date" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default">
          </div>
        </div>
        </div>
        <div class="mobile-div">
        <div class="row mobile-left mobile-input">
          <span class="input-group-text" id="inputGroup-sizing-default">{{"Número do Orçamento" | translate}}</span>
          <input [(ngModel)]="filtro.id" type="number" min="0" placeholder="Número do Orçamento" class="form-control" aria-label="Default"
            aria-describedby="inputGroup-sizing-default">
        </div>
        <div class="row mobile-right">
          <span class="input-group-text mobile-span" id="inputGroup-sizing-default">{{"Localizador" | translate }}</span>
          <input [(ngModel)]="filtro.localizador" type="text" min="0" placeholder="Localizador" class="form-control" aria-label="Default"
            aria-describedby="inputGroup-sizing-default">
        </div>
      </div>
        <div class="row">
          <span class="input-group-text" id="inputGroup-sizing-default">{{'Status da Reserva' | translate}}</span>
          <select [(ngModel)]="filtro.situacao" class="custom-select form-select" id="inputGroupSelect01">
            <option value="0">{{"Pendente" | translate}}</option>
            <option value="1">{{"Pré-Pagamento" | translate }}</option>
            <option value="6">{{"Pago" | translate}}</option>
            <option value="">{{"Todos" | translate}}</option>
          </select>

        </div>

        <div style="display:none;" class="row">
          <span class="input-group-text" id="inputGroup-sizing-default">{{'Agência' | translate}}</span>
          <input type="text" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default">
        </div>
        <div class="row">
          <button (click)="buscarMeusOrcamentos()" type="button" class="btn btn-success">{{'Filtrar' | translate}}</button>
        </div>
      </div>
      <div class="rightDiv">
        <app-view-loading *ngIf="carregando"></app-view-loading>
        <div *ngIf="!carregando">
          <div class="table-responsive">
            <table class="table table-hover" >
              <thead>
                <tr>
                  <th scope="col" class="numero">{{'Número' | translate}}</th>
                  <th scope="col" class="pax">Pax</th>
                  <th scope="col" class="data">{{'Data' | translate}}</th>
                  <th scope="col" class="agente">{{'Agente' | translate}}</th>
                  <th scope="col" class="agencia">{{'Agência' | translate}}</th>
                  <th scope="col" class="itens">{{'Itens' | translate}}</th>
                  <th scope="col" class="status">{{"Status" | translate}}</th>
                  <!-- <th scope="col" class="localizador">Localizador</th> -->
                </tr>
              </thead>
              <tbody *ngFor="let reserva of orcamentos; let a = index" >
                <tr>
                  <th scope="row" class="numero" (click)="acessarOrcamento($event, reserva)"> {{reserva.Id}}
                  </th>
                  <td class="pax"> {{reserva.Pax}}</td>
                  <td class="data">{{reserva.Alteracoes.CriadoEm | date: 'dd/MM/yyyy HH:mm' }}</td>
                  <td class="agente">{{reserva.Agente?.Nome}}</td>
                  <td class="agencia">{{reserva.Agencia?.NomeFantasia}}</td>
                  <td class="itens">
                    <span *ngIf="reserva.Hoteis.length > 0">
                      <i *ngIf="reserva.Hoteis[0].PacoteId != (null||undefined)" class="fa fa-star" title="Pacote"></i>
                    </span>
                    <i *ngIf="reserva.Aereos.length > 0" class="fa fa-plane fa-rotate-270" title="Aéreo"></i>
                    <i *ngIf="reserva.Hoteis.length > 0" class="fa fa-hotel" title="Hotel"></i>
                    <i *ngIf="reserva.Servicos.length > 0" class="fa fa-bus " title="Tranfer"></i>
                    <i *ngIf="reserva.Tickets.length > 0" class="fa fa-bus " title="Tranfer"></i>
                    <i *ngIf="reserva.Tours.length > 0" class="fa fa-ticket" title="Ticket"></i>
                    <i *ngIf="reserva.Carros.length > 0" class="fa fa-car " title="Carro"></i>
                    <i *ngIf="reserva.Seguros.length > 0" class="fa fa-shield-alt" title="Seguro"></i>
                    <!-- <i *ngIf="reserva.Pacote.length > 0" class="fa fa-star" title="Pacote"></i> -->
                  </td>
                  <td [ngSwitch]="reserva.Status">
                    <span *ngSwitchCase="0">{{'Pendente' | translate}}</span>
                    <span *ngSwitchCase="1">{{'Aguardando' | translate}}</span>
                    <span *ngSwitchCase="2">{{'Reserva Parcial' | translate}}</span>
                    <span *ngSwitchCase="3">{{'Reservado' | translate}}</span>
                    <span *ngSwitchCase="4">{{'Solicitado' | translate}}</span>
                    <span *ngSwitchCase="5">{{'Emitido' | translate}}</span>
                    <span *ngSwitchCase="6">{{'Pago' | translate}}</span>
                    <span *ngSwitchCase="7">{{'Problema Técnico' | translate}}</span>
                    <span *ngSwitchCase="8">{{'Cancelado' | translate}}</span>
                    <span *ngSwitchCase="9">{{'Excluído' | translate}}</span>
                    <span *ngSwitchCase="10">{{'Cancelamento com Problema' | translate}}</span>
                  </td>
                  <!-- <td class="localizador">{{reserva.Localizador}}</td> -->
                </tr>

              </tbody>
            </table>
            <app-paginacao [paginacao]="paginacao" (alterarPagina)="buscarPagina($event)"></app-paginacao>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
