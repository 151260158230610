import { Component, OnInit } from '@angular/core';
import { FAVICONLIGHT } from 'src/app/app.config';

@Component({
  selector: 'app-view-loading',
  templateUrl: './view-loading.component.html',
  styleUrls: ['./view-loading.component.scss']
})
export class ViewLoadingComponent implements OnInit {
 faviconlight = FAVICONLIGHT;
  constructor() { }

  ngOnInit(): void {
  }

}
