import { AfterViewInit, Component, OnInit, Output, Input, ElementRef, EventEmitter, ViewChild} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Usuario } from 'src/app/model';
import { LoginService, UsuarioService, CidadeService  } from 'src/app/services';
import { FormGroup, Validators, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-alterarsenha',
  templateUrl: './alterarsenha.component.html',
  styleUrls: ['./alterarsenha.component.scss']
})
export class AlterarsenhaComponent implements OnInit {
  @Input()
  alteracaoObrigatoria: Boolean = true;

  @Output()
  alterou = new EventEmitter<any>();

  senhaAtual: string;
  senha: string;
  resenha: string;
  alterada = false;
  loadingAlterarSenha = false;
  sucessoAlterarSenha = false;
  loadingAlterarDados = false;
  sucessoAlterarDados = false;
  erroAlterarSenha = '';
  erroAlterarDados = '';
  // public MASKS = MASKS;
  data: [];

  usuario: any;
  form: FormGroup;
  mensagensErro = [];

  // Api = API;

  constructor(private loginService: LoginService,
    private usuarioService: UsuarioService,
    private cidadeService: CidadeService,
    private modalService: NgbModal) {
    this.usuario = this.loginService.user();

    this.usuario.DataNascimento = this.usuario.DataNascimento != null ? moment(this.usuario.DataNascimento) : this.usuario.DataNascimento;
  }

  ngOnInit() {
  }

  alterarSenha(){
    this.erroAlterarSenha = '';
    this.sucessoAlterarSenha = false;
    this.loadingAlterarSenha = true;

    if (this.senha != this.resenha)
    {
      this.erroAlterarSenha = 'As senhas digitadas não conferem';
      this.loadingAlterarSenha = false;
    }
    else
    {
      this.usuarioService.alterarSenha(this.usuario.Id, this.senha, this.senhaAtual).subscribe(
        dados => {

          this.sucessoAlterarSenha = true;
          this.loadingAlterarSenha = false;
          this.alteracaoObrigatoria = false;
          // this.senha = '';
          // this.resenha = '';
          // this.senhaAtual = '';

          this.alterada = true;
          // this.form.markAsPristine();

          if (this.usuario.AtualizarDados){
            this.salvar();
          }
        },
        erro => {
          this.loadingAlterarSenha = false;
          console.log(erro);
          if (erro.status == 400){
            this.erroAlterarSenha = erro.error.mensagem;
          }else if (erro.status = 406){
            this.erroAlterarSenha = erro.error.mensagens[0];
          }
          else{
            this.erroAlterarSenha = 'Erro ao alterar a senha, tente novamente mais tarde!';
          }
          console.log(erro);
        });
      }
    }
    salvar() {
      this.erroAlterarDados = '';
      this.sucessoAlterarDados = false;
      this.loadingAlterarDados = true;

      this.usuario.TrocarSenha = false;
      this.usuario.AtualizarDados = false;

       this.usuarioService.salvarDados(this.usuario).subscribe(
         (dados) => {

           this.sucessoAlterarDados = true;
           this.loadingAlterarDados = false;

         },
         (erros) => {
           this.loadingAlterarDados = false;
           this.usuario.TrocarSenha = true;
           this.usuario.AtualizarDados = true;

           if (erros.status == 400){
             this.erroAlterarDados = erros.error.mensagem;
           }else if (erros.status = 406){
             this.erroAlterarDados = erros.error.mensagens[0];
           }
           else{
             this.erroAlterarDados = 'Erro ao alterar a senha, tente novamente mais tarde!';
           }
           console.log("Erro ao Salvar");
           console.log(erros);
         }
       );
     }


     selecionaCidade(item) {
       this.usuario.Cidade = item;
     }

     onChangeCidade(val: string) {
       this.cidadeService.buscarPorParametro(val, 1).subscribe(
         (dados) => {
           this.data = dados.Items.map(function (x) {
             x.display_field =
               x.Nome + " / " + x.Estado.Nome + " - " + x.Estado.Pais.Nome;
             return x;
           });
         },
         (erro) => {
           console.log(erro);
           this.data = [];
         }
       );
     }
     fecharModal() {
       if(this.alterada){
         this.alterou.emit(true);
       }
           this.modalService.dismissAll();
     }
}
