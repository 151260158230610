<div class="container">
  <div class="input-group" *ngFor="let trecho of busca.trechos; let i = index">
    <div class="row">
      <div class="col-3" *ngIf="tipoBusca == 'aereo'">
        <div class="buscaField">
          <label>{{ "Selecione a origem" | translate }}</label>
          <app-typeahead
            name="cidadeorigem"
            [lista]="cidades"
            [searchText]="trecho.AeroportoPartida.display_field"
            (searchFunction)="buscaDados($event)"
            (selectItem)="selecionaCidadeOrigem($event, trecho)"
          >
            <input
              class="theme-search-area-section-input"
              type="text"
              placeholder="Origem"
              required
              [(ngModel)]="trecho.AeroportoPartida.display_field"
              (ngModelChange)="buscaCidadeDebounce($event)"
            />
          </app-typeahead>
        </div>
      </div>
      <div [ngClass]="{ 'col-4': tipoBusca != 'aereo', 'col-3': tipoBusca == 'aereo' }">
        <div class="buscaField">
          <label>{{ "Selecione o destino" | translate }}</label>
          <div>
            <div *ngIf="tipoBusca == 'aereo'">
              <app-typeahead
                name="cidadedestino"
                [lista]="cidades"
                [searchText]="trecho.AeroportoChegada.display_field"
                (searchFunction)="buscaDados($event)"
                (selectItem)="selecionaCidadeDestino($event, trecho)"
              >
                <input
                  class="form-control"
                  type="text"
                  placeholder="Destino"
                  required
                  [(ngModel)]="trecho.AeroportoChegada.display_field"
                  (ngModelChange)="buscaCidadeDebounce($event)"
                  autocomplete="off"
                />
              </app-typeahead>
            </div>
            <div *ngIf="tipoBusca != 'aereo'">
              <app-typeahead
                name="cidadedestino"
                [lista]="cidades"
                [searchText]="trecho.cidadeDestino.display_field"
                (searchFunction)="buscaCidade($event)"
                (selectItem)="selecionaCidadeDestino($event, trecho)"
              >
                <input
                  class="form-control"
                  name="cidadeDestino"
                  placeholder="{{ 'Cidade' | translate }}"
                  (ngModelChange)="buscaCidadeDebounce($event)"
                  [(ngModel)]="trecho.cidadeDestino.display_field"
                  autocomplete="off"
                />
              </app-typeahead>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="row"> -->
        <div class="col-2">
          <span class="input-group-text" id="inputGroup-sizing-default">{{aereo ? "Data de Ida" : ("Data Check-in" | translate)}}</span>
          <input
            type="date"
            class="form-control"
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            [ngModel]="trecho.dataIda | date:'yyyy-MM-dd'"
            (ngModelChange)="trecho.dataIda = $event"
            [min]="dataAtual"
            onKeyDown="return false"
          />
        </div>

        <!-- <div class="col-3" id="alinha" *ngIf="aereoTrecho">
          <label class="btn-block transform" *ngIf="i >= qntTrechos && qntTrechos < 6">
            <input type="checkbox" class="checked-btn" (click)="adicionarTrecho()"/><span><i class="fas fa-plus-circle"></i> Adicionar</span>
          </label>
          <label class="btn-block transform" *ngIf="i < qntTrechos">
            <input
              type="checkbox"
              class="checked-btn"
              (click)="removerTrecho(i)"
            /><span><i class="fas fa-minus-circle"></i>Remover</span>
          </label>
        </div> -->

        <div class="col-2" [ngClass]="{ disable: aereoIda, invisible: aereoTrecho }" *ngIf="_router.url != '/listas/aereo'|| busca.tipoBuscaAereo != 'ida'">
          <span class="input-group-text" id="inputGroup-sizing-default">{{aereo ? "Data de Volta" : ("Data Check-out" | translate)}}</span>
          <input type="date" #dataVolta name="dataVolta" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" onKeyDown="return false" [ngModel]="trecho.dataVolta | date:'yyyy-MM-dd'" (ngModelChange)="trecho.dataVolta = $event" [min]="dataAtual && trecho.dataIda" />
        </div>

        <button type="submit" (click)="buscou()" class="btn btn-search col-1 mt-4">
          <i class="fas fa-arrow-right"></i>
        </button>
      <!-- </div> -->
    </div>
  </div>
</div>
