<ul class="pagination">
  <li class="page-item botao-paginacao" [class.disabled]="!paginacao.hasPrevious()">
    <a class="page-link" (click)="paginar(paginacao.previous())">
      {{"Anterior" | translate}}
    </a> 
  </li>
  <li *ngFor="let pagina of paginacao.getPaginacao()" class="page-item" [class.active]="paginacao.NumeroPagina == pagina" >
    <a class="page-link" (click)="paginar(pagina)">
      {{pagina}}
    </a>
  </li>
  <li class="page-item botao-paginacao" [class.disabled]="!paginacao.hasNext()">
    <a class="page-link" (click)="paginar(paginacao.next())">
      {{"Próximo" | translate}}
    </a>
  </li>
</ul>
