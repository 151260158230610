import { __read, __spreadArrays } from 'tslib';
// tslint:disable: variable-name
// tslint:disable: only-arrow-functions
// tslint:disable: max-line-length
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Busca } from 'src/app/model';
import { ReservaService } from 'src/app/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SERVICOS_OBRIGATORIOS } from 'src/app/app.config';
@Component({
  selector: 'app-revisa-orcamento',
  templateUrl: './revisa-orcamento.component.html',
  styleUrls: ['./revisa-orcamento.component.scss'],
})
export class RevisaOrcamentoComponent implements OnInit {

  @Input() busca: Busca;
  @Input() Orcamento: any;
  @Input() Pacote: Boolean = false;
  progress: any;
  erros = [];
  clicked = false;
  orcamentoId: any;

  constructor(
    private reservaService: ReservaService,
    private _router: Router,
    private modalService: NgbModal
  ) {

  }

  servicosObrigatoriosPacote: boolean = SERVICOS_OBRIGATORIOS;

  ngOnInit() {

    const busca = JSON.parse(sessionStorage.getItem('buscapacote'));
    if (this.busca.orcamentoId != undefined) {
      this.orcamentoId = busca.orcamentoId;
    }
    this.busca.quartos = busca.quartos;
   console.log(this.Orcamento);
  }

  excluirRegistro(index, objeto, aereo) {

    if (this.Pacote == false && this.Orcamento.Aereos.length > 0 && !this.busca.AereoConsolidacao && ((!aereo && this.Orcamento.Hoteis.length === 1 && this.Orcamento.Servicos.length === 0) ||
      (!aereo && this.Orcamento.Hoteis.length === 0 && this.Orcamento.Servicos.length === 1) ||
      (!aereo && this.Orcamento.Hoteis.length === 0 && this.Orcamento.Servicos.length === 0))) {
      alert('É necessário manter pelo menos um item além do aéreo!');
    } else {
      objeto = objeto.splice(index, 1);
    }
    window.sessionStorage.setItem(
      'orcamentousuario',
      JSON.stringify(this.Orcamento)
    );

    if (this.Pacote == false && this.Orcamento.Hoteis.length === 0 && this.Orcamento.Servicos.length === 0 && this.Orcamento.Aereos.length === 0) {
      alert('Pré orçamento cancelado!');
      sessionStorage.clear();
      this._router.navigate(['/']);
    }
  }

  public confirmarDelete(index, objeto, aereo = false) {

    const resposta = window.confirm(`Tem certeza que deseja excluir o registro ?`);

    if (resposta) {
      this.excluirRegistro(index, objeto, aereo);
    } else {
      // some code
    }
  }

  somaTotal(arr) {
    return (
      arr
        .map((x) => x.ValorListagem)
        .reduce(function (a, b) {
          return a + b;
        }, 0)
    );
  }

  salvarOrcamento() {
    this.erros = [];
    if (!this.busca.novoOrcamento) {
      if (this.Orcamento.Hoteis && this.Orcamento.Hoteis.length > 0) {
        if (this.Orcamento.Hoteis.filter(x => x.Opcao <= 0).length > 0) {
          this.erros.push('Selecione a opção dos hotéis para prosseguir');
          console.log(this.erros);
          this.clicked = false;
          return false;
        }
      }
    }

    this.Orcamento.quartos = this.busca.quartos;


    // if (this.busca.usuario.AgenciaId > 0) {
    //   this.Orcamento.AgenciaId = this.busca.usuario.AgenciaId;
    // }
    // else {
    //   const buscaConst = JSON.parse(atob(localStorage.getItem('g8h7d8')))
    //   this.Orcamento.quartos = this.busca.quartos;
    //   this.Orcamento.AgenciaId = buscaConst.Id;
    // }

    if (this.Orcamento.PacoteReservas.length > 0) {
      let busca = JSON.parse(sessionStorage.getItem('buscapacote'));
      if (busca.AgenciaId != undefined) {
        this.Orcamento.AgenciaId = busca.AgenciaId;
      }
    }
    else {
      this.Orcamento.AgenciaId = this.busca.AgenciaId;
    }



    if (this.busca.novoOrcamento) {
      this.reservaService.salvarOrcamento(this.Orcamento).subscribe(
        dados => {
          window.sessionStorage.removeItem('orcamentousuario');
          this._router.navigate([`/orcamento/${dados.Id}`]);
        },
        erros => {
          console.log(erros);

          this.erros = this.errorHandler(erros);
        }
      );
    } else {
      this.clicked = false;
      this.reservaService
        .adicionarItemOrcamento(this.Orcamento, this.busca.orcamentoId)
        .subscribe(
          dados => {
            window.sessionStorage.removeItem('orcamentousuario');
            this._router.navigate([
              `/orcamento/${this.busca.orcamentoId}`
            ]);
          },
          erros => {
            console.log(erros);

            this.erros = this.errorHandler(erros);
          }
        );
    }
  }


  public errorHandler(erro): string[] {
    const mensagem = [];
    switch (erro.status) {
      case 0:
        mensagem.push('o servidor não respondeu, tente novamente mais tarde!');
        break;
      case 400:
        mensagem.push('Houve algum erro na execução, tente novamente!');
        break;
      case 401:
        mensagem.push('você não tem autorização para executar esta ação!');
        break;
      case 404:
        mensagem.push(erro.error.mensagem);
        break;
      case 406:
        console.log('a requisição não foi aceita!');
        break;
      case 500:
        mensagem.push(
          'Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte'
        );
        break;
      default:
        mensagem.push(erro.statusText);
    }
    if (erro.error != null && erro.error.mensagens != null) {
    }
    return mensagem;
  }

  adicionarItem(item) {
    this._router.navigate([`listas/${item}`]);
  }

  adicionarPacote() {
    this._router.navigate([`lista-pacotes/pacotes`]);
  }

  objModal: any;
  abrirModal(content, item, index){
    this.objModal = item;
    this.modalService.open(content, {centered: true, size: 'lg'});
  }

}
