import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from './loader.service';



@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private loaderService: LoaderService) { }


  intercept(request: any, next: HttpHandler): Observable<HttpEvent<any>> {
    // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const loader = request.headers.keys();
    const x = !(loader.indexOf('noloader') !== -1);
    if (x) {
      this.loaderService.show();

      return next.handle(request).pipe(
        finalize(() => this.loaderService.hide()),
      );

    } else {

      request.headers = request.headers.delete('noloader');
    }
    return next.handle(request);

  }

}
