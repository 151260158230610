<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Pagamento de Reserva </h4>
</div>
<div class="modal-body">
  <app-loading-small *ngIf="carregandoInfo && podePagar"></app-loading-small>

  <div class="card" *ngIf="podePagar">
    <div class="card-body">
      <div id="accordion">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button class="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                {{"Valores da Reserva" | translate}}
              </button>
            </h5>
          </div>
          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
            <div class="card-body">
              <table class="table table-hover table-striped">
                <thead>
                  <tr>
                    <th>{{"Total" | translate}}</th>
                    <th>{{"Valor Pago" | translate}}</th>
                    <th>{{"Saldo Pendente" | translate}}</th>
                    <th>{{"Financiamentos Disponíveis" | translate}}</th>
                    <th>{{"Comissões Disponíveis" | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{reserva.Agencia.Empresa.MoedaPagamento.Sigla}} {{reserva.ValorTotal | currency:'BRL' }}</td>
                    <td>{{reserva.Agencia.Empresa.MoedaPagamento.Sigla}}  {{valorPago | currency:'BRL'}}</td>
                    <td>{{reserva.Agencia.Empresa.MoedaPagamento.Sigla}}  {{saldo | currency:'BRL'}}</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card">
            <div class="card-header" id="headingTwo">
              <h5 class="mb-0">
                <button class="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  {{"Pagamentos Realizados" | translate}}
                </button>
              </h5>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
              <div class="card-body">
                <table class="table table-hover table-striped">
                  <thead>
                    <tr>
                      <th>{{"Código" | translate}}</th>
                      <th>{{"Forma" | translate}}</th>
                      <th>{{"Data" | translate}}</th>
                      <th>{{"Valor" | translate}}</th>
                      <th>{{"Status" | translate}}</th>
                      <th>{{"Ações" | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let pgto of pagamentosRealizados;let i = index">
                      <td>{{ pgto.Id}}</td>
                      <td>{{ pgto.CondicaoPagamento.FormaPagamento.Descricao}} - {{ pgto.CondicaoPagamento.Descricao}}</td>
                      <td>{{ pgto.Alteracoes.CriadoEm | date: 'dd/MM/yyyy'}}</td>
                      <td>{{reserva.Agencia.Empresa.MoedaPagamento.Sigla}} {{ pgto.Valor | currency:'BRL':'':'1.2-2'}}</td>
                      <td [ngSwitch]="pgto.Status">
                        <span *ngSwitchCase="0" class="badge badge-pill">{{"Pendente" | translate}}</span>
                        <span *ngSwitchCase="1" class="badge badge-pill">{{"Enviado" | translate}}</span>
                        <span *ngSwitchCase="2" class="badge badge-pill">{{"Confirmado" | translate}}</span>
                        <span *ngSwitchCase="3" class="badge badge-pill">{{"Recusado" | translate}}</span>
                        <span *ngSwitchCase="4" class="badge badge-pill">{{"Aprovado" | translate}}</span>
                        <span *ngSwitchCase="5" class="badge badge-pill">{{"Cancelado" | translate}}</span>
                        <span *ngSwitchCase="6" class="badge badge-pill">{{"Estornado" | translate}}</span>
                        <span *ngSwitchCase="7" class="badge badge-pill">{{"Excluído" | translate}}</span>
                      </td>
                      <td [ngSwitch]="pgto.Status">
                        <div *ngSwitchCase="4">
                            <button *ngIf="pgto.TipoPagamento == 2" class="btn btn-danger btn-sm" (click)="estornar(pgto,i)" title="Estornar">Estornar </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3">{{"Total Pago" | translate}}</td>
                      <td>{{reserva.Agencia.Empresa.MoedaPagamento.Sigla}}  {{valorPago | currency:'BRL':'':'1.2-2'}}</td>
                      <td colspan="2"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        <div class="card">
          <!-- <div class="card-header" id="headingThree">
            <h5 class="mb-0">
              <button class="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                Adicionar Pagamentos
              </button>
            </h5>
          </div> -->
          <div id="collapseThree" class="collapse show" aria-labelledby="headingThree" data-parent="#accordion">
            <div class="card-body">
              <div class="row">
                <div class="col2">
                  <h5>Saldo</h5>
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>{{"Valor Total da Reserva" | translate}}</th>
                        <th>{{"Valor Pago" | translate}}</th>
                        <th>{{"Pagamentos Informados" | translate}}</th>
                        <th>{{"Saldo" | translate}}</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{{reserva.Agencia.Empresa.MoedaPagamento.Sigla}}  {{reserva.ValorTotal | currency:'BRl':'':'1.2-2'}}</td>
                        <td>{{reserva.Agencia.Empresa.MoedaPagamento.Sigla}}  {{valorPago | currency:'BRL':'':'1.2-2'}}</td>
                        <td>{{reserva.Agencia.Empresa.MoedaPagamento.Sigla}}  {{somaPagamentosInformados() | currency:'BRL':'':'1.2-2'}}</td>
                        <td>{{reserva.Agencia.Empresa.MoedaPagamento.Sigla}}  {{calcularSaldoGeral() | currency:'BRL':'':'1.2-2'}}</td>
                      </tr>
                    </tbody>
                  </table>

                  <button [hidden]="adicionandoPagamento" [disabled]="!formasPagamento || !podeAdicionarPagamento || calcularSaldoGeral() <= 0" class="btn btn-success" (click)="novoPagamento()">+ Pagamento</button>
                </div>
              </div>

              <form [hidden]="!adicionandoPagamento" role="form" #formPagamentos="ngForm" novalidate (keydown.enter)="$event.preventDefault()">
                <div *ngFor="let pagamento of pagamentos; let i = index">
                  <div class='row header_Pagamento'>
                    <div class='col formaPagamento'>
                      <label class="_label _pl-0">Forma de Pagamento</label>
                      <app-input requiredMessage="Selecione uma Opção">
                        <select [required]="true" class="form-select" [(ngModel)]="pagamento.formaSelecionada" (change)="selecionouFormaPagamento(pagamento)" name="pgto_forma_selecionada{{pagamento.Guid}}">
                          <option *ngFor="let f of formasPagamento" [ngValue]="f">{{f.FormaPagamento.Descricao}}</option>
                        </select>
                      </app-input>
                    </div>
                    <div class='col valor'>
                      <label class="_label _pl-0">Valor</label>
                      <app-input>
                        <input [required]="true" class="form-control" [(ngModel)]="pagamento.Valor" name="pgto_valor{{pagamento.Guid}}" />
                      </app-input>
                    </div>
                    <!-- <div class='col paxPagante'>
                      <label class="_label _pl-0">Pax Pagante</label>
                      <app-input requiredMessage="Selecione uma Opção">
                        <select [required]="true" class="form-select" [(ngModel)]="pagamento.PaxPaganteId" name="pgto_pax{{pagamento.Guid}}">
                          <option *ngFor="let pagante of pessoasPagantes()" [ngValue]="pagante.Id">{{pagante.Nome}} {{pagante.Sobrenome}}</option>
                        </select>
                      </app-input>
                    </div> -->
                    <!-- <div class='col-1 deleta'>
                      <span *ngIf="pagamento.Processado" class="fa fa-check"></span>
                      <button *ngIf="i+1 == pagamentos.length || pagamento.Processado" class="btn-delete" (click)="removerPagamento(i)"><span class="fa fa-trash"></span></button>
                    </div> -->
                  </div>

                  <div class='row mt-3'>
                    <div class='col faturado' *ngIf="pagamento.formaSelecionada?.FormaPagamento.TipoPagamento == 1">
                      <div class="alert alert-dark" role="alert">
                        {{"Condição: Condicionado à Saldo" | translate}}
                      </div>
                    </div>

                    <div class='col financiamento' *ngIf="pagamento.formaSelecionada?.FormaPagamento.TipoPagamento == 4">
                      <div class='row'>
                        <div class='col'>
                          <label>{{"Financiamento" | translate}}</label>
                          <select required class="form-select" [(ngModel)]="pagamento.CondicaoPagamento" name="pagamento_CondicaoPagamento{{pagamento.Guid}}">
                            <option *ngFor="let c of pagamento.formaSelecionada.FormaPagamento.Condicoes" [ngValue]="c">{{c.Parcelas}} x</option>
                          </select>
                        </div>
                        <div class='col'>
                          <label class="_label _pl-0">{{"Primeira Parcela" | translate}}</label>
                          <app-input>
                            <input type="date" required autocomplete="nope" class="form-control" (click)="DataPrimeiroVencimento.toggle()" [(ngModel)]="pagamento.Financiamentos[0].DataPrimeiroVencimento" name="pagamento_Financiamentos_DataPrimeiroVencimento" min="{{hoje}}">
                          </app-input>
                        </div>
                        <div class='col'>
                        </div>
                        <div class='col-1'>
                        </div>
                      </div>
                    </div>

                    <div class='col boleto' *ngIf="pagamento.formaSelecionada?.FormaPagamento.TipoPagamento == 3">
                      <div class="alert alert-dark" role="alert">
                        Boleto à Vista: Passível de confirmação bancária
                      </div>
                    </div>

                    <div class='col comissao' *ngIf="pagamento.formaSelecionada?.FormaPagamento.TipoPagamento == 0">
                      <div class="alert alert-dark" role="alert">
                        Condição: Conta como pagamento à vista
                      </div>
                    </div>

                    <div class='col pix' *ngIf="pagamento.formaSelecionada?.FormaPagamento.TipoPagamento == 7">
                      <div class="form-group">
                        <textarea name="observacoespagamento" rows="4" cols="40" [(ngModel)]="pagamento.formaSelecionada?.FormaPagamento.Observacoes"></textarea>
                      </div>
                    </div>

                    <div class="row cartao_de_credito" *ngIf="pagamento.formaSelecionada?.FormaPagamento.TipoPagamento == 2 && !somenteLinkPagamento">
                        <div class="col-4 col-mb-6" (change)="linkPagamento()">
                          <label for="inputLinkPagamento">Gerar Link de Pagamento &nbsp;</label>
                          <input type="checkbox" id="inputLinkPagamento">
                        </div>
                    </div>

                    <div class="col cartao_de_credito" *ngIf="pagamento.formaSelecionada?.FormaPagamento.TipoPagamento == 2 && btnLinkPagamento==false">
                        <div class='row mt-3'>
                            <div class='col'>
                              <label>{{"Condição" | translate}}</label>
                              <select class="form-select" [(ngModel)]="pagamento.CondicaoPagamento" name="pagamento_CondicaoPagamento{{pagamento.Guid}}">
                                <ng-container *ngFor="let c of pagamento.formaSelecionada.FormaPagamento.Condicoes">
                                  <option *ngIf="(pagamento.Valor * c.Coeficiente).toFixed(2) >= pagamento.formaSelecionada.FormaPagamento.ValorMinimoParcela "  [ngValue]="c">{{c.Parcelas}}x de {{((pagamento.Valor + pagamento.Taxas) * c.Coeficiente).toFixed(2) }} {{c.Descricao}}</option>
                                </ng-container>
                              </select>
                            </div>
                            <div class="col-sm-2" *ngIf="(pagamento.formaSelecionada?.FormaPagamento.TipoPagamento == 4 || pagamento.formaSelecionada?.FormaPagamento.TipoPagamento == 2) && AplicaMkp || (pagamento.formaSelecionada?.FormaPagamento.TipoPagamento == 4 && TaxaNaPrimeiraParcela)">
                              <div class="form-group">
                                <label>{{'Taxas' | translate}} {{TaxaNaPrimeiraParcela ? '(soma na 1º parcela)' : ''}}</label>
                                <input [readOnly]="true" [required]="true" class="form-control input-sm" [(ngModel)]="pagamento.Taxas" name="pgto_taxas{{pagamento.Guid}}" />
                              </div>
                            </div>
                        </div>
                      <div class='row mt-3'>
                        <div class='col'>
                          <label class="_label _pl-0">Nome Pax Pagante como está no cartão</label>
                          <app-input>
                            <input style="text-transform: uppercase;" required class="form-control" [(ngModel)]="pagamento.Cartoes[0].Nome" name="pagamento_Cartoes_Nome{{pagamento.Guid}}">
                          </app-input>
                        </div>
                        <div class="col">
                          <label class="_label _pl-0">Numero do Cartão</label>
                          <app-input>
                            <input required minlength="16" required class="form-control" mask="0000-0000-0000-0000" [(ngModel)]="pagamento.Cartoes[0].Numero" name="pagamento_Cartoes_Sobrenome{{pagamento.Guid}}">
                          </app-input>
                        </div>
                      </div>
                      <div class='row mt-3'>
                        <div class='col'>
                          <label>{{"Condição" | translate}}</label>
                          <select class="form-select" [(ngModel)]="pagamento.CondicaoPagamento" name="pagamento_CondicaoPagamento{{pagamento.Guid}}" (click)="onChangeParcelas(pagamento)">
                            <ng-container *ngFor="let c of pagamento.formaSelecionada.FormaPagamento.Condicoes">
                              <option *ngIf="(pagamento.Valor * c.Coeficiente).toFixed(2) >= pagamento.formaSelecionada.FormaPagamento.ValorMinimoParcela "  [ngValue]="c">{{c.Parcelas}}x de {{((pagamento.Valor + pagamento.Taxas) * c.Coeficiente).toFixed(2) }} {{c.Descricao}}</option>
                            </ng-container>
                          </select>
                        </div>
                        <div class="col-sm-2" *ngIf="(pagamento.formaSelecionada?.FormaPagamento.TipoPagamento == 4 || pagamento.formaSelecionada?.FormaPagamento.TipoPagamento == 2) && AplicaMkp || (pagamento.formaSelecionada?.FormaPagamento.TipoPagamento == 4 && TaxaNaPrimeiraParcela)">
                          <div class="form-group">
                            <label>{{'Taxas' | translate}} {{TaxaNaPrimeiraParcela ? '(soma na 1º parcela)' : ''}}</label>
                            <input [readOnly]="true" [required]="true" class="form-control input-sm" [(ngModel)]="pagamento.Taxas" name="pgto_taxas{{pagamento.Guid}}" />
                          </div>
                        </div>
                        <div class='col'>
                          <label class="_label _pl-0">{{"Bandeira" | translate}}</label>
                          <app-input>
                            <select class="form-select" [(ngModel)]="pagamento.Cartoes[0].Bandeira" name="pagamento_Cartoes_Bandeira{{pagamento.Guid}}">
                              <option *ngFor="let b of bandeiras" [ngValue]="b.Descricao">{{b.Descricao}}</option>
                            </select>
                          </app-input>
                        </div>
                        <div class="col">
                          <label class="_label _pl-0">{{"Validade" | translate}}</label>
                          <app-input>
                            <input required [minlength]="4" class="form-control" [dropSpecialCharacters]="false" mask="00/0000" [(ngModel)]="pagamento.Cartoes[0].DataValidade" name="pagamento_Cartoes_DataValidade{{pagamento.Guid}}">
                          </app-input>
                        </div>

                        <div class="col">
                          <label class="_label _pl-0">CVV</label>
                          <app-input>
                            <input [required]="true" [minlength]="3" class="form-control" mask="9000" [(ngModel)]="pagamento.Cartoes[0].DigitoVerificador" name="pagamento_Cartoes_DigitoVerificador{{pagamento.Guid}}">
                          </app-input>
                        </div>
                      </div>
                    </div>
                    <div class="col cartao_de_credito" *ngIf="pagamento.formaSelecionada?.FormaPagamento.TipoPagamento == 2 && btnLinkPagamento==true">
                        <div class='row mt-3'>
                            <div class='col'>
                              <label>{{"Condição" | translate}}</label>
                              <select class="form-select" [(ngModel)]="pagamento.CondicaoPagamento" name="pagamento_CondicaoPagamento{{pagamento.Guid}}" (click)="onChangeParcelas(pagamento)">
                                <ng-container *ngFor="let c of pagamento.formaSelecionada.FormaPagamento.Condicoes">
                                  <option *ngIf="(pagamento.Valor * c.Coeficiente).toFixed(2) >= pagamento.formaSelecionada.FormaPagamento.ValorMinimoParcela "  [ngValue]="c">{{c.Parcelas}}x de {{((pagamento.Valor + pagamento.Taxas) * c.Coeficiente).toFixed(2) }} {{c.Descricao}}</option>
                                </ng-container>
                              </select>
                            </div>
                            <div class="col-sm-2" *ngIf="(pagamento.formaSelecionada?.FormaPagamento.TipoPagamento == 4 || pagamento.formaSelecionada?.FormaPagamento.TipoPagamento == 2) && AplicaMkp || (pagamento.formaSelecionada?.FormaPagamento.TipoPagamento == 4 && TaxaNaPrimeiraParcela)">
                              <div class="form-group">
                                <label>{{'Taxas' | translate}} {{TaxaNaPrimeiraParcela ? '(soma na 1º parcela)' : ''}}</label>
                                <input [readOnly]="true" [required]="true" class="form-control input-sm" [(ngModel)]="pagamento.Taxas" name="pgto_taxas{{pagamento.Guid}}" />
                              </div>
                            </div>
                        </div>
                        <div class='row mt-3'>
                            <div class="col-6">
                                <div class="form-group">
                                <label>E-mail&nbsp;<span style="color: red;font-size: medium;">*</span></label>
                                <input type="text" [(ngModel)]="pagamento.Email" [ngModelOptions]="{standalone: true}"
                                    class="form-control input-sm" [required]="true" minlength="8" />
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                <label>CPF&nbsp;<span style="color: red;font-size: medium;">*</span></label>
                                <input autocomplete="off" [required]="true" [(ngModel)]="pagamento.CPF" required
                                    [ngModelOptions]="{standalone: true}" type="text" class="form-control input-sm"
                                    placeholder="000.000.000-00" mask="000.000.000-00">
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                <label>{{'Telefone'}}&nbsp;<span style="color: red;font-size: medium;">*</span></label>
                                <input autocomplete="off" [(ngModel)]="pagamento.Telefone"
                                    [ngModelOptions]="{standalone: true}" mask="00 0 00000000" type="text"
                                    class="form-control input-sm">
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="!carregandoInfo">
                            <div class="col text-end">
                              <button *ngIf="btnLinkPagamento == true && pagamento.formaSelecionada?.FormaPagamento.TipoPagamento == 2" class="col-4 btn btn-success" [disabled]="!pagamento.Email || !pagamento.CPF" (click)="gerarLinkPagamento()">Link para Pagamento</button>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class='row mt-3'>
                    <div class="col">
                      <div [hidden]="!pagamento.PaxPaganteId || !possuiPaxNaoPagantes(pagamento.PaxPaganteId, pagamento.Guid)">
                        <label>Responsável por:</label>
                        <ul class="list-group">
                          <li *ngFor="let pax of pessoasNaoPagantes(pagamento)" (click)="selecionarPaxNaoPagante(pax, pagamento)" [class.selecionado]="checado(pax, pagamento)">
                            <div class="form-check">
                              <input class="form-check-input" [checked]="checado(pax, pagamento)" type="checkbox">
                              <label class="form-check-label">
                                {{pax.Nome}} {{pax.Sobrenome}}
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3" *ngIf="pagamento.Erros?.length > 0">
                    <div class="alert alert-danger" style="width: 100%">
                      <ul>
                        <li *ngFor="let mensagem of pagamento.Erros">{{mensagem}}</li>
                      </ul>
                    </div>
                  </div>

                  <div class="row mt-3" *ngIf="pagamento.Success?.length > 0">
                    <div class="alert alert-success" style="width: 100%">
                      <ul>
                        <li>{{pagamento.Success}}</li>
                      </ul>
                    </div>
                  </div>
                  <hr *ngIf="formasPagamento && podeAdicionarPagamento && calcularSaldoGeral() > 0">
                </div>
<!-- 
                <button *ngIf="formasPagamento && podeAdicionarPagamento && calcularSaldoGeral() > 0" class="btn btn-success" (click)="novoPagamento()">+ Adicionar</button> -->
              </form>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>

  <div *ngIf="!podePagar">
    <h4> {{"O Orçamento não pode ser pago" | translate}}</h4>
    <div>
      <p>{{"Itens que estão impedindo o pagamento" | translate}}: </p>
      <table class="table ">
        <thead>
          <tr>
            <th>{{"Item" | translate}}</th>
            <th>{{"Descrição" | translate}}</th>
            <th>{{"Fornecedor" | translate}}</th>
            <th>{{"Status" | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let aereo of reserva.Aereos">
            <td>{{"Aéreo" | translate }}</td>
            <td>{{ "Localizador: " + aereo.LocalizadorAereo | translate }}</td>
            <td *ngIf="usuario.EmpresaId != null">{{ aereo.Fornecedor }}</td>
            <td>{{ aereo.StatusDesc }}</td>          
          </tr>
          <tr *ngFor="let hotel of reserva.Hoteis">
            <td>Hotel</td>
            <td>{{ "Hotel: " + hotel.Nome | translate }}</td>
            <td *ngIf="usuario.EmpresaId != null">{{ hotel.Fornecedor }}</td>
            <td>{{ hotel.StatusDesc }}</td>
          </tr>
          <tr *ngFor="let servico of reserva.Servicos">
            <td>{{"Serviço" | translate }}</td>
            <td>{{servico.Titulo}}</td>
            <td *ngIf="usuario.EmpresaId != null">{{ servico.Fornecedor }}</td>
            <td>{{ servico.StatusDesc }}</td>
          </tr>
        </tbody>
      </table>

      <div class="alert alert-danger" type="danger">
        <p>{{"Necessário reservar os itens acima, ou removê-los do orçamento para prosseguir!" | translate}}</p>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div>
    <div class="row" *ngIf="mensagensErro?.length > 0">
      <div class="alert alert-danger" style="width: 100%">
        <ul>
          <li *ngFor="let mensagem of mensagensErro">{{mensagem}}</li>
        </ul>
      </div>
    </div>
    <div class="row" *ngIf="mensagemSucesso?.length > 0" style="width: 100%">
      <div class="alert alert-success" style="width: 100%">
        {{mensagemSucesso}}
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!carregandoInfo">
    <div class="col text-end">
      <button class="btn btn-outline-danger" data-bs-dismiss="modal" (click)="fechou.emit()">Fechar</button>
      <button [disabled]="carregando || pagamentos && pagamentos.length == 0 || saldo <= somaPagamentosInformados || validaComissao(pagamentos) || !podePagar" (click)="efetuarPagamento()" class="btn btn-success" type="submit" *ngIf="btnLinkPagamento==false">Efetuar Pagamentos</button>
    </div>
  </div>
</div>
