<div class="modal-header">
  <h4>{{"Cancelamento de Serviço" | translate }}</h4>
</div>
<div class="modal-body" *ngIf="!carregando">
  <div class='row'>
    <div class='col'>
      <strong class="title">{{servico?.Titulo}}</strong>
    </div>
  </div>

  <div class='row'>
    <div class='col'>
      <p>{{"Data" | translate }}: {{servico?.De | date: 'dd/MM/yyyy'}}</p>
    </div>
  </div>

  <div class='row'>
    <div class='col'>
      <h4>{{"Confirmar cancelamento" | translate}}?</h4>
    </div>
  </div>
</div>

<div class="modal-footer">
  <app-loading-small *ngIf="carregando"></app-loading-small>

  <div class="row" *ngIf="mensagensErro?.length > 0">
    <div class="alert alert-danger" style="width: 100%">
      <ul>
        <li *ngFor="let mensagem of mensagensErro">{{mensagem}}</li>
      </ul>
    </div>
  </div>
  <div class="row" *ngIf="mensagemSucesso?.length > 0" style="width: 100%">
    <div class="alert alert-success" style="width: 100%">
      {{mensagemSucesso}}
    </div>
  </div>

  <div class="row">
    <div class='col flex'>
      <button [disabled]="carregando" class="btn btn-outline-danger" data-bs-dismiss="modal" (click)="fechou.emit()">Fechar</button>
      <button [disabled]="carregando" *ngIf="servico?.Status == 3 || servico?.Status == 4" (click)="cancelarServico()" class="btn btn-success" type="submit">{{"Continuar" | translate}}</button>
    </div>
  </div>
</div>
