import { WebService } from '../gerenciamento/webservice.model';
import { FormaPagamento } from '../gerenciamento/forma-pagamento.model';
import { Injectable } from "@angular/core";


@Injectable()
export class Agencia {
  public Id: number;
  public RazaoSocial: string;
  public NomeFantasia: string;
  public CNPJ: string;
  public InscricaoEstadual: string;
  public InscricaoMunicipal: string;
  public EmpresaId: number;
  public Consultores: any[];
  public WebSite: any;
  public Email: any;
  public TelefonePrincipal: any;
  public TelefoneSecundario: any;
  public LimiteCredito: any;
  public SaldoCredito: any;
  public Comissao: any;
  public FormaPgtoGateway: any;
  public FormaPgtoFinanciamento: any;
  public NumMaxParcelas: any;
  public UsaOffline: any;
  public Idioma: any;
  public BonusAereo: any;
  public MoedaListagemId: any;
  public MoedaPagamentoId: any;
  public ListaMoedaFornecedor: any;
  public LiberaVoucherAutomatico: any;
  public Embratur: any;
  public CodigoERP: any;
  public Situacao: any;
  public Cidade?: any = { Nome: '' };
  public Logo: string;
  public Empresa?: any = { NomeFantasia: '' };
  public PerfilMarkup?: any = { Descricao: '' };
  public Comercial?: any = { Nome: '' };
  public Meta: number;
  public LogoApresentacao: any;
  public Excluidos: WebService[];
  public Cep: any;
  public Logradouro: any;
  public Numero: any;
  public Bairro: any;
  public Complemento: any;
  public Ativo: any;
  public Alteracoes: any;
  public FormasPagamento: any;

  static RazaoSocial(RazaoSocial: any) {
    throw new Error('Method not implemented.');
  }
}

export class AgenciaFormaPagamento {
  Id: number;
  FormaPagamento: FormaPagamento = new FormaPagamento();
  MaxParcelas: number;
  Ativo: boolean;
  AgenciaId: number;
  FormaPagamentoId: number;
}

// tslint:disable-next-line: class-name
export class Agencia_Integrador {
  public WebService?: any = { Descricao: '' };
}
