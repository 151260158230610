import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { API , ITENSPORPAGINA} from '../../app.config';

@Injectable()
export class VoucherService {
  constructor(private http: HttpClient) {}

//     exibirVoucher(VoucherKey):Observable<any>{
// //      const headers = new HttpHeaders().set('noloader', 't');
//     const headers = new HttpHeaders();
//       return this.http.get<any>(`${API}/api/voucher/${VoucherKey}`, {headers: headers});
//     }

    exibirVoucher(VoucherKey): Observable<any> {
      return this.http.get<any>(`${API}/api/voucher/${VoucherKey}`, { responseType: 'blob' as 'json' })
        .pipe(map(res => {
        return new Blob([res], { type: 'application/pdf', });
      }));
    }

  exibirVoucherUrl(VoucherKey): string {
    return `${API}/api/voucher/${VoucherKey}`;
  }

    gerarVoucher(tipo, item, endereco ): Observable<any> {

      return this.http.post<any>(`${API}/api/reservas/voucher/${tipo}`, {Id: item.Id, EnderecoBase: endereco});
    }

    enviarVoucher(VoucherKey, email, Tipo) : Observable<any> {
      return this.http.patch<any>(`${API}/api/VoucherEnvio/${VoucherKey}/${Tipo}`, { Email: email });
    }

    exibirVoucherHtml(VoucherKey):Observable<any> {
      const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
      return this.http.patch<any>(`${API}/api/VoucherHtml/${VoucherKey}?html=true`, { headers, responseType: 'text' as 'json'});
    }



  }
