<div class="container">
  <div class="d-flex">
    <div class="card">
      <img src="..." class="card-img-top" alt="...">
    
      <div class="card-body">
        <h5 class="card-title">Card title</h5>
        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
        <a href="#" class="btn btn-primary">Go somewhere</a>
      </div>
    </div>
    
    <div class="card" aria-hidden="true" *ngFor="let i of info">
      <img src="..." class="card-img-top" alt="...">
      <div class="card-body">
        <h5 class="card-title placeholder-glow">
          <span class="col-6" [ngClass]="loading ? 'placeholder' : '' " >{{i.title}}</span>
        </h5>
        <p class="card-text placeholder-glow">
          <span class="col-7" [ngClass]="loading ? 'placeholder' : '' ">{{ loading ? '' : i.text}}</span>
          <span class="col-4" [ngClass]="loading ? 'placeholder' : '' "></span>
          <span class="col-4" [ngClass]="loading ? 'placeholder' : '' "></span>
          <span class="col-6" [ngClass]="loading ? 'placeholder' : '' "></span>
          <span class="col-8" [ngClass]="loading ? 'placeholder' : '' "></span>
        </p>
        <a href="#" tabindex="-1" class="btn btn-primary disabled placeholder col-6"></a>
      </div>
    </div>
  </div>
</div>