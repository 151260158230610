import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


import { Aeroporto } from '../../model/';

import { API, ITENSPORPAGINA} from '../../app.config';

// tslint:disable: max-line-length
// tslint:disable: ban-types
@Injectable()
export class AeroportoService {
  constructor(private http: HttpClient) {}


    buscarPorParametro(param: string, pagina: Number): Observable<any> {
      const headers = new HttpHeaders().set('noloader', 't');

      if (param.length > 0) {
        return this.http.get<any>( `${API}/api/Aeroporto/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers});
      } else {
        return this.http.get<any>( `${API}/api/Aeroporto?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers});
      }

    }

    buscarPorId(id: number): Observable<any> {
        return this.http.get<any>( `${API}/api/Aeroporto/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any> {
      return this.http.delete<any>( `${API}/api/Aeroporto/${id}`);
    }

    salvar(aeroporto: Aeroporto): Observable<any> {
      if (aeroporto.Id > 0) {
        return this.http.put<any>( `${API}/api/Aeroporto/${aeroporto.Id}`, aeroporto);
      } else {
        return this.http.post<any>( `${API}/api/Aeroporto/`, aeroporto);
      }

    }


  }
