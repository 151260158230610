<ng-content class="elementRefItems"></ng-content>

<div *ngIf="showDropDown" class="state-container-drowpdown elementRefItems">
  <!-- clickOutside(clickOutside)="toggleDropDown()" -->
  <div (mousedown)="onSelecionarItem(item)" style="cursor: pointer;" class=" item-dropdown elementRefItems"
    *ngFor="let item of lista; let i= index" [class.item-dropdown-selected]="i == indice">
    {{item.display_field}}
  </div>
  <div class="item-dropdown elementRefItems" *ngIf="lista.length == 0">
    {{notFoundMessage}}
  </div>
</div>
