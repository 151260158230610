<div class="pagebg">
    <div class="banner-image">
        <div class="banner-text">
            <h2>{{"Serviços" | translate}}</h2>
        </div>
    </div>
    <div class="container">
        <div class='row mb-3 mt-3'>
            <div class='container col-md-12 row'>
                <h4 class="col-md-5">{{"Serviços Inclusos"| translate }}</h4>
                <button class="btn btn-success buttonOrcamento col-md-7" (click)="rotaAvancar()">{{"Continuar" |
                    translate}}</button>
            </div>
        </div>

        <app-view-loading *ngIf="buscando"></app-view-loading>
<!--
        <div class="row text-center" *ngIf="servicos?.length == 0">
            <h3 class="text-muted">{{'Nenhum serviço adicional encontrado' | translate}}</h3>
        </div> -->


        <div class="card-section pb-4">
            <div *ngFor="let servico of servicos; let i = index" class="mt-4">
                <div class="card mb-3 all">
                    <div class="row g-0">
                        <div class="col-md-3">
                            <img src="{{
                      servico.ImagemPrincipal != null && servico.ImagemPrincipal != ''
                        ? servico.ImagemPrincipal
                        : 'assets/logo/logo-nova-colorida.svg'
                    }}" class="img-fluid rounded-start" alt="background image">
                        </div>
                        <div class="col-md-6">
                            <div class="card-body">
                                <div class="row mb-3 col-9 card-body" [ngSwitch]="servico.CategoriaServico">
                                    <h5 class="fonte-cabecalho-card"> {{servico.Titulo}}
                                        <span *ngSwitchCase="0">({{'REGULAR' | translate}})</span>
                                        <span *ngSwitchCase="1">({{'PRIVATIVO' | translate}})</span>
                                        <span *ngSwitchCase="2">({{'LUXO' | translate}})</span>
                                    </h5>
                                </div>
                                <div>
                                    <button class="btn btn-success"
                                        (click)="abrirModal(contentServicoDetalhes, servico)"
                                        *ngIf="!buscando">{{"Detalhes do Serviço" | translate}}</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 d-flex flex-column justify-content-around align-items-center">
                            <!-- <p class="card-subtitle"><small class="text-muted">{{"A partir de" | translate}}:</small>
                            </p> -->
                            <h5 class="card-title">{{servico.MoedaListagem.Simbolo}} {{ servico.ValorListagem |
                                currency:'BRL':'':'2.2-2' }}</h5>
                            <label class="btn btn-success" *ngIf="!servico.selecionado && !servicosObrigatoriosPacote">
                                <input type="checkbox" class="checked-btn"
                                    (click)="selecionarServico(servico)" /><span>{{"Adicionar" | translate}}</span>
                            </label>
                            <label class="btn btn-outline-success" *ngIf="servico.selecionado && !servicosObrigatoriosPacote">
                                <input type="checkbox" class="checked-btn"
                                    (click)="removerServico(servico)" /><span>{{"Remover" | translate}}</span>
                            </label>

                            <label class="btn btn-outline-success" *ngIf="servicosObrigatoriosPacote && servicoSelecionado(servico)">
                                <input type="checkbox" class="checked-btn" /><span>{{"Serviço Incluso" | translate}}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #contentServicoDetalhes let-modal>
    <app-servico-detalhes [objServico]="objModal"></app-servico-detalhes>
</ng-template>
