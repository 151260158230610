import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-area-teste',
  templateUrl: './area-teste.component.html',
  styleUrls: ['./area-teste.component.scss']
})
export class AreaTesteComponent implements OnInit {
  
  loading = true;

  info = [
    {title: 'Teste', text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultricies at ligula ac placerat. Vestibulum volutpat nisi nisl, iaculis congue tellus condimentum et. " },
  ]

  constructor() { }

  ngOnInit() {

    setTimeout(() => { this.loading = false }, 3000);
  }

}
