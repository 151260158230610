<div class="modal-body">
  <div class="progress" *ngIf="!carregando">
    <div class="progress-bar bg-success" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">Dados</div>
    <div class="progress-bar" [ngClass]="[this.progress > 1 ? 'bg-success' : 'bg-secondary']" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">Emissão</div>
  </div>
  <div id="dados" class="content mt-5 mb-5" *ngIf="!carregando && progress == 1">
    <h5>Verifique os dados antes de emitir!</h5>
    <app-orcamento-aereo-card [aereo]="aereo"></app-orcamento-aereo-card>
    <div class="row">
      <div class="col-mt-3 row">
        <table class="table">
          <thead>
            <tr>
              <th>{{"Tipo" | translate}}</th>
              <th>Qtde</th>
              <th>{{"Tarifa" | translate}} (un)</th>
              <th>{{"Taxas de serviço" | translate}} (un)</th>
              <th>{{"Taxas" | translate}} (un) </th>
              <th>{{"Subtotal" | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let tarifa of aereo.PaxesTarifa">
              <td>
                {{(tarifa.TipoPax == 0 ? 'Adulto' : (tarifa.TipoPax == 1 ? 'Criança' : 'Bebê')) | translate}}
              </td>
              <td>{{tarifa.QtdPax}}</td>
              <td>{{aereo.MoedaListagem.Simbolo}}
                {{tarifa.ValorTarifaListagem | currency:'BRL':'':'1.2-2' }}
              </td>
              <td>{{aereo.MoedaListagem.Simbolo}}
                {{tarifa.ValorTaxaServicoListagem | currency:'BRL':'':'1.2-2' }}</td>
              <td>{{aereo.MoedaListagem.Simbolo}}
                {{tarifa.ValorTaxaListagem | currency:'BRL':'':'1.2-2' }}
              </td>
              <td>{{aereo.MoedaListagem.Simbolo}}
                {{tarifa.SubTotalListagem | currency:'BRL':'':'1.2-2' }}
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="aereoRetarifado && aereoRetarifado.VendaTotal !== aereo.VendaTotal">
          <h2>Alterou o Valor do Aéreo</h2>
          <p>Valor Antigo: {{aereo.VendaTotal | currency:'BRL':true:'1.2-2' }}</p>
          <p>Valor Atual: {{aereoRetarifado.VendaTotal | currency:'BRL':true:'1.2-2' }}</p>
          <p>
            <label ngbButtonLabel class="btn btn-primary tab-buscador">
              <input type="checkbox" ngbButton [(ngModel)]="cienteAlterouValor"> Estou Ciente
            </label>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div id="info" *ngIf="mensagensErro?.length > 0">
    <div class='row'>
      <div class='col text-center'>
        <h4>Aéreo emitido com sucesso!</h4>
      </div>
    </div>
    <div class='row'>
      <div class='col d-flex center'>
        <h6>{{"Status:" | translate}}</h6>
        <p> {{Orcamento.Aereo[0].StatusDesc}} </p>
      </div>
      <div class='col d-flex center'>
        <h6>{{"Localizador:" | translate}}</h6>
        <p> {{Orcamento.Aereo[0].LocalizadorAereo}} </p>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer text-end" style="display:block">
  <app-loading-small *ngIf="carregando"></app-loading-small>
  <div class="row" *ngIf="mensagensErro?.length > 0">
    <div class="alert alert-danger" style="width: 100%">
      <ul>
        <li *ngFor="let mensagem of mensagensErro">{{mensagem}}</li>
      </ul>
    </div>
  </div>
  <div class="row" *ngIf="mensagemSucesso?.length > 0" style="width: 100%">
    <div class="alert alert-success" style="width: 100%">
      {{mensagemSucesso}}
    </div>
  </div>
  <button class="btn btn-outline-danger" data-bs-dismiss="modal" (click)="fechou.emit()">Voltar</button>
  <button class="btn btn-success" [disabled]="carregando" (click)="emitirAereo()">Emitir</button>
</div>
