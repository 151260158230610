import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import * as cloneDeep from 'lodash/cloneDeep';
import { Busca, Paginacao, PacoteReserva, Hotel } from 'src/app/model';
import { Util } from 'src/app/services';
import { PacotelstService } from 'src/app/services/turismo/pacotelst.service';

@Component({
  selector: 'app-pacote-hotel',
  templateUrl: './pacote-hotel.component.html',
  styleUrls: ['./pacote-hotel.component.scss']
})
export class PacoteHotelComponent implements OnInit {

  constructor(
    // private hotelService: HotelService,
    private pacoteService: PacotelstService,
    public _router: Router,
    public route: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  @Output() selecionou = new EventEmitter<any>();
  @Output() removeu = new EventEmitter<any>();
  @Output() avancar = new EventEmitter<any>();
  @Input() Orcamento: PacoteReserva[] = [];

  paginacao: Paginacao = new Paginacao();
  busca: Busca = new Busca();

  hoteis: Hotel[] = [];
  
  pacoteReserva: any = new PacoteReserva();
  itemBusca: any = null;
  progress: any;
  pacoteId: any;
  qntTrechos = 0;
  usuario = null;
  buscando = false;
  rebusca = false;
  mensagemErro: '';
  objHotel: any;
  indice: number;
  PacoteDetalhes: any;

  ngOnInit() {
    const busca = window.sessionStorage.getItem('buscapacote');
    this.busca = this.busca.decodeBusca(busca);
    this.busca.trechos = this.busca.trechos.map(x => {
      x.dataIda = moment(x.dataIda);
      x.minDataIda = moment(x.minDataIda);
      x.dataVolta = moment(x.dataVolta);
      x.minDataVolta = moment(x.minDataVolta);
      return x;

    });

    this.buscarHoteisPacote();
  }

  rotaAvancar() {
    this.avancar.emit('');
  }

  buscarHoteisPacote() {
    const buscaHotel = this.busca.getBuscaHotel();
    this.buscando = true;
    this.pacoteService
      .buscarEstatico(buscaHotel)
      .subscribe(
        dados => {
          this.pacoteReserva = dados;
          this.pacoteReserva.PacoteImagens = this.pacoteReserva.Pacote.PacoteImagens.map(x => {
            x.Imagem = Util.pathImagemApi(x.Imagem);
            return x;
          })
          this.pacoteReserva.Hoteis = this.pacoteReserva.Hoteis.map(x => {
            x.Id = x.Id * -1;

            const opcoes = x.Quartos.map(q => q.Pesquisa)
              .filter(this.distinct)
              .sort();
            x.opcoes = [];
            for (let i = 0; i < opcoes.length; i++) {
              x.opcoes.push(x.Quartos.filter(x => x.Pesquisa == opcoes[i]));
            }
            x.Quartos = [];
            x.melhorPreco = 0;
            for (let i = 0; i < opcoes.length; i++) {
              x.Quartos.push(x.opcoes[i][0]);
              x.melhorPreco += x.opcoes[i][0].ValorListagem;
            }

            return x;
          });

          this.buscando = false;
          window.sessionStorage.setItem('pacoteReserva', JSON.stringify(this.pacoteReserva));
          window.sessionStorage.setItem("buscapacote", JSON.stringify(this.busca));
        },
        erro => {
          this.buscando = false;
          console.log(erro);
          alert(erro.error.mensagem);
          this._router.navigateByUrl("/listagem-pacotes/pacotes");
        }
      );
  }

  somaTotal(arr) {
    return arr
      .map(x => x.ValorListagem)
      .reduce(function (a, b) {
        return a + b;
      }, 0);
  }

  distinct = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  hotelSelecionado(hotel) {
    const dado = this.Orcamento[0]?.Hoteis.filter(
      x => x.CodigoFornecedor === hotel.CodigoFornecedor
        && x.Fornecedor == hotel.Fornecedor
    );
    return dado?.length;
  }

  selecionarHotel(hotel) {
    hotel.selecionado = true;
    let pacotereservaClone = cloneDeep(this.pacoteReserva);
    pacotereservaClone.Hoteis = [];
    pacotereservaClone.Hoteis.push(hotel);
    if(this.Orcamento.length > 0){
        this.Orcamento[0] = pacotereservaClone;
    } else {
      this.Orcamento.push(pacotereservaClone);
    }
    this.selecionou.emit(this.Orcamento);
  }

  removerHotel(hotel) {
    let indice = -1;
    for (let i = 0; i < this.Orcamento[0].Hoteis.length; i++) {
      if (this.Orcamento[0].Hoteis[i].Id == hotel.Id) {
        indice = i;
      }
    }
    if (indice >= 0) {
      this.Orcamento[0].Hoteis.splice(indice, 1);

      hotel.QuantidadeSelecionada = this.Orcamento[0].Hoteis.filter(x => x.CodigoFornecedor == hotel.CodigoFornecedor && x.Fornecedor == hotel.Fornecedor).length;

      if (hotel.QuantidadeSelecionada == 0) {
        hotel.selecionado = false;
      }

      this.removeu.emit(hotel);
    }
  }

  temHotel(){
    if(this.Orcamento[0]?.Hoteis.filter(x => x.selecionado == true).length > 0){
      return true;
    }else{
      return false;
    }
  }

  atualizaHotel(hotel){
    console.log(hotel);
  }

  buscarPagina(pagina) {
    this.paginacao.NumeroPagina = pagina;
    this.buscarHoteisPacote();
  }

  abrirModal(content, hotel) {
    this.PacoteDetalhes = hotel;
    this.objHotel = hotel;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' });
  }

  fecharModal() {
    this.modalService.dismissAll();
  }
}
