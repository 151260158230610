import { Component, EventEmitter, HostListener, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/services';
import { LOGO, LOGOLIGHT, LINKPORTFOLIO, ESCONDERTARIFARIO, LINKTARIFARIO } from 'src/app/app.config';

export const BUSCA = 'busca';
export const ORCAMENTO = 'orcamentousuario';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  usuario: any;
  usuarioNome: any;
  activeNav = {
    home: false,
    orcamento: false,
    pacotes: false
  }
  logo = LOGO;
  logolight = LOGOLIGHT;
  linkportfolio = LINKPORTFOLIO;
  linktarifario = LINKTARIFARIO;
  escondertarifario = ESCONDERTARIFARIO;
  nav: string = '';

  @Output() deslogou = new EventEmitter<any>();

  constructor(
    private loginService: LoginService,
    public _router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.usuario = this.loginService.user();
    this.usuarioNome = this.usuario.Nome;
  }

  ngDoCheck() {
    const item3 = this._router.url.split('/');

    if (item3.length == 2 && item3[0].length == 0 && item3[1].length == 0) {
      this.activeNav = {
        home: true,
        orcamento: false,
        pacotes: false,
      }
    } else if (item3.length > 0 && (item3.includes("pacotes") || item3.includes("lista-pacotes"))) {
      this.activeNav = {
        home: false,
        orcamento: false,
        pacotes: true,
      }
    } else if (item3.length > 0 && (item3.includes("orcamento") || item3.includes("meus-orcamentos"))) {
      this.activeNav = {
        home: false,
        orcamento: true,
        pacotes: false,
      }
    } else {
      this.activeNav = {
        home: false,
        orcamento: false,
        pacotes: false,
      }
    }
  }

  limpaPesquisa() {
    sessionStorage.clear();
  }

  logout() {
    this.loginService.logout();
    this.deslogou.emit('Deslogou');
    this.clearAllStorage();
    window.location.reload();
  }

  clearAllStorage() {
    sessionStorage.clear();
    localStorage.clear();
  }
}
