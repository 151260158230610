import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler , HttpEvent} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor( private loginService: LoginService) {}

  intercept(request: any, next: HttpHandler): Observable<HttpEvent<any>> {
    // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{

    const loader = request.headers.keys();
    const noauth = (loader.indexOf('noauth') !== -1);
    if ( !noauth && this.loginService.isLoggedIn() ) {
      const authRequest = request.clone({setHeaders: {Authorization: 'Bearer ' + this.loginService.user().access_token}});
      return next.handle(authRequest);

    } else {
      if ( noauth ) {
        request.headers = request.headers.delete('noauth');
      }
      return next.handle(request);
    }



  }


}
