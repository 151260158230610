// tslint:disable:ban-types
// tslint:disable: max-line-length
import { ITENSPORPAGINA } from './../../app.config';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API } from '../../app.config';
import { BuscaHotelRQ } from 'src/app/model';
@Injectable({
  providedIn: 'root'
})
export class PacotelstService {

  constructor(private http: HttpClient) {}

  buscarPacotes(busca: string, pagina: Number): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    const datain = null;
    const dataout = null;
    if (busca.length > 0 || datain != null || dataout != null) {
      return this.http.post<any>(`${API}/api/Pacote/list?Pagina=${pagina}&ItensPorPagina=${9}`, { headers: headers, Busca: busca, Datain: datain, Dataout: dataout });
    } else {
      return this.http.get<any>(`${API}/api/Pacote?Pagina=${pagina}&ItensPorPagina=${9}`, { headers });
    }
    // if (busca.length > 0) {
    //   return this.http.get<any>( `${API}/api/Pacote/list/${busca.trim()}?Pagina=${pagina}&ItensPorPagina=${PACOTESPORPAGINA}`, { headers });
    // } else {
    //   return this.http.get<any>( `${API}/api/Pacote?Pagina=${pagina}&ItensPorPagina=${PACOTESPORPAGINA}`, { headers });
    // }
  }

  buscarPorParametro(param: string, pagina: number): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
      if (param.length > 0) {
          return this.http.get<any>(`${API}/api/PacoteDestino/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
      }
      else {
          return this.http.get<any>(`${API}/api/PacoteDestino/?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
      }
    }

    removerPacoteReserva(ReservaId: Number): Observable<any> {
      return this.http.delete<any>(`${API}/api/Pacote/Reserva/${ReservaId}`);
    }

    removerPacoteReservaEstatico(PacoteReservaId: Number): Observable<any>{
      return this.http.delete<any>(`${API}/api/pacotereserva/remover/${PacoteReservaId}`);
    }

    reservarPacoteDestino(PacoteReservaId: number): Observable<any> {
    return this.http.post<any>(`${API}/api/pacoteestatico/reservar/${PacoteReservaId}`, {});
    }


    cancelarPacoteDestino(PacoteReservaId : number): Observable<any> {
        return this.http.post<any>(`${API}/api/pacote/cancelar/${PacoteReservaId}`, {});
    }

    buscaPacotesDestinos(DestinoId: number, pagina: number): Observable<any> {
      return this.http.get<any>(`${API}/api/PacoteDestino/destinos/${DestinoId}?Pagina=${pagina}&ItensPorPagina=12`);
    }

    buscarEstatico(busca: BuscaHotelRQ): Observable<any> {
      return this.http.post<any>(`${API}/api/pacote/buscaestatico/`, busca, { headers: new HttpHeaders() });
    }

    listDestinos(): Observable<any> {
      const headers = new HttpHeaders().set('noloader', 't');
          return this.http.get<any>(`${API}/api/PacoteDestino/Select`, { headers: headers });
    }

    buscarPorId(id: number): Observable<any> {
      return this.http.get<any>(`${API}api/PacoteDestino/${id}`);
    }

    remover(id: Number): Observable<any> {
      return this.http.delete<any>(`${API}/api/PacoteDestino/remover/${id}`);
    }

    salvar(PacoteDestino: any): Observable<any> {
      if (PacoteDestino.Id > 0) {
          return this.http.put<any>(`${API}/api/PacoteDestino/${PacoteDestino.Id}`, PacoteDestino);
      }
      else {
          return this.http.post<any>(`${API}/api/PacoteDestino/`, PacoteDestino);
      }
    }

}
